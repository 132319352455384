import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

interface Props {
  children: any;
  title: any;
  disable?: boolean;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.contrastText,
      boxShadow: '0px 4px 16px 2px rgba(0, 0, 0, 0.38)',
      fontWeight: 400,
      fontSize: '14px'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white
    }
  })
);

const MTooltip = ({ children, title, disable }: Props) => {
  if (!disable) {
    return (
      <LightTooltip disableFocusListener disableTouchListener title={title || 'tooltip'} arrow>
        <div>{children}</div>
      </LightTooltip>
    );
  }
  return <div>{children}</div>;
};

export default MTooltip;
