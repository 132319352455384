import styled from 'styled-components';
import Flex from '../../core/Flex';

export const RecentActivityNoDataContainer = styled(Flex)`
  height: 300px;
  width: 100%;
`;

export const Link = styled.a<any>`
  height: 18px;
  text-decoration: none;
  font-family: 'Hind';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #383975;
  border-bottom: 1px solid #383975;
`;
