import DOMPurify from 'dompurify';

interface SafeInnerHtmlProps {
  unsafeInnerHtmlText: string | undefined;
}

const SafeInnerHtml = ({ unsafeInnerHtmlText }: SafeInnerHtmlProps) => {
  return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(unsafeInnerHtmlText || '<div />') }} />;
};

export default SafeInnerHtml;
