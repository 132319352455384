const BYTE_SIZE_TYPES = ['bytes', 'KB', 'MB', 'GB'];
export const BYTE_SIZE = { bytes: 0, KB: 1, MB: 2, GB: 3 };

function isInt(n: number) {
  return n % 1 === 0;
}

export const byteMe = (size: number, type = BYTE_SIZE.bytes, isObject = false): string | 0 => {
  let i = type;
  if (typeof size !== 'number') {
    return 0;
  }
  while (size > 1024) {
    i++;
    size = size / 1024;
  }
  if (isObject) return BYTE_SIZE_TYPES[i];
  return `${isInt(parseFloat(Number(size).toFixed(2))) ? Number(size.toFixed(2)) : Number(size).toFixed(2)} ${
    BYTE_SIZE_TYPES[i]
  }`;
};
