import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Switch, Tab, Tabs } from '@mui/material';
import { ValueFormatterParams } from '@ag-grid-community/core';

import { reportsStateAtom, reportsStateI } from '../../../atoms/reports.atom';
import {
  BodyDrawer,
  CellTitle,
  Close,
  Container,
  DataGridUI,
  DataGridUIContainer,
  HeaderDrawer,
  TitleDrawer,
  ToggleWrapper
} from '../Reports.styled';
import TableLoader from '../../../components/Loader/TableLoader';
import { DBExtensionResponse, Extension, ReportAtomState } from './types';
import MTooltip from '../../../core/Tooltip/Tooltip';
import Typography from '../../../core/Typography';
import { SeverityLabel } from '../../../components/SeverityLabel/SeverityLabel';
import MDrawer, { toggleDrawer } from '../../../core/Drawer/Drawer';
import { AssertionTab } from '../../../components/AssertionsTab/AssertionTab';
import { PriorityTextToNumber } from '../../../enums';
import { ReportTabState, getAssertions } from '../utils';
import Flex from '../../../core/Flex';
import { getExtensionsReportAPI } from '../utils/ReportAPI';
import { RestClientContext } from '../../../auth/RestClientAuthProvider';
import { debugModeState } from '../../../atoms/debug-mode.atom';

const insightsFormatter = (params: ValueFormatterParams) => {
  return (
    params?.data?.insight && <SeverityLabel priority={Number.parseInt(PriorityTextToNumber[params?.data?.insight?.priority])} />
  );
};

export const DbExtensionsReport = () => {
  const type: ReportAtomState = 'dataExtension';
  const restClient = useContext(RestClientContext);
  const isDebugMode = useRecoilValue(debugModeState);
  const [filterInsights, setFilterInsights] = useState(false);
  const [assertionIndex, setAssertionIndex] = useState(0);
  const [{ [type]: reportsState, selectedType }, setReportsState] = useRecoilState(reportsStateAtom);
  const { projectApiKey = '', pmcDeviceId = '' } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [insight, setInsight]: any[] = useState([]);

  const [columnDefs] = useState([
    {
      field: 'name',
      headerName: 'Name',
      valueGetter: (params: any) => params?.data?.name
      // filter: 'agSetColumnFilter'
    },
    {
      field: 'setting',
      headerName: 'Installed Version',
      flex: 0.5,
      // filter: 'agSetColumnFilter',
      valueGetter: (params: any) => params?.data?.installed_version
    },
    {
      headerName: 'Default Version',
      flex: 0.5,
      valueGetter: (params: any) => params?.data?.default_version
    },
    {
      headerName: 'Comment',
      flex: 1.5,
      valueGetter: (params: any) => params?.data?.comment,
      cellRenderer: ({ value }: ValueFormatterParams) => (
        <CellTitle>
          <MTooltip disable={value?.length < 80} title={<Typography>{value}</Typography>}>
            <Typography>{value}</Typography>
          </MTooltip>
        </CellTitle>
      )
    },
    {
      headerName: 'Insights',
      sortable: true,
      sort: 'desc',
      cellRenderer: insightsFormatter,
      flex: 0.5,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      valueGetter: (params: ValueFormatterParams) => params?.data?.context?.insight,
      comparator: (valueA: number, valueB: number, nodeA: any, nodeB: any) => {
        const priorityA = PriorityTextToNumber[nodeA?.data?.insight?.priority || 'INFO'];
        const priorityB = PriorityTextToNumber[nodeB?.data?.insight?.priority || 'INFO'];
        if (priorityA === priorityB) return 0;
        return priorityA > priorityB ? -1 : 1;
      }
    }
  ]);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1
    }),
    []
  );

  const getReports = async () => {
    setLoading(true);
    const extensionResponse: DBExtensionResponse | undefined = await getExtensionsReportAPI(
      restClient,
      projectApiKey,
      pmcDeviceId
    );
    setReportsState((old: reportsStateI) => ({
      ...old,
      selectedType: type,
      [type]: { rows: extensionResponse?.extensions || [], lastUpdateIsoDate: extensionResponse?.updated_at || '' }
    }));

    setLoading(false);
  };
  useEffect(() => {
    if (selectedType !== type) {
      setReportsState((old) => ({
        ...old,
        selectedType: type,
        pmcDeviceId
      }));
    }

    if (projectApiKey && pmcDeviceId) {
      getReports();
    }
  }, [projectApiKey, pmcDeviceId]);

  const filteredData = useMemo(
    () => (!filterInsights ? reportsState.rows : reportsState.rows.filter((index: Extension) => index.insight)),
    [filterInsights, reportsState.rows.length, pmcDeviceId]
  );
  return (
    <>
      <Container>
        {isDebugMode && (
          <ToggleWrapper>
            <MTooltip title={<Typography>{filterInsights ? 'Show all' : 'Show Extensions with Insights only'}</Typography>}>
              <Switch color="secondary" checked={filterInsights} onChange={() => setFilterInsights(!filterInsights)} />
            </MTooltip>
          </ToggleWrapper>
        )}

        <DataGridUIContainer className="ag-theme-material">
          {loading ? (
            <TableLoader size={10} />
          ) : (
            <DataGridUI
              rowData={filteredData}
              animateRows={true}
              columnDefs={columnDefs}
              multiSortKey={'ctrl'}
              suppressCellFocus={true}
              defaultColDef={defaultColDef}
              onRowClicked={(row: any) => {
                row?.data?.insight && setInsight([row?.data?.insight]);
              }}
            />
          )}
        </DataGridUIContainer>
      </Container>
      <MDrawer onClose={() => toggleDrawer(() => setInsight([]))} anchor="right" open={!!insight.length}>
        <HeaderDrawer justify="space-between">
          <Flex margin="16px">
            <TitleDrawer h3 weight="600">
              Extensions Details
            </TitleDrawer>
          </Flex>
          <Flex margin="16px">
            <Close title="close-icon" onClick={() => setInsight([])} />
          </Flex>
        </HeaderDrawer>
        <BodyDrawer>
          <Tabs sx={{ minHeight: '35px', ml: 1 }} value={0}>
            {ReportTabState.map((tabItem: string) => (
              <Tab key={tabItem} sx={{ textTransform: 'none', fontWeight: 600 }} id={`report-tab-${tabItem}`} label={tabItem} />
            ))}
          </Tabs>
          <AssertionTab
            assertions={getAssertions(insight)}
            headHeight="120px"
            facts={{}}
            assertionCurrentIndex={assertionIndex}
            setAssertionIndexCallback={setAssertionIndex}
            tableInfos={[]}
            changeTabActivity={0}
            isLoading={loading}
            selectedQuery={0}
            extensionsReportAssertions={true}
            isSidebar={true}
          />
        </BodyDrawer>
      </MDrawer>
    </>
  );
};
