import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

export const generateTraceFromObject = (rawData: any) => {
  const traceId = uuidv4();
  
  const tag =
    (rawData['Tag Key'] &&
      rawData['Tag Value'] && {
        [`app.tag.${rawData['Tag Key'].toLocaleLowerCase()}`]: rawData['Tag Value']
      }) ||
    {};
  const routeSpan = generateRouteSpan(rawData.duration, rawData['Service Name'], tag, rawData.url, traceId);
  const querySpans = !rawData['queries and plans']
    ? []
    : rawData['queries and plans'].map((item: any) => {
        return generateQuerySpan(rawData.duration, rawData['Service Name'], tag, item.query, item.plan, traceId);
      });

  return [routeSpan, ...querySpans];
};

export const generateRouteSpan = (duration: number, serviceName: string, tag: any, url: string, traceId: string) => {
  const routeStartTime = new Date();
  const routeEndTime = dayjs(routeStartTime).add(duration, 'millisecond');

  return {
    kind: 'SpanKind.SERVER',
    name: `/debug/`,
    links: [],
    events: [],
    status: {
      status_code: 'UNSET'
    },
    context: {
      span_id: `${uuidv4()}`,
      trace_id: traceId,
      trace_state: '[]'
    },
    end_time: `${routeEndTime.toISOString()}`,
    resource: {
      'service.name': serviceName,
      'telemetry.sdk.name': 'opentelemetry',
      'telemetry.sdk.version': '1.11.1',
      'telemetry.sdk.language': 'python',
      ...tag
    },
    parent_id: null,
    attributes: {
      'http.url': 'http://test:None/v1/policies/environment/',
      'http.host': 'test:None',
      'http.route': url,
      'http.flavor': '1.1',
      'http.method': 'POST',
      'http.scheme': 'http',
      'http.target': url,
      'net.peer.ip': '127.0.0.1',
      'net.peer.port': 123,
      'track.by.metis': true,
      'http.user_agent': 'python-httpx/0.21.3',
      'http.server_name': 'test',
      'http.status_code': 200,
      'http.request.header.content_type': ['application/json'],
      'http.response.header.content_type': ['application/json'],
      'http.response.header.content_length': ['643']
    },
    start_time: `${routeStartTime.toISOString()}`
  };
};

export const generateQuerySpan = (duration: number, serviceName: string, tag: any, query: any, plan: any, traceId: string) => {
  const routeStartTime = new Date();
  const routeEndTime = dayjs(routeStartTime).add(duration, 'millisecond');

  const planParsed = Array.isArray(plan) ? plan[0] : plan;

  const queryStartTime = dayjs(routeStartTime).add(5, 'millisecond');
  const queryEndTime = dayjs(routeEndTime).subtract(5, 'millisecond');

  return {
    kind: 'SpanKind.CLIENT',
    name: 'SELECT postgres',
    links: [],
    events: [],
    status: {
      status_code: 'UNSET'
    },
    context: {
      span_id: `${uuidv4()}`,
      trace_id: traceId,
      trace_state: '[]'
    },
    end_time: queryEndTime.toISOString(),
    start_time: queryStartTime.toISOString(),
    resource: {
      'service.name': serviceName,
      'telemetry.sdk.name': 'opentelemetry',
      'telemetry.sdk.version': '1.11.1',
      'telemetry.sdk.language': 'python',
      ...tag
    },
    parent_id: null,
    attributes: {
      'db.name': 'postgres',
      'db.user': 'admin',
      'db.system': 'postgresql',
      'db.statement.metis': `${query}`,
      'net.peer.name': '127.0.0.1',
      'net.peer.port': 5435,
      'db.statement.metis.plan': JSON.stringify(planParsed)
    }
  };
};
