import React from 'react';
import { createRestClient } from '../common/rest-client';
import { KyInstance } from 'ky/distribution/types/ky';
import ky from 'ky';

export const RestClientContext = React.createContext<KyInstance>(ky);

export const RestClientProvider = ({ children }: { children: React.ReactNode }) => {
  const restClient = createRestClient();
  return <RestClientContext.Provider value={restClient}>{children}</RestClientContext.Provider>;
};
