import ky from 'ky';
import * as Sentry from '@sentry/react';
import { useAuth, ContextHolder } from '@frontegg/react';

const errorInitState = {
  counter: 0,
  URL: ''
};
export const createRestClient = () => {
  const retryErrorState = errorInitState;
  const { user } = useAuth();

  return ky.create({
    prefixUrl: `${window.location.origin}/api/`,
    retry: {
      limit: 1,
      statusCodes: [500, 408],
      methods: ['get']
    },
    timeout: 30000,
    hooks: {
      beforeRequest: [
        async (request) => {
          const token = user?.accessToken;
          request.headers.set('Authorization', `Bearer ${token}`);
          request.headers.set('userId', user?.id || '');
        }
      ],
      // afterResponse: [
      //   async (_input, _options, response) => {
      //     if (response.status === 401) {
      //       const baseUrl = ContextHolder.getContext().baseUrl;
      //       window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
      //     }
      //     if (response?.status === 404) {
      //       window.location.replace('/404');
      //     }
      //   }
      // ],
      beforeError: [
        (error) => {
          const { response } = error;
          //detect API Error and send only once to sentry also print the error to logs with counter (the max counter is 4)
          if (response?.url && retryErrorState.URL !== response?.url) {
            retryErrorState.counter = 1;
            retryErrorState.URL = response?.url;
            Sentry.captureException(new Error(`Error-${response?.status}-${response?.url}`), {
              extra: {
                ErrorMessage: `StatusCode: ${response?.status}, URL:${response?.url}`,
                ...getErrorHeaderParams(response?.body)
              }
            });
          } else {
            retryErrorState.counter++;
          }
          console.warn({ statusCode: response?.status, URL: response?.url, retryCount: retryErrorState.counter });
          return error;
        }
      ]
    }
  });
};

export const getErrorHeaderParams = (body: any) => ({
  'X-Amz-Cf-Id': body?.headers?.['X-Amz-Cf-Id'],
  'X-Ray-Id': body?.headers?.['X-Ray-Id']
});
