import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { reportsStateAtom, reportsStateI } from '../../../atoms/reports.atom';
import { BodyDrawer, CellTitle, Container, DataGridUI, DataGridUIContainer, ToggleWrapper } from '../Reports.styled';
import TableLoader from '../../../components/Loader/TableLoader';
import { DBConfigType, ReportAtomState } from './types';
import { ValueFormatterParams } from '@ag-grid-community/core';
import Typography from '../../../core/Typography';
import MTooltip from '../../../core/Tooltip/Tooltip';
import MDrawer, { toggleDrawer } from '../../../core/Drawer/Drawer';
import { AssertionTab } from '../../../components/AssertionsTab/AssertionTab';
import { getAssertions } from '../utils';
import { getDbConfigReportAPI } from '../utils/ReportAPI';
import { RestClientContext } from '../../../auth/RestClientAuthProvider';
import { SeverityLabel } from '../../../components/SeverityLabel/SeverityLabel';
import { PriorityTextToNumber } from '../../../enums';
import { debugModeState } from '../../../atoms/debug-mode.atom';
import { Switch } from '@mui/material';

const insightsFormatter = (params: ValueFormatterParams) => {
  return (
    params?.data?.insight && <SeverityLabel priority={Number.parseInt(PriorityTextToNumber[params?.data?.insight?.priority])} />
  );
};

export const DbConfigReport = () => {
  const type: ReportAtomState = 'dataDBConfig';
  const isDebugMode = useRecoilValue(debugModeState);
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [rowData, setRowData]: any[] = useState([]);
  const [assertionIndex, setAssertionIndex] = useState(0);
  const restClient = useContext(RestClientContext);
  const [filterInsights, setFilterInsights] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { projectApiKey = '', pmcDeviceId = '' } = useParams();
  const [{ [type]: reportsState, selectedType }, setReportsState] = useRecoilState(reportsStateAtom);

  const [columnDefs] = useState([
    {
      field: 'name',
      headerName: 'Configuration Property',
      valueGetter: (params: any) => params?.data?.name?.toLowerCase?.()
    },
    {
      field: 'setting',
      sortable: false,
      headerName: 'Setting',
      valueGetter: (params: any) => params?.data?.setting
    },
    {
      headerName: 'Description',
      flex: 2,
      valueGetter: (params: any) => params?.data?.description,
      cellRenderer: ({ value }: ValueFormatterParams) => (
        <CellTitle>
          <MTooltip disable={value?.length < 90} title={<Typography>{value}</Typography>}>
            <Typography>{value}</Typography>
          </MTooltip>
        </CellTitle>
      )
    },
    {
      headerName: 'Insights',
      sortable: true,
      sort: 'desc',
      cellRenderer: insightsFormatter,
      flex: 0.5,
      cellStyle: { justifyContent: 'left', alignItems: 'center', display: 'flex' },
      valueGetter: (params: any) => params?.data?.context?.insight,
      comparator: (valueA: number, valueB: number, nodeA: any, nodeB: any) => {
        const priorityA = PriorityTextToNumber[nodeA?.data?.insight?.priority || 'INFO'];
        const priorityB = PriorityTextToNumber[nodeB?.data?.insight?.priority || 'INFO'];
        if (priorityA === priorityB) return 0;
        return priorityA > priorityB ? -1 : 1;
      }
    }
  ]);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      alwaysMultiSort: true
    }),
    []
  );

  const getReports = async () => {
    setLoading(true);
    const configReportRes: DBConfigType | undefined = await getDbConfigReportAPI(restClient, projectApiKey, pmcDeviceId);
    setReportsState((old: reportsStateI) => ({
      ...old,
      selectedType: type,
      [type]: { rows: configReportRes?.config || [], lastUpdateIsoDate: configReportRes?.updated_at || '' }
    }));
    setLoading(false);
  };

  useEffect(() => {
    if (selectedType !== type) {
      setReportsState((old) => ({
        ...old,
        selectedType: type,
        pmcDeviceId
      }));
    }
    if (projectApiKey && pmcDeviceId) {
      getReports();
    }
  }, [projectApiKey, pmcDeviceId]);

  const filteredData = useMemo(
    () => (!filterInsights ? reportsState.rows : reportsState.rows.filter((index: any) => index.insight)),
    [filterInsights, reportsState.rows.length, pmcDeviceId]
  );

  return (
    <>
      <Container>
        {/* <ToggleWrapper>
          <MTooltip title={<Typography>{filterInsights ? 'Show all' : 'Show Configurations with Insights only'}</Typography>}>
            <Switch color="secondary" checked={filterInsights} onChange={() => setFilterInsights(!filterInsights)} />
          </MTooltip>
        </ToggleWrapper> */}

        <DataGridUIContainer className="ag-theme-material">
          {isLoading ? (
            <TableLoader size={10} />
          ) : (
            <DataGridUI
              rowData={filteredData}
              animateRows={true}
              columnDefs={columnDefs}
              suppressCellFocus={true}
              multiSortKey={'ctrl'}
              defaultColDef={defaultColDef}
              onRowClicked={(row: any) => {
                setDrawerState(row?.data?.insight !== undefined);
                setRowData([row?.data?.insight]);
              }}
            />
          )}
        </DataGridUIContainer>
      </Container>
      <MDrawer onClose={() => toggleDrawer(() => setDrawerState(false))} anchor="right" open={drawerState}>
        <BodyDrawer>
          <AssertionTab
            assertions={getAssertions(rowData)}
            headHeight="250px"
            facts={{}}
            assertionCurrentIndex={assertionIndex}
            setAssertionIndexCallback={setAssertionIndex}
            tableInfos={[]}
            changeTabActivity={0}
            isLoading={isLoading}
            selectedQuery={0}
            extensionsReportAssertions={true}
            isSidebar={true}
          />
        </BodyDrawer>
      </MDrawer>
    </>
  );
};
