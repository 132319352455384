import styled from 'styled-components';
import Flex from '../../core/Flex';
import Typography from '../../core/Typography';

export const AssertionsTabContainer = styled.div<{ headHeight?: string }>`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: inherit;
  position: relative;
  height: ${({ headHeight }) => (headHeight ? `calc(100vh - ${headHeight})` : 'calc(100vh - 390px)')};
`;

export const AssertionContainer = styled.div`
  flex: 0.25;
  min-width: 250px;
  overflow: scroll;
  padding-bottom: 5px;
`;

export const AssertionDetailsContainer = styled.div`
  padding: 5px 5px 0px 5px;
  flex: 0.75;
  width: 100%;
  overflow: auto;
`;

export const EmptyAssertionContainer = styled(Flex)`
  position: absolute;
  left: 30%;
  top: 50%;
`;
export const EmptyAssertionTitle = styled(Typography)`
  color: ${({ theme, color }) => color || theme.colors.text.title};
  padding-top: 4px;
`;

export const AssertionDetailsTitleContainer = styled(Flex)`
  width: 100%;
`;

export const CommingSoonContainer = styled.div`
  border-radius: 10px;
  margin-bottom: 5px;
  padding: 5px 8px 0px 0px;
`;
