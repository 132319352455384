import styled from 'styled-components';
import Typography from '../../core/Typography';

export const LogContainer = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
  padding: 0 5px;
`;

export const EllipsisText = styled.div<any>`
  text-overflow: ellipsis;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
`;
export const Ul = styled.ul<any>`
  padding: 0;
  padding-left: 19px;
  margin: 0;
`;

export const Title = styled(Typography)`
  padding-right: 5px;
  color: ${({ theme }) => theme.colors.text.title};
  margin: 10px 0px 5px 0px;
`;
