import { useRecoilState } from 'recoil';
import { useEffect, useState, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { ValueFormatterParams } from '@ag-grid-community/core';
import { reportsStateAtom, reportsStateI } from '../../../atoms/reports.atom';
import Flex from '../../../core/Flex';
import { TrendDataGridCell } from '../common/TrendDataGridCell';
import { SeverityLabel } from '../../../components/SeverityLabel/SeverityLabel';

import { BodyDrawer, Close, Container, DataGridUI, DataGridUIContainer, HeaderDrawer, TitleDrawer } from '../Reports.styled';
import TableLoader from '../../../components/Loader/TableLoader';
import { DataIndex, ReportAtomState } from './types';
import MDrawer, { toggleDrawer } from '../../../core/Drawer/Drawer';
import { AssertionTab } from '../../../components/AssertionsTab/AssertionTab';
import { ReportTabState } from '../utils';
import { getIndexUsageAPI } from '../utils/ReportAPI';
import { RestClientContext } from '../../../auth/RestClientAuthProvider';

export const IndexUsage = () => {
  const type: ReportAtomState = 'dataIndex';
  const restClient = useContext(RestClientContext);

  const [isLoading, setLoading] = useState(false);
  const [filterInsights, setFilterInsights] = useState(false);
  const [insight, setInsight] = useState<any>([]);
  const [{ [type]: reportsState, selectedType }, setReportsState] = useRecoilState(reportsStateAtom);

  const { projectApiKey = '', pmcDeviceId = '' } = useParams();
  const [assertionIndex, setAssertionIndex] = useState(0);

  const getReports = async () => {
    setLoading(true);
    const indexUsageRes: { rows: DataIndex[]; lastUpdateIsoDate: string } | undefined = await getIndexUsageAPI(
      restClient,
      projectApiKey,
      pmcDeviceId
    );
    setReportsState((old: reportsStateI) => ({
      ...old,
      selectedType: type,
      [type]: { rows: indexUsageRes?.rows || [], lastUpdateIsoDate: indexUsageRes?.lastUpdateIsoDate || '' }
    }));
    setLoading(false);
  };

  useEffect(() => {
    if (selectedType !== type) {
      setReportsState((old) => ({
        ...old,
        selectedType: type,
        pmcDeviceId
      }));
    }
    if (projectApiKey && pmcDeviceId) {
      getReports();
    }
  }, [projectApiKey, pmcDeviceId]);

  const filteredData = useMemo(
    () => (!filterInsights ? reportsState.rows : reportsState.rows.filter((index: DataIndex) => index.context.insights)),
    [filterInsights, pmcDeviceId, reportsState.rows.length]
  );
  const insightsFormatter = (params: ValueFormatterParams) => {
    return params?.value?.length && <SeverityLabel priority={params.value[0]?.priority} />;
  };
  const [columnDefs] = useState([
    {
      sortable: true,
      flex: 1,
      headerName: 'Schema Name',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      valueGetter: (params: any) => params.data?.context?.schema
    },
    {
      headerName: 'Table Name',
      sortable: true,
      flex: 1,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      valueGetter: (params: any) => params.data?.context?.table
    },
    {
      headerName: 'Index Name',
      sortable: true,
      flex: 1,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      valueGetter: (params: any) => params.data?.context?.index
    },
    {
      field: 'history',
      headerName: 'Daily Usage',
      sortable: false,
      flex: 0.6,
      valueGetter: (params: any) => params.data?.values,
      cellRenderer: ({ value }: ValueFormatterParams) => <TrendDataGridCell data={value} />
    },
    {
      headerName: 'Insights',
      sortable: true,
      sort: 'desc',
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      cellRenderer: insightsFormatter,
      valueGetter: (params: ValueFormatterParams) => params?.data?.context?.insights,
      comparator: (valueA: number, valueB: number, nodeA: any, nodeB: any) => {
        const priorityA = nodeA?.data?.context?.insights?.[0]?.priority || 0;
        const priorityB = nodeB?.data?.context?.insights?.[0]?.priority || 0;
        if (priorityA === priorityB) return 0;
        return priorityA > priorityB ? 1 : -1;
      }
    }
  ]);

  return (
    <>
      <Container>
        {/* <ToggleWrapper>
          <MTooltip title={<Typography>{filterInsights ? 'Show all' : 'Show Indexes with Insights only'}</Typography>}>
            <Switch color="secondary" checked={filterInsights} onChange={() => setFilterInsights(!filterInsights)} />
          </MTooltip>
        </ToggleWrapper> */}
        <DataGridUIContainer className="ag-theme-material">
          {isLoading ? (
            <TableLoader size={10} />
          ) : (
            <DataGridUI
              rowData={filteredData}
              animateRows={true}
              columnDefs={columnDefs}
              rowHeight={75}
              suppressCellFocus={true}
              onRowClicked={(row: any) => {
                row?.data?.context?.insights && setInsight(row.data.context.insights);
              }}
            />
          )}
        </DataGridUIContainer>
      </Container>
      <MDrawer onClose={() => toggleDrawer(() => setInsight([]))} anchor="right" open={!!insight.length}>
        <HeaderDrawer justify="space-between">
          <Flex margin="16px">
            <TitleDrawer h3 weight="600">
              Index Usage Details
            </TitleDrawer>
          </Flex>
          <Flex margin="16px">
            <Close title="close-icon" onClick={() => setInsight([])} />
          </Flex>
        </HeaderDrawer>
        <BodyDrawer>
          <Tabs sx={{ minHeight: '35px', ml: 1 }} value={0}>
            {ReportTabState.map((tabItem: string) => (
              <Tab key={tabItem} sx={{ textTransform: 'none', fontWeight: 600 }} id={`report-tab-${tabItem}`} label={tabItem} />
            ))}
          </Tabs>
          <AssertionTab
            assertions={insight}
            headHeight="120px"
            facts={{}}
            assertionCurrentIndex={assertionIndex}
            setAssertionIndexCallback={setAssertionIndex}
            tableInfos={[]}
            changeTabActivity={0}
            isLoading={isLoading}
            selectedQuery={0}
            extensionsReportAssertions={true}
            isSidebar={true}
          />
        </BodyDrawer>
      </MDrawer>
    </>
  );
};
