import { styled as MuiStyle } from '@mui/material/styles';
import { Button as MuiButton } from '@mui/material';
import styled from 'styled-components';

interface ButtonProps {
  onClick: () => void;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'link' | 'danger';
  startIcon?: React.ReactNode;
  sx?: any;
  endIcon?: React.ReactNode;
  children: React.ReactNode;
  href?: string;
  target?: string;
}

const Button = ({ onClick, disabled, startIcon, variant, endIcon, children, ...props }: ButtonProps) => {
  switch (variant) {
    case 'link':
      return (
        <LinkButton {...props} className={`disabled-${disabled}`} onClick={disabled ? () => null : onClick} rel="noreferrer">
          {children}
        </LinkButton>
      );
    case 'secondary':
      return (
        <SecondaryButton {...props} onClick={onClick} startIcon={startIcon} endIcon={endIcon} disabled={disabled}>
          {children}
        </SecondaryButton>
      );

    case 'danger':
      return (
        <DangerButtonStyled
          {...props}
          onClick={onClick}
          startIcon={startIcon}
          endIcon={endIcon}
          variant="contained"
          disabled={disabled}
        >
          {children}
        </DangerButtonStyled>
      );
    default:
      return (
        <PrimaryButtonStyled
          {...props}
          onClick={onClick}
          startIcon={startIcon}
          endIcon={endIcon}
          variant="contained"
          disabled={disabled}
        >
          {children}
        </PrimaryButtonStyled>
      );
  }
};

export default Button;

const PrimaryButtonStyled = MuiStyle(MuiButton)`
text-transform: none;
padding: 5px 10px 4px;
border-radius: 4px;
color: #091235;
min-height:30px;
background: #66DFC8;
&:hover {
  background-color:#38AFA2;
}
&:disabled {
  color: #6B7383;
}
`;

const DangerButtonStyled = MuiStyle(PrimaryButtonStyled)`
  color:#fff;
  background: #ef5350;
  &:hover {
    background-color:#f44336;
  }
`;
const SecondaryButton = MuiStyle(PrimaryButtonStyled)`
margin:0;
background: #C8F4EB;
border: 1px solid #66DFC8;
&:hover {
  background-color:#ACEEE1;
}
`;

const LinkButton = styled.a`
  color: ${({ theme }) => theme.colors.info.hover};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.info.main};
  }
`;
