import { Container, Content, HeaderContainer, Title } from './InsightDetailsCard.styled';
import Flex from '../../core/Flex';
import { SeverityLabel } from '../SeverityLabel/SeverityLabel';
import { Assertion } from '../../types/Assertion.type';
import MTooltip from '../../core/Tooltip/Tooltip';
import Typography from '../../core/Typography';

interface Props {
  assertionDetails: Assertion | any;
  callback?: any;
  index?: number;
  choosen: boolean;
  isSidebar?: boolean;
}

const InsightDetailsCard = ({ assertionDetails, callback, choosen, index, isSidebar }: Props) => {
  return (
    <Container
      onClick={() => callback({ idx: index, ...assertionDetails })}
      align={'start'}
      choosen={choosen}
      justify={'space-evenly'}
      direction={'column'}
      key={assertionDetails?.title}
    >
      <HeaderContainer>
        <Flex style={{ width: 'inherit' }} justify={'space-between'}>
          <MTooltip disable={assertionDetails.title?.length < 30} title={<Typography>{assertionDetails?.title}</Typography>}>
            <Title weight={600} isSidebar={isSidebar}>
              {assertionDetails?.title}
            </Title>
          </MTooltip>

          <SeverityLabel priority={assertionDetails?.condition?.event?.priority || assertionDetails?.priority} />
        </Flex>
      </HeaderContainer>
      {!assertionDetails?.ruleId?.includes('SQL') && <Content weight={400}>{assertionDetails?.ruleResult}</Content>}
    </Container>
  );
};

export default InsightDetailsCard;
