import { Skeleton } from '@mui/material';
import styled from 'styled-components';

import Flex from '../../core/Flex';

export const Container = styled(Flex)`
  flex: 0.25;
  min-width: 250px;
  overflow: scroll;
  padding-bottom: 5px;
`;
export const Item = styled(Skeleton)`
  margin: 0 10px 6px 10px;
`;
const InsightLoader = () => {
  return (
    <Container direction="column" align="start" justify="start">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
        <Item key={i} variant="rectangular" animation="wave" width={'90%'} height={40} />
      ))}
    </Container>
  );
};

export default InsightLoader;
