import { useLocation, useParams } from 'react-router-dom';
import useNavigateBack from '../../hooks/useNavigateBack';
import Flex from '../Flex';
import { BreadcrumbsImg, BreadcrumbsText, Container } from './Breadcrumbs.styled';
import MTooltip from '../Tooltip/Tooltip';
import { ApiKeyType, useActiveAPIKey } from '../../atoms/api-keys.atom';

type IBreadcrumbsList = {
  name: string;
  isActive?: boolean;
  navigateTo?: string;
};

interface BreadcrumbsI {
  projectName?: string;
  breadcrumbsList: IBreadcrumbsList[];
  isGlobalPage?: boolean;
}

const Breadcrumbs = ({ projectName, breadcrumbsList = [], isGlobalPage = false }: BreadcrumbsI) => {
  const navigateBack = useNavigateBack();
  const { projectApiKey = '' } = useParams();
  const { state }: any = useLocation();
  const selectedApiKey: ApiKeyType = useActiveAPIKey(projectApiKey);
  const isQueryAnalyzerPage = projectName === 'Query Analyzer';
  return (
    <>
      <Container justify="start">
        <Flex>
          {!isGlobalPage && (
            <>
              <BreadcrumbsText h4 data-cy="title" onClick={() => navigateBack({ navigateTo: `/projects` })}>
                Projects
              </BreadcrumbsText>
              <BreadcrumbsImg title="arrow-next" />
            </>
          )}
          <BreadcrumbsText
            h4
            isActive={!breadcrumbsList.length}
            data-cy="title"
            onClick={() =>
              isQueryAnalyzerPage
                ? navigateBack({ navigateTo: `/query-analysis` })
                : navigateBack({ navigateTo: `/projects/${projectApiKey}` })
            }
          >
            {projectName || selectedApiKey.label}
          </BreadcrumbsText>
        </Flex>

        {breadcrumbsList.map((item: IBreadcrumbsList, i: number) => (
          <Flex key={i}>
            <BreadcrumbsImg title="arrow-next" />
            <MTooltip title={item.name} disable={item.name?.length < 50}>
              <BreadcrumbsText
                h4
                isActive={item.isActive}
                onClick={() => {
                  if (!item.isActive) {
                    item?.navigateTo ? navigateBack({ ...state, navigateTo: item.navigateTo }) : navigateBack(state);
                  }
                }}
              >
                {item.name}
              </BreadcrumbsText>
            </MTooltip>
          </Flex>
        ))}
      </Container>
    </>
  );
};

export default Breadcrumbs;
