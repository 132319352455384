import { KyInstance } from 'ky/distribution/types/ky';
import {
  DBConfigType,
  DBExtensionResponse,
  DataIndex,
  DataTable,
  QueryStats,
  Rows,
  queryStatisticDataItemT
} from '../reports/types';
import { analyticsTrack } from '../../../utils/segment-analytics';
import dayjs from 'dayjs';
import { populateZeros } from '.';

export const EmptyReport = { rows: [], lastUpdateIsoDate: '', offset: 0 };

export const getStatisticQueryByIdAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  query_id: string
): Promise<queryStatisticDataItemT[]> => {
  try {
    const resQueryItem: queryStatisticDataItemT[] = await restClient
      .get(`statistics/query/${query_id}`, {
        headers: {
          'x-api-key': projectApiKey
        }
      })
      .json();
    if (resQueryItem.length) {
      analyticsTrack('getQueryStatisticById Success', resQueryItem);
      return resQueryItem.map((i: queryStatisticDataItemT) => {
        const callsDelta = Number(i?.calls_delta || 0);
        const totalExecTimeDelta = Number(i?.total_exec_time_delta || 0);
        let hourly_avg_duration: number;
        if (totalExecTimeDelta / callsDelta === Infinity) {
          hourly_avg_duration = totalExecTimeDelta;
        } else if (Number.isNaN(totalExecTimeDelta / callsDelta)) {
          hourly_avg_duration = +i.mean_exec_time;
        } else {
          hourly_avg_duration = totalExecTimeDelta / callsDelta;
        }
        return {
          ...i,
          mean_exec_time: Number(i?.mean_exec_time),
          created_at: i?.created_at,
          formatted_date: dayjs(i.created_at).format('MMM-DD-YY HH:00'),
          calls: Number(i?.calls ?? 0) / 1000,
          calls_delta: callsDelta,
          calls_deltaRed: Math.abs(callsDelta),
          avg_duration_delta: callsDelta > 0 ? totalExecTimeDelta / callsDelta / 1000 ?? 0 : 0,
          hourly_avg_duration
        };
      });
    }
    return [];
  } catch (error: any) {
    analyticsTrack('getQueryStatisticById Error');
    return [];
  }
};
export const getStatisticQueryAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  pmcDeviceId: string,
  offset: number
): Promise<{ rows: QueryStats[]; lastUpdateIsoDate: string }> => {
  try {
    const { data, last_updated = '' }: { data: QueryStats[]; last_updated: string } = await restClient
      .get(`statistics/query/top`, {
        headers: {
          'x-api-key': projectApiKey
        },
        searchParams: {
          size: 50,
          pmcDeviceId: pmcDeviceId,
          offset: offset
        }
      })
      .json();
    analyticsTrack('getStatisticQuery Success', data);
    return { rows: data, lastUpdateIsoDate: last_updated };
  } catch (error: any) {
    analyticsTrack('QueryStatistics fetch Error');
    return EmptyReport;
  }
};

export const getInsightsByIdAPI = async (restClient: KyInstance, projectApiKey: string, traceId: string): Promise<any[]> => {
  try {
    const res: any = await restClient
      .get(`traces/${traceId}`, {
        headers: { 'x-api-key': projectApiKey }
      })
      .json();
    return res?.queries?.length ? res?.queries : [];
  } catch (error: any) {
    analyticsTrack('getInsightsById fetch Error');
    return [];
  }
};

export const getExtensionsReportAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  pmcDeviceId: string
): Promise<DBExtensionResponse | undefined> => {
  try {
    const res: DBExtensionResponse = await restClient
      .get('pmc-device/extensions', {
        headers: {
          'x-api-key': projectApiKey
        },
        searchParams: {
          pmcDeviceId
        }
      })
      .json();

    return res;
  } catch (error: any) {
    analyticsTrack('getExtensionsReportAPI fetch Error');
  }
};
export const getTableRowSizeAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  pmcDeviceId: string
): Promise<{ rows: DataTable[]; lastUpdateIsoDate: string } | undefined> => {
  try {
    const res = await restClient
      .get('reports', {
        searchParams: {
          ['api-key']: projectApiKey ?? '',
          ['report-type']: 'numberOfRows',
          pmcDeviceId
        },
        headers: { 'x-api-key': projectApiKey }
      })
      .json();
    if (!res) return EmptyReport;
    const data: DataTable[] = Object.values(res);
    const rowsLength: number = data.length - 1;
    if (rowsLength) {
      const historyLength: number = (data[rowsLength] as DataTable)?.rows?.length - 1;
      const lastUpdateIsoDate: any = (data[rowsLength] as DataTable)?.rows?.[historyLength]?.time;

      return {
        rows: data,
        lastUpdateIsoDate
      };
    }
    return EmptyReport;
  } catch (error: any) {
    analyticsTrack('getIndexUsageAPI fetch Error');
  }
};
export const getIndexUsageAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  pmcDeviceId: string
): Promise<{ rows: DataIndex[]; lastUpdateIsoDate: string } | undefined> => {
  try {
    const res: Rows = await restClient
      .get('reports', {
        searchParams: {
          ['api-key']: projectApiKey ?? '',
          ['report-type']: 'indexUsage',
          pmcDeviceId
        }
      })
      .json();
    const data: DataIndex[] = Object.values(res);
    const DAILY_BAR_LENGTH = 14; // days
    for (const row of data) {
      if (row?.values?.length && row?.values?.length < DAILY_BAR_LENGTH) {
        populateZeros(DAILY_BAR_LENGTH, row?.values);
      }
    }

    if (data && Array.isArray(data) && data.length) {
      const historyLength: number = data[0]?.values?.length - 1;
      const lastUpdateIsoDate: any = data[0]?.values?.[historyLength]?.time;

      return {
        rows: data,
        lastUpdateIsoDate
      };
    }

    return EmptyReport;
  } catch (error: any) {
    analyticsTrack('getIndexUsageAPI fetch Error');
  }
};
export const getDbConfigReportAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  pmcDeviceId: string
): Promise<DBConfigType | undefined> => {
  try {
    const configReportRes: DBConfigType = await restClient
      .get('pmc-device/config', {
        headers: {
          'x-api-key': projectApiKey
        },
        searchParams: {
          pmcDeviceId
        }
      })
      .json();
    return configReportRes;
  } catch (error: any) {
    analyticsTrack('getDbConfigReportAPI fetch Error');
  }
};
