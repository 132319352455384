import { createIndexRemedationPlan } from '../create-index-remedation-plan';

import {
  RULE_SCHEMA_FK_01_AI,
  RULE_SCHEMA_IDX_01_AI,
  RULE_SCHEMA_PK_01_AI,
  RULE_01_SCHEMA_TBL_AI,
  RULE_01_SCHEMA_TGR_AI,
  RULE_SCHEMA_FK_02_AI,
  RULE_SCHEMA_IDX_02_AI,
  RULE_SCHEMA_PK_02_AI,
  RULE_SCHEMA_IDX_03_AI,
  RULE_SCHEMA_PK_03_AI,
  RULE_SCHEMA_STAT_03,
  RULE_SCHEMA_STAT_01,
  RULE_SCHEMA_PK_04_AI,
  RULE_SCHEMA_STRUCTURE_01
} from './ssa';
import { RULE_MIGRATION } from './migration-analysis';
import {
  RULE_SQL_01,
  RULE_SQL_02,
  RULE_SQL_03,
  RULE_SQL_06,
  RULE_SQL_07,
  RULE_SQL_08,
  RULE_SQL_11,
  RULE_SQL_20,
  RULE_SQL_21,
  RULE_SQL_22,
  RULE_SQL_23,
  RULE_SQL_24,
  RULE_SQL_A_09,
  RULE_SQL_E_05
} from './sql';

export enum INSIGHTS_KEYS {
  SQL00001 = 'SQL-00001',
  SQLE00002 = 'SQL-E-00002',
  SQLA00002 = 'SQL-A-00002',
  SQLE00003 = 'SQL-E-00003',
  SQLA00003 = 'SQL-A-00003',
  SQLE00005 = 'SQL-E-00005',
  SQL00006 = 'SQL-00006',
  SQLE00007 = 'SQL-E-00007',
  SQLA00007 = 'SQL-A-00007',
  SQLE00008 = 'SQL-E-00008',
  SQLA00009 = 'SQL-A-00009',
  SQLA00008 = 'SQL-A-00008',
  SQLA00011 = 'SQL-A-00011',
  SQL00020 = 'SQL-00020',
  SQL00021 = 'SQL-00021',
  SQL00022 = 'SQL-00022',
  SQL00023 = 'SQL-00023',
  SQL00024 = 'SQL-00024',
  RULE_SCHEMA_PK_01 = 'RULE_SCHEMA_PK_01',
  RULE_SCHEMA_PK_02 = 'RULE_SCHEMA_PK_02',
  RULE_SCHEMA_PK_03 = 'RULE_SCHEMA_PK_03',
  RULE_SCHEMA_PK_04 = 'RULE_SCHEMA_PK_04',
  RULE_SCHEMA_FK_01 = 'RULE_SCHEMA_FK_01',
  RULE_SCHEMA_FK_02 = 'RULE_SCHEMA_FK_02',
  RULE_SCHEMA_IDX_01 = 'RULE_SCHEMA_IDX_01',
  RULE_SCHEMA_IDX_02 = 'RULE_SCHEMA_IDX_02',
  RULE_SCHEMA_IDX_03 = 'RULE_SCHEMA_IDX_03',
  RULE_SCHEMA_TBL_01 = 'RULE_SCHEMA_TBL_01',
  RULE_SCHEMA_TGR_01 = 'RULE_SCHEMA_TGR_01',
  RULE_SCHEMA_STAT_01 = 'RULE_SCHEMA_STAT_01',
  RULE_SCHEMA_STAT_03 = 'RULE_SCHEMA_STAT_03',
  RULE_SCHEMA_STRUCTURE_01 = 'RULE_SCHEMA_STRUCTURE_01',
  RULE_MIGRATION_01 = '00001',
  RULE_MIGRATION_02 = '00002',
  RULE_MIGRATION_03 = '00003',
  RULE_MIGRATION_04 = '00004',
  RULE_MIGRATION_06 = '00006',
  RULE_MIGRATION_07 = '00007',
  RULE_MIGRATION_08 = '00008',
  RULE_MIGRATION_09 = '00009',
  RULE_MIGRATION_10 = '00010',
  RULE_MIGRATION_11 = '00011',
  RULE_MIGRATION_12 = 'sql-ddl-tbl-0001',
  RULE_MIGRATION_13 = 'sql-ddl-tbl-0002',
  RULE_MIGRATION_14 = 'sql-altr-tbl-0001',
  RULE_MIGRATION_15 = 'sql-ddl-idx-0001',
  RULE_MIGRATION_16 = 'sql-ddl-tbl-0004',
}

export const getInsightInvestigation = (
  facts: any,
  insight: string,
  tableInfos: any,
  changeTabActivity: any,
  selectedQuery: any,
  assertion?: any,
  tableItem?: any
) => {
  const insights = selectedQuery?.assertions?.success?.reduce((acc: any, current: any) => {
    acc[current?.ruleId] = true;
    return acc;
  }, {});
  const { table, isNeedNewIndex }: any = createIndexRemedationPlan(tableInfos, facts['ast-raw-data']);
  switch (insight) {
    /*  Migration Insights Starts */
    case INSIGHTS_KEYS.RULE_MIGRATION_01:
    case INSIGHTS_KEYS.RULE_MIGRATION_02:
    case INSIGHTS_KEYS.RULE_MIGRATION_03:
    case INSIGHTS_KEYS.RULE_MIGRATION_04:
    case INSIGHTS_KEYS.RULE_MIGRATION_06:
    case INSIGHTS_KEYS.RULE_MIGRATION_07:
    case INSIGHTS_KEYS.RULE_MIGRATION_08:
    case INSIGHTS_KEYS.RULE_MIGRATION_09:
    case INSIGHTS_KEYS.RULE_MIGRATION_10:
    case INSIGHTS_KEYS.RULE_MIGRATION_11:
    case INSIGHTS_KEYS.RULE_MIGRATION_12:
    case INSIGHTS_KEYS.RULE_MIGRATION_13:
    case INSIGHTS_KEYS.RULE_MIGRATION_14:
    case INSIGHTS_KEYS.RULE_MIGRATION_15:
    case INSIGHTS_KEYS.RULE_MIGRATION_16:
      return RULE_MIGRATION(assertion);
    /*  Migration Insights Ends */
    
    /*  SSA Insights Starts  */
    case INSIGHTS_KEYS.RULE_SCHEMA_PK_01:
      return RULE_SCHEMA_PK_01_AI(assertion, tableItem);
    case INSIGHTS_KEYS.RULE_SCHEMA_PK_02:
      return RULE_SCHEMA_PK_02_AI(assertion, tableItem);
    case INSIGHTS_KEYS.RULE_SCHEMA_PK_03:
      return RULE_SCHEMA_PK_03_AI(assertion, tableItem);
    case INSIGHTS_KEYS.RULE_SCHEMA_PK_04:
      return RULE_SCHEMA_PK_04_AI(assertion, tableItem);
    case INSIGHTS_KEYS.RULE_SCHEMA_FK_01:
      return RULE_SCHEMA_FK_01_AI(assertion, tableItem);
    case INSIGHTS_KEYS.RULE_SCHEMA_FK_02:
      return RULE_SCHEMA_FK_02_AI(assertion, tableItem); 
    case INSIGHTS_KEYS.RULE_SCHEMA_IDX_01:
        return RULE_SCHEMA_IDX_01_AI(assertion, tableItem); 
    case INSIGHTS_KEYS.RULE_SCHEMA_IDX_02:
        return RULE_SCHEMA_IDX_02_AI(assertion, tableItem);
    case INSIGHTS_KEYS.RULE_SCHEMA_IDX_03:
      return RULE_SCHEMA_IDX_03_AI(assertion, tableItem);
    case INSIGHTS_KEYS.RULE_SCHEMA_TBL_01:
      return RULE_01_SCHEMA_TBL_AI(assertion, tableItem);
    case INSIGHTS_KEYS.RULE_SCHEMA_TGR_01:
      return RULE_01_SCHEMA_TGR_AI(assertion, tableItem);
    case INSIGHTS_KEYS.RULE_SCHEMA_STAT_01:
      return RULE_SCHEMA_STAT_01(assertion, tableItem);
    case INSIGHTS_KEYS.RULE_SCHEMA_STAT_03:
      return RULE_SCHEMA_STAT_03(assertion, tableItem);
      case INSIGHTS_KEYS.RULE_SCHEMA_STRUCTURE_01:
        return RULE_SCHEMA_STRUCTURE_01(assertion, tableItem);
      
    /*  SSA Insights Ends   */

    /* SQL Insights Starts */
    case INSIGHTS_KEYS.SQL00001:
      return RULE_SQL_01(facts, assertion, tableInfos, isNeedNewIndex, table);
    case INSIGHTS_KEYS.SQLA00002:
    case INSIGHTS_KEYS.SQLE00002:
      return RULE_SQL_02(facts);
    case INSIGHTS_KEYS.SQLE00003:
    case INSIGHTS_KEYS.SQLA00003:
      return RULE_SQL_03(facts);
    case INSIGHTS_KEYS.SQLE00005:
      return RULE_SQL_E_05(facts, changeTabActivity);
    case INSIGHTS_KEYS.SQL00006:
      return RULE_SQL_06(facts);
    case INSIGHTS_KEYS.SQLE00007:
    case INSIGHTS_KEYS.SQLA00007:
      return RULE_SQL_07(facts);
    case INSIGHTS_KEYS.SQLA00008:
    case INSIGHTS_KEYS.SQLE00008:
      return RULE_SQL_08(facts);
    case INSIGHTS_KEYS.SQLA00009:
      return RULE_SQL_A_09(facts);
    case INSIGHTS_KEYS.SQLA00011:
      return RULE_SQL_11(facts, changeTabActivity);
    case INSIGHTS_KEYS.SQL00020:
      return RULE_SQL_20(facts);
    case INSIGHTS_KEYS.SQL00021:
      return RULE_SQL_21();
    case INSIGHTS_KEYS.SQL00022:
      return RULE_SQL_22();
    case INSIGHTS_KEYS.SQL00023:
      return RULE_SQL_23();
    case INSIGHTS_KEYS.SQL00024:
      return RULE_SQL_24(facts);
    /* SQL Insights Ends */

    default:
      return undefined;
  }
};
