import { atom, selector } from 'recoil';

export interface debugModeStateI {
  debug: boolean;
}

export interface debugModeType {
  debug: boolean;
}

export const debugModeState = atom<any>({
  key: 'debugModeState',
  default: false,

});

export const debugModeStateSelector = selector<debugModeStateI>({
  key: 'debugModeStateSelector',
  get: ({ get }: any) => {
    return get(debugModeState);
  }
});
