import { Tooltip, XAxis, YAxis, Area, ComposedChart, CartesianGrid, ResponsiveContainer, Bar } from 'recharts';
import { useContext, useEffect, useState } from 'react';
import { Grid, Skeleton, Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ReactComponent as NoRecentActivityData } from '@icons/no-data-found-traces.svg';
import { ReactComponent as ExtendLink } from '@icons/extend.svg';
import dayjs from 'dayjs';
import { useRecoilState, useRecoilValue } from 'recoil';
import InfiniteScroll from 'react-infinite-scroller';

import Typography from '../../../core/Typography';
import { GridContainer } from '../../LogAnalysis/LogAnalyzer.styled';
import { analyticsTrack } from '../../../utils/segment-analytics';
import { formatSQL, getFactsAsKeyVal, numberWithCommas, toNumbersSuffix } from '../../../utils/utils';
import MDrawer, { toggleDrawer } from '../../../core/Drawer/Drawer';
import {
  Arrow,
  ArrowWrapper,
  Close,
  Container,
  ContainerItem,
  CopyDrawerContainer,
  DrawerItemWrapper,
  ExtendLinkWrapper,
  GridItem,
  HeaderDrawer,
  TabWrapper,
  TextDrawer,
  TitleDrawer,
  TitleWrapper,
  TooltipContainer,
  WrapperList
} from '../Reports.styled';
import NoData from '../../../components/NoData/NoData';
import TableLoader from '../../../components/Loader/TableLoader';
import { reportsStateAtom, reportsStateI } from '../../../atoms/reports.atom';
import Flex from '../../../core/Flex';
import { ApiKeyType, useActiveAPIKey } from '../../../atoms/api-keys.atom';
import { queryStatisticDataItemT, QueryStats, ReportAtomState } from './types';
import CodeEditor from '../../../core/MonacoEditor/CodeEditor';
import { debugModeState } from '../../../atoms/debug-mode.atom';
import Button from '../../../core/Button/Button';
import { QueryTabState } from '../utils';
import { TabPanel } from '../../../components/AnalyzeQueryView/VsceExtensionsComponents/TabPanel';
import { EmptyReport, getInsightsByIdAPI, getStatisticQueryAPI, getStatisticQueryByIdAPI } from '../utils/ReportAPI';
import { AssertionTab } from '../../../components/AssertionsTab/AssertionTab';
import { RestClientContext } from '../../../auth/RestClientAuthProvider';
import BasicSpinnerLoader from '../../../components/Loader/BasicSpinnerLoader';
import { QUERY_PER_PAGE } from '../consts';
import SeveritiesSummary from '../../../components/SeveritiesSummary/SeveritiesSummary';

export const QueryStatistics = () => {
  const type: ReportAtomState = 'dataQueryStats';
  const restClient = useContext(RestClientContext);
  const { projectApiKey = '', pmcDeviceId = '' } = useParams();
  const [selectedQuery, setSelectedQuery] = useState<QueryStats>();
  const [loading, setLoading] = useState<boolean>(false);
  const [queryStatisticDataItem, setQueryStatisticDataItem] = useState<queryStatisticDataItemT[]>();
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [tabState, setTabState] = useState(0);
  const [isGetOffset, setOffset] = useState(false);
  const [drawerLoadingState, setDrawerLoadingState] = useState<boolean>(false);
  const [{ [type]: reportsState, selectedType, pmcDeviceId: selectedPmcDeviceId }, setReportsState] =
    useRecoilState(reportsStateAtom);
  const isDebugMode = useRecoilValue(debugModeState);
  const [queryTrace, setQueryTrace] = useState<any>();
  const [assertionIndex, setAssertionIndex] = useState(0);
  const [isInsightLoading, setInsightLoading] = useState(false);
  const SingleQueryRedirectUrl = `${window.location.origin}/projects/${projectApiKey}/activities/source/query-analysis/`;
  const selectedApiKey: ApiKeyType = useActiveAPIKey(projectApiKey);

  const handleTabChange = (event: any, newValue: number) => {
    event.preventDefault();
    analyticsTrack('QueryStats Tab ', { tab: QueryTabState[newValue] });
    setTabState(newValue);
  };

  const getQueryStatisticById = async (item: QueryStats) => {
    setDrawerLoadingState(true);
    setDrawerState(true);
    if (item?.query_id && item.query_id !== selectedQuery?.query_id && item.query_id) {
      setTabState(0);
      setSelectedQuery(item);
      const queryById: queryStatisticDataItemT[] = await getStatisticQueryByIdAPI(restClient, projectApiKey, item.query_id);
      setQueryStatisticDataItem(queryById);
    }
    setDrawerLoadingState(false);
  };
  const getInsightsById = async () => {
    setInsightLoading(true);
    setAssertionIndex(0);
    const resQueries = await getInsightsByIdAPI(restClient, projectApiKey, selectedQuery?.trace_id || '');
    if (resQueries.length) {
      setQueryTrace({ ...resQueries[0], trace_id: selectedQuery?.trace_id });
    }
    setInsightLoading(false);
  };
  const getStatisticQuery = async () => {
    setLoading(true);
    setOffset(false);
    const { rows, lastUpdateIsoDate }: { rows: QueryStats[]; lastUpdateIsoDate: string } = await getStatisticQueryAPI(
      restClient,
      projectApiKey,
      pmcDeviceId,
      reportsState.offset
    );

    setReportsState((old: reportsStateI) => ({
      ...old,
      selectedType: type,
      pmcDeviceId,
      [type]: { rows: [...reportsState.rows, ...rows], lastUpdateIsoDate, offset: reportsState.offset + QUERY_PER_PAGE }
    }));

    setLoading(false);
  };
  const CustomTooltip = (data: any) => {
    const { active, payload } = data;
    if (active && payload && payload.length) {
      const data = payload[0]?.payload;
      const { calls_delta: _calls_delta, hourly_avg_duration } = data;
      const calls_delta = Math.abs(_calls_delta);
      const hourly_total_execution = numberWithCommas(hourly_avg_duration * calls_delta, 3);
      return (
        <TooltipContainer>
          <Flex justify="start">
            <TitleDrawer weight="600">Date:</TitleDrawer>
            <TextDrawer>{dayjs(data?.created_at).format('MMM-DD HH:00')}</TextDrawer>
          </Flex>
          <Flex justify="start">
            <TitleDrawer weight="600">Hourly Avg Duration (ms):</TitleDrawer>
            <TextDrawer>{numberWithCommas(hourly_avg_duration, 3)}</TextDrawer>
          </Flex>
          <Flex justify="start">
            <TitleDrawer weight="600">Hourly Total Execution (ms):</TitleDrawer>
            <TextDrawer>{hourly_total_execution}</TextDrawer>
          </Flex>
          <Flex justify="start">
            <TitleDrawer weight="600">Hourly Calls:</TitleDrawer>
            <TextDrawer>{numberWithCommas(Math.abs(data?.calls_delta))}</TextDrawer>
          </Flex>
        </TooltipContainer>
      );
    }
    return null;
  };

  useEffect(() => {
    if (selectedType !== type || (selectedPmcDeviceId && selectedPmcDeviceId !== pmcDeviceId)) {
      setReportsState((old) => ({
        ...old,
        selectedType: type,
        pmcDeviceId,
        [type]: selectedPmcDeviceId !== pmcDeviceId ? EmptyReport : reportsState
      }));
    }
    if (projectApiKey && pmcDeviceId && reportsState.rows.length === reportsState.offset && !loading) {
      getStatisticQuery();
    }
  }, [projectApiKey, pmcDeviceId, reportsState.offset, reportsState.rows.length, isGetOffset]);

  useEffect(() => {
    if (selectedQuery?.trace_id && queryTrace?.trace_id !== selectedQuery?.trace_id) {
      getInsightsById();
    } else if (selectedQuery?.trace_id === null) {
      setQueryTrace(undefined);
    }
  }, [selectedQuery?.query_id, pmcDeviceId]);

  const [debugDrawerState, setDebugDrawerState] = useState(false);

  return (
    <>
      <Container>
        {loading && !reportsState.rows.length ? (
          <TableLoader size={10} />
        ) : (
          <>
            <Container direction="column">
              <GridContainer container>
                <Grid
                  item
                  xs={7}
                  sx={{
                    padding: '2px 8px'
                  }}
                >
                  <GridItem justify="start">
                    <TitleWrapper weight="600">SQL Command</TitleWrapper>
                  </GridItem>
                </Grid>
                <Grid item xs={1}>
                  <GridItem>
                    <TitleWrapper weight="600">Calls</TitleWrapper>
                  </GridItem>
                </Grid>
                <Grid item xs={1.5}>
                  <GridItem justify="start">
                    <TitleWrapper weight="600">Avg Duration (ms)</TitleWrapper>
                  </GridItem>
                </Grid>
                <Grid item xs={2}>
                  <GridItem justify="start">
                    <TitleWrapper weight="600">Insights</TitleWrapper>
                  </GridItem>
                </Grid>
              </GridContainer>
              <WrapperList>
                {reportsState.rows.length ? (
                  <InfiniteScroll
                    useWindow={false}
                    initialLoad={false}
                    loadMore={() => {
                      setOffset(true);
                    }}
                    hasMore={reportsState.rows.length % QUERY_PER_PAGE === 0 && !loading}
                  >
                    {reportsState.rows.map((item: QueryStats, i: number) => (
                      <ContainerItem
                        isActive={item?.query_id === selectedQuery?.query_id && !selectedApiKey?.isDemo}
                        key={i}
                        onClick={() => getQueryStatisticById(item)}
                      >
                        <Grid
                          container
                          sx={{
                            padding: '12px 22px'
                          }}
                        >
                          <Grid item xs={7}>
                            <GridItem justify="start">
                              <Typography>
                                {item?.query.length > 300 ? item?.query.slice(0, 300) + '...' : item?.query}
                              </Typography>
                            </GridItem>
                          </Grid>
                          <Grid item xs={1}>
                            <GridItem>
                              <Typography>
                                {toNumbersSuffix(item?.calls).num + '' + toNumbersSuffix(item?.calls).suffix}
                              </Typography>
                            </GridItem>
                          </Grid>
                          <Grid item xs={1.5}>
                            <GridItem justify="start">
                              <Typography> {numberWithCommas(+item?.mean_exec_time)}</Typography>
                              <ArrowWrapper className="arrow-icon-item">
                                <Arrow title="arrow-next" />
                              </ArrowWrapper>
                            </GridItem>
                          </Grid>
                          <Grid item xs={2}>
                            <GridItem justify="start">
                              <SeveritiesSummary
                                severity_critical={item?.severities?.severity_critical}
                                severity_high={item?.severities?.severity_high}
                                severity_medium={item?.severities?.severity_medium}
                                severity_low={item?.severities?.severity_low}
                                showAll={false}
                              />
                            </GridItem>
                          </Grid>
                        </Grid>
                      </ContainerItem>
                    ))}
                  </InfiniteScroll>
                ) : (
                  <NoData
                    title={() => (
                      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                        No data was found.
                        <br />
                      </div>
                    )}
                    subTitle=""
                    icon={<NoRecentActivityData />}
                  />
                )}
                {loading && (
                  <Flex>
                    <BasicSpinnerLoader />
                  </Flex>
                )}
              </WrapperList>
            </Container>
            <MDrawer onClose={() => toggleDrawer(() => setDebugDrawerState(false))} anchor="right" open={debugDrawerState}>
              <Flex margin="16px 0 32px">
                <CodeEditor
                  isCopyValue
                  language={'sql'}
                  readonly={true}
                  width="900px"
                  value={formatSQL(JSON.stringify(queryStatisticDataItem))}
                />
              </Flex>
            </MDrawer>
            <MDrawer onClose={() => toggleDrawer(() => setDrawerState(false))} anchor="right" open={drawerState}>
              <DrawerItemWrapper justify="start" align="start" direction="column">
                <HeaderDrawer justify="space-between">
                  <Flex margin="16px">
                    <TitleDrawer h3 weight="600">
                      SQL Command Details
                    </TitleDrawer>
                  </Flex>
                  <Flex margin="16px">
                    {isDebugMode && (
                      <Button variant="link" onClick={() => setDebugDrawerState(true)}>
                        <Typography h4 weight={600}>
                          Raw Info
                        </Typography>
                      </Button>
                    )}
                    <Close title="close-icon" onClick={() => setDrawerState(false)} />
                  </Flex>
                </HeaderDrawer>
                <CopyDrawerContainer justify="start" align="start" direction="column">
                  {selectedQuery?.trace_id && (
                    <ExtendLinkWrapper target="_blank" href={SingleQueryRedirectUrl + selectedQuery?.trace_id}>
                      <ExtendLink width={20} height={20} />
                    </ExtendLinkWrapper>
                  )}

                  <Tabs sx={{ minHeight: '30px', mt: -1, ml: 1 }} value={tabState} onChange={handleTabChange}>
                    {QueryTabState.map((tabItem: string) => (
                      <Tab
                        key={tabItem}
                        sx={{ textTransform: 'none', fontWeight: 600 }}
                        id={`query-tab-${tabItem}`}
                        label={tabItem}
                      />
                    ))}
                  </Tabs>
                  <TabPanel value={tabState} index={0}>
                    <Flex margin="6px">
                      {drawerLoadingState ? (
                        <Skeleton variant="rectangular" animation="wave" width="900px" height="30vh" />
                      ) : (
                        <CodeEditor
                          isCopyValue
                          language="sql"
                          readonly={true}
                          height="28vh"
                          width="900px"
                          value={formatSQL(selectedQuery?.query || '')}
                        />
                      )}
                    </Flex>

                    <TitleDrawer pd="6px" weight="600">
                      Average Duration (ms)
                    </TitleDrawer>
                    <ResponsiveContainer width="100%" height={200}>
                      {drawerLoadingState ? (
                        <Skeleton variant="rectangular" animation="wave" width="100%" height={80} />
                      ) : (
                        <ComposedChart data={queryStatisticDataItem}>
                          <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="rgba(96, 142, 220, 0.5)" stopOpacity={0.5} />
                              <stop offset="95%" stopColor="rgba(255, 255, 255, 0.5)" stopOpacity={0} />
                            </linearGradient>
                          </defs>
                          <CartesianGrid stroke="#f5f5f5" />
                          <XAxis
                            dataKey="created_at"
                            tickMargin={10}
                            minTickGap={20}
                            tickFormatter={(x) => dayjs(x).format('MMM-DD HH:00')}
                            interval="preserveEnd"
                            tick={{ fill: '#B0B0CC' }}
                            scale="band"
                          />
                          <Tooltip content={CustomTooltip} wrapperStyle={{ border: 'none' }} />
                          <Area
                            type="monotone"
                            dataKey="hourly_avg_duration"
                            stroke="#0097FB"
                            fillOpacity={1}
                            fill="url(#colorUv)"
                          />
                          <YAxis />
                        </ComposedChart>
                      )}
                    </ResponsiveContainer>
                    <TitleDrawer pd="6px" weight="600">
                      Hourly Calls
                    </TitleDrawer>
                    <ResponsiveContainer width="100%" height={200}>
                      {drawerLoadingState ? (
                        <Skeleton variant="rectangular" animation="wave" width="100%" height={80} />
                      ) : (
                        <ComposedChart data={queryStatisticDataItem}>
                          <CartesianGrid stroke="#f5f5f5" />
                          <XAxis
                            dataKey="created_at"
                            tickMargin={10}
                            minTickGap={20}
                            tickFormatter={(x) => dayjs(x).format('MMM-DD HH:00')}
                            interval="preserveEnd"
                            tick={{ fill: '#B0B0CC' }}
                            scale="band"
                          />
                          <Tooltip content={CustomTooltip} wrapperStyle={{ border: 'none' }} />
                          <Bar dataKey="calls_deltaRed" stroke="none" fill="#608EDC" maxBarSize={10} />
                          <YAxis />
                        </ComposedChart>
                      )}
                    </ResponsiveContainer>
                  </TabPanel>
                  <TabPanel value={tabState} index={1}>
                    <TabWrapper>
                      {queryTrace ? (
                        <AssertionTab
                          assertions={queryTrace?.assertions?.success || []}
                          headHeight="120px"
                          facts={getFactsAsKeyVal(queryTrace?.facts || {})}
                          assertionCurrentIndex={assertionIndex}
                          setAssertionIndexCallback={setAssertionIndex}
                          tableInfos={queryTrace?.tableInfos || []}
                          changeTabActivity={0}
                          isLoading={isInsightLoading}
                          selectedQuery={0}
                          tableItem={queryTrace}
                          isSidebar={true}
                        />
                      ) : (
                        <NoData
                          title={() => (
                            <Typography h3 weight="600">
                              Insights unavailable...
                            </Typography>
                          )}
                          subTitle={`The system could not find an execution plan for the query ID ${selectedQuery?.query_id}`}
                          icon={<NoRecentActivityData />}
                        />
                      )}
                    </TabWrapper>
                  </TabPanel>
                </CopyDrawerContainer>
              </DrawerItemWrapper>
            </MDrawer>
          </>
        )}
      </Container>
    </>
  );
};
