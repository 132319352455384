import { Outlet, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth, AdminPortal } from '@frontegg/react';

import Flex from '../../core/Flex';
import Breadcrumbs from '../../core/Breadcrumbs/Breadcrumbs';
import { sideMenuList } from '../../utils/constants';
import { Container, Divider, Icon, ListItem, ListWrapper, Wrapper, WrapperOutlet } from './Settings.styled';
import Typography from '../../core/Typography';

const SettingsRoute = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [active, setActive] = useState(0);
  useEffect(() => {
    const currentPath = sideMenuList.findIndex(
      (route: any) => location.pathname.includes(route.path) && route.path !== '/settings'
    );
    if (location.pathname.includes('/user-management')) {
      setActive(0);
    }

    console.log(currentPath);
    if (currentPath > 0) {
      setActive(currentPath);
    }
  }, [location.pathname]);
  const handelSelect = useCallback(
    (id: number, path: string | any, params?: string | any) => () => {
      if (path === 'user-management') {
        AdminPortal.show();
      } else {
        setActive(id);
        navigate(`${params ? path + '/' + params : path || '/'}`);
      }
    },
    [active]
  );
  const filteredSideMenuList = useMemo(() => sideMenuList.filter((m: any) => m.isActive?.(user) ?? true), [sideMenuList, user]);
  return (
    <>
      <Breadcrumbs
        projectName={'Projects'}
        breadcrumbsList={[{ name: filteredSideMenuList[active]?.name || 'General' }]}
        isGlobalPage
      />
      <Flex justify="start">
        <Wrapper>
          <Container>
            <ListWrapper direction="column" justify="start">
              {filteredSideMenuList.map((li, i) =>
                li.icon ? (
                  <ListItem
                    key={li.name}
                    active={active === i}
                    data-cy={`sideBar${li.name}`}
                    onClick={handelSelect(i, li.path, undefined)}
                  >
                    <Icon>{li.icon({ title: li.name, width: 20, height: 20 })}</Icon>
                    <Flex>
                      <Typography weight={500}>{li.name}</Typography>
                    </Flex>
                  </ListItem>
                ) : (
                  <Divider key={li.name} />
                )
              )}
            </ListWrapper>
          </Container>
        </Wrapper>
        <WrapperOutlet justify="start" align="start">
          <Outlet />
        </WrapperOutlet>
      </Flex>
    </>
  );
};

export default SettingsRoute;
