import { KyInstance } from 'ky/distribution/types/ky';

export const getTableAnalysisAPI = async (
  restClient: KyInstance,
  tableID: string,
  pmcDeviceID: string,
  projectApiKey: string
): Promise<any[]> => {
  try {
    const resTableList: any = await restClient
      .get(`db-details/ssa/${pmcDeviceID}/${tableID}`, {
        headers: {
          'x-api-key': projectApiKey
        }
      })
      .json();
    if (resTableList) {
      return resTableList;
    } else return [];
  } catch (error: any) {
    return [];
  }
};

export const getTableByPmcDeviceIdAndParamsAPI = async (
  restClient: KyInstance,
  tableName: string,
  schemaName: string,
  pmcDeviceID: string,
  projectApiKey: string
): Promise<any[]> => {
  try {
    const resTableList: any[] = await restClient
      .get(`db-details/ssa/table-details/${pmcDeviceID}/${schemaName}/${tableName}`, {
        headers: {
          'x-api-key': projectApiKey
        }
      })
      .json();
    if (resTableList) {
      return resTableList;
    } else return [];
  } catch (error: any) {
    return [];
  }
};
