import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { Select as SelectMui } from '@mui/material';
import { styled } from '@mui/system';
import { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import { ReactComponent as ArrowIcon } from '@icons/arrow-down.svg';

export type SelectListItem = {
  id: number | string;
  name: string | any;
  val: string;
};
interface SelectProps {
  name: string;
  list: SelectListItem[];
  defaultValue?: string;
  value: string;
  onSelect: (value: any) => void;
  minWidth?: string;
  changeValueOnPress?: boolean;
  disabled?: boolean;
}
export const SelectInput = styled(SelectMui)`
  .MuiSelect-select {
    padding: 4px 8px;
  }
`;
export const Label = styled(InputLabel)`
  top: -4px;
`;

const Select: FunctionComponent<SelectProps> = ({
  name,
  onSelect,
  list,
  minWidth = 150,
  value = '',
  defaultValue = '',
  disabled = false
}) => {
  const theme: any = useTheme();
  return (
    <FormControl sx={{ minWidth }} size="small" disabled={disabled}>
      <Label id="demo-select-small">{name}</Label>
      <SelectInput
        IconComponent={(props: any) => (
          <ArrowIcon className={`material-icons ${props.className}`} title="select" fill={theme.colors.info.main} />
        )}
        data-cy="select-example"
        labelId="demo-select-small"
        id="demo-select-small"
        value={value !== '' ? value : defaultValue}
        label={name}
        onChange={(e: any) => onSelect(e.target.value)}
      >
        {list.map((item) => (
          <MenuItem data-cy={`select-item-${item.id}`} key={item.id} value={item.val}>
            {item.name}
          </MenuItem>
        ))}
      </SelectInput>
    </FormControl>
  );
};

export default Select;
