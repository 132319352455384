import { useEffect, useState } from 'react';
import { ReactComponent as SeverityLow } from '@icons/severity-low-square-icon.svg';
import { ReactComponent as SeverityMedium } from '@icons/severity-medium-square-icon.svg';
import { ReactComponent as SeverityHigh } from '@icons/severity-high-square-icon.svg';
import { ReactComponent as SeverityCritical } from '@icons/severity-critical-square-icon.svg';
import { ReactComponent as SeverityInfo } from '@icons/done.svg';
import { ReactComponent as LowSeverity } from '@icons/severity-low.svg';
import { ReactComponent as MediumSeverity } from '@icons/severity-medium.svg';
import { ReactComponent as HighSeverity } from '@icons/severity-high.svg';
import { ReactComponent as CriticalSeverity } from '@icons/severity-critical.svg';

import { INSIGHT_STATUS, INSIGHT_STATUS_ENUM } from '../../utils/constants';
interface Props {
  priority: number;
}

export const getSeverityComponent = (priority: number, isNewIcon = false) => {
  switch (priority) {
    case 5:
      return <SeverityInfo style={{ paddingRight: 5 }} height={20} width={20} />;
    case 4:
      return isNewIcon ? (
        <LowSeverity style={{ paddingRight: 5 }} height={20} width={20} />
      ) : (
        <SeverityLow style={{ paddingRight: 5 }} height={20} width={20} />
      );
    case 3:
      return isNewIcon ? (
        <MediumSeverity style={{ paddingRight: 5 }} height={20} width={20} />
      ) : (
        <SeverityMedium style={{ paddingRight: 5 }} height={20} width={20} />
      );
    case 2:
      return isNewIcon ? (
        <HighSeverity style={{ paddingRight: 5 }} height={20} width={20} />
      ) : (
        <SeverityHigh style={{ paddingRight: 5 }} height={20} width={20} />
      );
    case 1:
      return isNewIcon ? (
        <CriticalSeverity style={{ paddingRight: 5 }} height={20} width={20} />
      ) : (
        <SeverityCritical style={{ paddingRight: 5 }} height={20} width={20} />
      );
    default:
      return <></>;
  }
};
export const SeverityLabel = ({ priority }: Props) => {
  const [priorityLabel, setPriorityLabel] = useState('Low');
  const getPriorityLabel = (priority: number | string) => {
    switch (priority) {
      case 6:
        setPriorityLabel('Skipped');
        break;
      case 5:
        setPriorityLabel('Healthy');
        break;
      case 4:
        setPriorityLabel('Low');
        break;
      case 3:
        setPriorityLabel('Medium');
        break;
      case 2:
        setPriorityLabel('High');
        break;
      case 1:
        setPriorityLabel('Critical');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getPriorityLabel(priority);
  }, [priority]);
  if (INSIGHT_STATUS[priority] === INSIGHT_STATUS_ENUM.SKIPPED) return <></>;
  return getSeverityComponent(priority, true);
};
