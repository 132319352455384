import CopyToClipBoard from '../../../components/Buttons/CopyToClipBoard';
import TableItem from '../../../components/Table/TableItem';
import Flex from '../../../core/Flex';
import { numberWithCommas } from '../../utils';
import { v4 as uuidv4 } from 'uuid';
import { byteMe } from '../../byteMe';
import Typography from '../../../core/Typography';
import { LogContainer } from '../automated-investigation.styled';
import styled from 'styled-components';
import SafeInnerHtml from '../../SafeInnerHtml';
import { wrapNumbersInBold } from '../utils';
import { ReactComponent as AlertIcon } from '@icons/warning.svg';
export const ConsistentDiv = styled(Typography)`
  margin: 14px 0;
`;

const ID: string = uuidv4();

export const RULE_SQL_01 = (facts: any, assertion: any, tableItem: any, isNeedNewIndex: boolean, table: any) => {
  let rowsRead: any;
  const isActual = facts['plan-type'] === 'actual';

  if (isActual) rowsRead = facts['actual-rows-read'];

  return {
    investigation: (
      <ConsistentDiv>
        {/* {isEstimated ? ( */}
       <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{marginRight: 3}}>
        <SafeInnerHtml unsafeInnerHtmlText={wrapNumbersInBold(assertion?.ruleResult)} />
        </div>
        
         <div style={{marginRight: 3}}>{isActual && (<div><b style={{marginRight: 3}}>{numberWithCommas(facts['actual-rows-removed-by-filter'])}</b>rows were filtered out</div>)} </div>
         {isActual && !!facts['actual-rows-read'] && facts['actual-rows-read'] > 0 && (<b>({Math.floor((facts['actual-rows-removed-by-filter'] / facts['actual-rows-read']) * 100)}%)</b>
        )}
       </div>
        <Flex align={'start'}>
          <TableItem headHeight={'320px'} data={tableItem} />
          <Flex style={{ marginLeft: 5 }} justify={'flex-start'} align={'start'} direction={'column'}></Flex>
        </Flex>
      </ConsistentDiv>
    ),
    impact: (
      <ConsistentDiv>
        Reading a large number of rows requires many IO operations. That might resolve into:
        <ul style={{margin: 0}}>
          <li>Large row queries strain storage, causing slow response times and Disk I/O overload.</li>
          <li>Multi-user environments suffer from growing query sizes, impacting database performance.</li>
          <li>Extensive result sets consume memory, possibly resorting to disk-based temporary storage.</li>
          <li>Large queries may cause contention issues with shared locks and blocking.</li>
          <li>Remote database access encounters substantial network traffic, leading to delays.</li>
          <li>Resource-intensive queries impede database scalability.</li>
          <li>Extensive result sets hamper application performance, requiring query optimization.</li>
        </ul>
      </ConsistentDiv>
    ),
    remediation: isNeedNewIndex ? (
      <Flex align={'start'} justify={'flex-start'} direction={'column'}>
        {table
          ?.filter((i: any) => i?.['remedation'] && i?.['Filter'] && i?.['Filter'] !== 'N/A')
          ?.map((item: any, idx: number) => {
            const remidiationQuery = `CREATE INDEX IDX_${item?.['Table Name']?.replaceAll('.', '_') || ''}_${ID
              .substring(0, 12)
              ?.replaceAll('-', '')} ON ${item?.['Table Name']} (${item?.['remedation']?.index?.join(', ')});`;
            return (
              <Flex key={idx} align={'start'} justify={'flex-start'} direction={'column'}>
                <ul>
                  <li>
                    {`Add an Index to the table ${item?.['Table Name']}.`}
                    <br />
                    <Flex direction="row">
                      <code style={{ paddingBottom: 2 }}>{remidiationQuery}</code>
                      <div style={{ marginTop: 3 }}>
                        <CopyToClipBoard data={remidiationQuery}></CopyToClipBoard>
                      </div>
                    </Flex>
                  </li>
                  {
                    isActual && !!facts['actual-rows-read'] && facts['actual-rows-read'] > 50_000 &&
                    <li>Consider limiting the number of returned rows. For example:
                       <code style={{paddingLeft: 5}}>SELECT … LIMIT 10000;</code> 
                    </li>
                  }
                </ul>
              </Flex>
            );
          })}
      </Flex>
    ) : (
      <ol>
         <li>Consider limiting the number of returned rows. For example:
                       <code style={{paddingLeft: 5}}>SELECT … LIMIT 10000;</code> 
         </li>
        <li>
          Consider partitioning the table to distribute the data across multiple disks. This can help to reduce the amount of I/O
          required to read the data, which can improve performance.
        </li>
      </ol>
    )
  };
};

export const RULE_SQL_02 = (facts: any) => {
  const planType = facts['plan-type'];
  const rowsRead = planType === 'actual' ? numberWithCommas(facts['actual-rows-read']) : 'unknown';
  const rowsReturned =
    planType === 'actual' ? numberWithCommas(facts['actual-rows-returned']) : numberWithCommas(facts['estimated-rows-returned']);
  const rowSizeInBytes = facts['row-size-in-bytes'];
  const resultSetSize = facts['results-set-size'];
  return {
    investigation:
      planType === 'estimated' ? (
        <>
          The SQL command returned (estimated) <b>{rowsReturned}</b> rows.
        </>
      ) : (
        <>
          The SQL command returned <b>{rowsReturned}</b> rows.
        </>
      ),
    impact: <ConsistentDiv>Excessive network usage.</ConsistentDiv>,
    remediation: (
      <ul>
        <li>
          Limit the number of rows returned rows using <code>LIMIT</code>.
        </li>
        <li>
          Return only a subset (page) of the data. The client can call the next page using <code>OFFSET</code>.
        </li>
      </ul>
    )
  };
};

export const RULE_SQL_03 = (facts: any) => {
  return {
    investigation: (
      <ol>
        <li>
          Number of tables: <b>{facts['num-of-tables']}</b>
        </li>
        <li>
          The table{facts?.['num-of-tables'] > 1 ? 's' : ''}: {facts['table-names-list']}
        </li>
      </ol>
    ),
    impact: <ConsistentDiv>Joining too many table might resolve in excessive IO usage.</ConsistentDiv>,
    remediation: (
      <ConsistentDiv>
        Review all the tables used by the SQL Command. Consider returning main data, from less tables, in one call, and more data
        only if needed.
      </ConsistentDiv>
    )
  };
};

export const RULE_SQL_E_05 = (facts: any, changeTabActivity: any) => {
  const OperationCostTable = [
    {
      Operation: 'Read',
      Cost: numberWithCommas(facts['total-physical-operations-cost'])
    },
    {
      Operation: 'Join',
      Cost: numberWithCommas(facts['total-join-operation-costs'])
    },
    {
      Operation: 'Sort',
      Cost: facts['total-sort-operations-cost'] || 0
    }
  ];
  return {
    investigation: (
      <ConsistentDiv>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{ marginRight: 5 }}>
            In PostgreSQL, &quot;cost&quot; refers to the estimated execution cost of a query plan. It is used by the query
            optimizer to determine the most efficient execution plan for a query
            <b style={{marginLeft: 3}}>{numberWithCommas(facts['plan-type'] === 'actual' ? facts['total-cost'] : facts['estimated-cost'])}</b>.
          </span>
        </div>
        <div style={{ width: '100%', paddingBottom: 5 }}>
          <TableItem headHeight={'320px'} data={OperationCostTable || []} />
        </div>
      </ConsistentDiv>
    ),
    impact: (
      <ConsistentDiv>
        High cost reading the rows usually result in excessive IO and Memory utilization. Joining many rows might result in high
        CPU. High cost of sorting also might result into high CPU and memory usage.
      </ConsistentDiv>
    ),
    remediation: (
      <ol>
        <li>
          <Flex>
            Use the tab
            <LogContainer onClick={() => changeTabActivity(3)}>Query Tale</LogContainer>
            to review each step in the execution plan and its cost
          </Flex>
        </li>
      </ol>
    )
  };
};

export const RULE_SQL_06 = (facts: any) => {
  return {
    investigation: (
      <>
        <div>
          {' '}
          The output of the SQL Commands returns <b>{facts['num-of-columns']}</b> columns.{' '}
        </div>
        <div>
          The output column(s): <b>{facts['output-columns']}</b>
        </div>
      </>
    ),
    impact: <ConsistentDiv>
      <ul>
        <li>Security / compliance: always avoid returning unnecessary data.</li>
        <li>Network Latency: slow response time due to transferring large amount of data to clients. The latency affects the UX and system health.</li>
        <li>DB latency: Indexes inefficiency, it would be impossible to plan covering indexes.</li>
      </ul>
      </ConsistentDiv>,
    remediation: (
      <ConsistentDiv>
       It is recommended to carefully review the necessity of columns in the result set and only return the ones really needed by the app.
      </ConsistentDiv>
    )
  };
};

export const RULE_SQL_07 = (facts: any) => {
  return {
    investigation: (
      <ConsistentDiv>
        <Typography>
          The SQL command sorted: <b>{numberWithCommas(facts['total-num-of-rows-sorted'])}</b> rows.
        </Typography>
        {facts['sorted_keys'] ? (
          <div>
            Sort keys: <b>{facts['sorted_keys']}</b>
          </div>
        ) : undefined}
      </ConsistentDiv>
    ),
    impact: (
      <ConsistentDiv>
        Sorting a large number of rows can slow down the query, requiring CPU and memory resources. It may lead to memory spills
        if the server needs to sort a very large dataset.
      </ConsistentDiv>
    ),
    remediation: (
      <ConsistentDiv>
        <ul>
          <li>Limit the result set by applying filters or using pagination.</li>
          <li>Optimize indexing for the sorting columns.</li>

          <li>Consider caching pre-sorted results for static data.</li>
        </ul>
      </ConsistentDiv>
    )
  };
};

export const RULE_SQL_08 = (facts: any) => {
  const sizeInBytes: number = facts['row-size-in-bytes'];
  const planMode: string = facts['accuration_level'];
  const isEstimated: boolean = planMode === 'estimated';
  const rowsReturned =
    facts['actual-rows-returned'] && typeof facts['actual-rows-returned'] === 'number'
      ? facts['actual-rows-returned']
      : facts['estimated-rows-returned'];
  return {
    investigation: (
      <ConsistentDiv>
        <div>
          Avg row size: <b>{byteMe(sizeInBytes)}</b>.
        </div>
        <div>
          Rows returned: <b>{rowsReturned}</b>.
        </div>
        <div>
          Total size of the results set: <b>{byteMe(facts['results-set-size'] || 1)}</b>.
        </div>
        <div style={{ display: 'flex', flexDirection: 'rows' }}>
          <div style={{ padding: '5px 5px 0px 0px' }}>
            <AlertIcon height={20} width={20} />
          </div>

          <div>
            Execution plans may provide inaccurate estimations of size, especially for JSON and long strings. Real-world testing
            is recommended for accurate sizing in these cases.
          </div>
        </div>
      </ConsistentDiv>
    ),
    impact: (
      <>
        <ul>
          <li>Excessive network usage which might resolve into slow queries.</li>
          <li>Sometimes the App Server processes every row sent from the DB, which might slow the system dramatically.</li>
        </ul>
      </>
    ),
    remediation: (
      <ConsistentDiv>
        It is recommended to use the <code>pg_column_size</code> function to accurately view the actual size of a column,
        especially for cases involving JSON or long strings.
      </ConsistentDiv>
    )
  };
};

export const RULE_SQL_A_09 = (facts: any) => {
  return {
    investigation: (
      <ConsistentDiv>
        {facts['sort-space-type'] === 'Disk' ? (
          <>
            The query uses the hard drive to sort the data.
            <ul>
              <li>Sort Key: {facts['sorted_keys'] ? facts['sorted_keys'] : 'N/A'}</li>
              <li>
                Temp IO Blocks Read: <b>{facts['sort-read-buffers']}</b>
              </li>
              <li>
                Temp IO Blocks Written: <b>{facts['sort-written-buffers']}</b>
              </li>
            </ul>
          </>
        ) : (
          'No sorting i/o operations were performed.'
        )}
      </ConsistentDiv>
    ),
    impact: <>Performance - sorting the rows on the hard drive (disk) is much slower than sorting in memory. </>,
    remediation: (
      <>
        <ul>
          <li>
            The PostgreSQL configuration property used to control how much memory can be used for sorting is work_mem. The default
            value of <b>work_mem</b> is 2MB (250 IO Blocks of 8KB).
          </li>
          <li>
            Configure a larger <b>work_mem</b> using the SET command. For ex. <code>SET work_mem = 1GB;</code> Restart the server.
          </li>
          <li>
            The best way to determine the optimal value for work_mem is to experiment with different values and measure the
            performance of your queries. Setting a value too high will reserve too much unnecessary memory for sorting, for every
            new connection.{' '}
          </li>
        </ul>
      </>
    )
  };
};

export const RULE_SQL_11 = (facts: any, changeTabActivity: any) => {
  return {
    investigation: (
      <ConsistentDiv>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{ marginRight: 5 }}>
            In PostgreSQL, &quot;cost&quot; is a unit of measurement used to estimate the relative performance of different query
            execution plans. The optimizer uses this information to determine which plan is the most efficient. The cost of the
            SQL command is{' '}
            <b>{numberWithCommas(facts['plan-type'] === 'actual' ? facts['total-cost'] : facts['estimated-cost'])}</b>.
          </span>
        </div>
      </ConsistentDiv>
    ),
    impact: (
      <ConsistentDiv>
        High cost reading the rows usually result in excessive IO and Memory utilization. Joining many rows might result in high
        CPU. High cost of sorting also might result into high CPU and memory usage.
      </ConsistentDiv>
    ),
    remediation: (
      <ol>
        <li>
          <Flex>
            Use the tab
            <LogContainer onClick={() => changeTabActivity(3)}>Query Tale</LogContainer>
            to review each step in the execution plan and its cost
          </Flex>
        </li>
      </ol>
    )
  };
};

export const RULE_SQL_20 = (facts: any) => {
  return {
    investigation: (
      <ConsistentDiv>
        {facts['index-on-functions-array'] && facts['index-on-functions-array'].length > 0 ? (
          <>
            <div>
              The SQL command uses a WHERE clause. The following expressions use a{' '}
              {facts['index-on-functions-array'].length > 1 ? 'functions' : 'function'}
              <br /> to evaluate the {facts['index-on-functions-array'].length > 1 ? 'expressions' : 'expression'}:{' '}
              {facts['index-on-functions-array'].map((functionDetails: any) => (
                <em>{functionDetails.functionDeclaration}</em>
              ))}
            </div>
            <>
              <ul>
                {facts['index-on-functions-array'].map((functionDetails: any) => (
                  <li>
                    expression: {functionDetails.functionDeclaration}
                    <br />
                    index: {functionDetails?.index?.name ? functionDetails?.index?.name : 'Index Missing'}
                  </li>
                ))}
              </ul>
            </>
          </>
        ) : (
          'There are no functions on the WHERE clause.'
        )}
      </ConsistentDiv>
    ),
    impact: (
      <div>
        Performance - The Query Optimizer uses indexes to read only the rows relevant to the query. When the WHERE clause uses a
        function, in most cases the the Optimizer can not use the index which slows down the query and might generate intensive IO
        operations.{' '}
      </div>
    ),
    remediation: (
      <>
        <ol>
          <li>
            Check the execution plan. Sometimes other indexes are used to filter the data efficiently, which make the impact
            minimal.
          </li>
          <li>
            If the entire table is scanned because the none of the indexes can be used, consider adding a new index on the
            expression.{' '}
          </li>
        </ol>
      </>
    )
  };
};

export const RULE_SQL_21 = () => {
  return {
    investigation: (
      <ConsistentDiv>
        The SQL command uses <b>SELECT *</b> instead of specifying the actual columns.
      </ConsistentDiv>
    ),
    impact: (
      <ul>
        <li>
          Increased data transfer: <code>SELECT *</code> retrieves all columns from a table, including potentially unnecessary or
          large columns. This leads to more data being transferred over the network, which can negatively impact performance,
          especially when dealing with large datasets.
        </li>
        <li>
          Unintended column additions: If new columns are added to the table in the future, queries using <code>SELECT *</code>{' '}
          will automatically include those new columns. This can lead to unintended consequences and break compatibility with
          existing code.
        </li>
        <li>
          Poor query performance: When unnecessary columns are included in the result set, it can lead to increased I/O and memory
          usage.
        </li>
        <li>
          Code maintenance and readability: By explicitly listing the required columns in the <code>SELECT</code> statement, the
          query becomes more self-documenting and easier to understand.
        </li>
        <li>
          Covering Indexes: A covering index is an index that includes all the columns required for a query, eliminating the need
          for the database to access the underlying table. Selecting only the necessary columns helps suggest covering indexes.
        </li>
      </ul>
    ),
    remediation: <ConsistentDiv>Replace <code>SELECT *</code> with the actual columns: <code>SELECT column1, column2…</code></ConsistentDiv>
  };
};

export const RULE_SQL_22 = () => {
  return {
    investigation: (
      <ConsistentDiv>
        The SQL Command should have a WHERE clause to filter which rows to update and which shouldn't be updated.
      </ConsistentDiv>
    ),
    impact: (
      <ConsistentDiv>
        Without a WHERE clause, all rows in the table would be deleted, which could result in data loss and unexpected
        consequences.
      </ConsistentDiv>
    ),
    remediation: (
      <ConsistentDiv>
        Add a <code>WHERE</code> clause to the SQL command
      </ConsistentDiv>
    )
  };
};

export const RULE_SQL_23 = () => {
  return {
    investigation: (
      <ConsistentDiv>
        The SQL Command should have a WHERE clause to filter which rows to delete and which shouldn't be deleted.{' '}
      </ConsistentDiv>
    ),
    impact: (
      <ConsistentDiv>
        Without a WHERE clause, all rows in the table would be deleted, which could result in data loss and unexpected
        consequences.
      </ConsistentDiv>
    ),
    remediation: (
      <ConsistentDiv>
        Add a <code>WHERE</code> clause to the SQL command
      </ConsistentDiv>
    )
  };
};

export const RULE_SQL_24 = (facts: any) => {
  const isLikeExpressionExist: boolean = facts['like-expression-was-used'].reduce((acc: any, cur: any) => {
    acc = acc || cur?.likeExpressionExist;
    return acc;
  }, false);
  const likeExpressionList: any = (facts['like-expression-was-used'] || []).map((exp: any) => (
    <li>
      <code>{exp?.likeExpression}</code>
    </li>
  ));
  return {
    investigation: (
      <ConsistentDiv>
        {isLikeExpressionExist ? (
          <>
            <div>The WHERE clause contains LIKE. The LIKE expression's</div>
            <ul>{likeExpressionList}</ul>
          </>
        ) : (
          `The WHERE clause doesn't contains any LIKE `
        )}
      </ConsistentDiv>
    ),
    impact: (
      <div>
        <ul>
          <li>High CPU and slow response time - using a wildcard character (%) at the beginning of the search pattern in a <b>LIKE</b> statement (e.g., <b>LIKE '%text'</b>) result in a table scan. This can extremely affect large tables as it requires examining every row, regardless of any indexes that may be present.</li>
          <li> Performance - When you use a wildcard character (%) at the beginning of the search pattern in a <b>LIKE</b> statement (e.g., <b>LIKE</b>
        '%example'), the database engine needs to scan the entire table to find matching rows. This can be extremely slow for
        large tables as it requires examining every row, regardless of any indexes that may be present.</li>
        </ul>
      </div>
    ),
    remediation: (
      <>
        <ol>
          <li>Minimize the number of rows scanned using LIMIT or other conditions (WHERE).</li>
          <li>
            In PostgreSQL, trigrams are three-character sequences that represent substrings of a text. Trigrams are commonly used
            for similarity matching and indexing in text search scenarios.
            <ol>
              <li>
                Enable the extension: <code>CREATE EXTENSION pg_trgm;</code>
              </li>
              <li>
                Create a GIN index on the trigram representation of the column. For ex.{' '}
                <code>CREATE INDEX trigram_gin_idx ON your_table USING gin (text_column gin_trgm_ops);</code>
              </li>
              <li>
                Perform trigram-based searches: <code>SELECT * FROM your_table WHERE text_column % 'search_text';</code>
              </li>
            </ol>
          </li>
        </ol>
      </>
    )
  };
};
