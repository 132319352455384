//Todo: expand function utilities to except

// export const wrapNumbersInBold = (text: string, wrapStart?: string, wrapEnd?: string) => {
//   if(text) {
//     const regex = /(\d+)/g;
//     const result = text.replace(regex, '<b>$1</b>');
//     return `${result}`;
//   }
//   return `Insight description unavailable`
 
// };


export const wrapNumbersInBold = (text: string, wrapStart?: string, wrapEnd?: string) => {
  if(typeof text === 'string') {
  // Regular expression to match numbers
  const regex = /\d+/g;

  // Replace numbers with formatted versions
  const formattedString = text?.replace(regex, (match) => {
    // Format the number with commas
    const numberWithCommas = Number(match).toLocaleString();

    // Wrap the number in <b> tags
    return `<b>${numberWithCommas}</b>`;
  });

  return formattedString;
  }

  return text

}