import { atom } from 'recoil';

export interface UserSettings {
  defaultApiKey?: string;
}

export const userSettingsAtom = atom<UserSettings>({
  key: 'userSettingsAtom',
  default: {}
});
