import styled, { useTheme } from 'styled-components';
import { useState } from 'react';
import { BarChart, Bar, ResponsiveContainer, Cell } from 'recharts';

import {
  ShortDateFormat,
  capitalizeFirstLetter,
  getFormattedDate,
  numberWithCommas,
  toNumbersSuffix
} from '../../../utils/utils';
import Typography from '../../../core/Typography';
import MTooltip from '../../../core/Tooltip/Tooltip';

const CellContainer = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`;

const BarContainer = styled(BarChart)`
  margin-bottom: -4px;
  margin-top: 10px;
`;
const BorderBottom = styled.div<{ maxWidth: number }>`
  height: 2px;
  width: ${({ maxWidth }) => `${maxWidth}px`};
  background: ${({ theme }) => theme.colors.chart.main};
`;

const BarYText = styled(Typography)<{ maxWidth: number }>`
  width: ${({ maxWidth }) => `${maxWidth}px`};
  top: -8px;
  position: absolute;
  text-align: center;
  z-index: 5;
  color: ${({ theme }) => theme.colors.grey[500]};
`;
interface TrendDataGridCellI {
  data: any[];
  payloadName?: string;
  width?: number;
  hideTitle?: boolean;
  dateFormat?: ShortDateFormat;
}

export const TrendDataGridCell = ({
  data,
  payloadName = 'Calls ',
  width = 150,
  hideTitle = false,
  dateFormat = 'l'
}: TrendDataGridCellI) => {
  const [focusBar, setFocusBar] = useState<number | null>(null);
  const [mouseLeave, setMouseLeave] = useState(false);
  const [tooltipState, setTooltipState] = useState<string[]>([]);
  const { colors }: any = useTheme();
  if (!data || data.length === 0) {
    return <></>;
  }

  const showBar = data.reduce((partialSum: any, a: { value: any }) => Math.max(partialSum, a.value), 0);
  return (
    <CellContainer>
      <MTooltip
        title={
          <Typography>
            {tooltipState[0]} <br />
            {tooltipState[1]}
          </Typography>
        }
      >
        {!hideTitle && (
          <BarYText s weight="500" maxWidth={width}>
            Max in 14 days: {toNumbersSuffix(showBar, 0).num + toNumbersSuffix(showBar, 0).suffix} {payloadName}
          </BarYText>
        )}
        <ResponsiveContainer width="100%" height={60}>
          <>
            <BarContainer
              width={width}
              height={60}
              data={data}
              onMouseMove={(state) => {
                if (state?.activePayload?.[0]?.payload?.time) {
                  const stool = [
                    'Date: ' + getFormattedDate(state.activePayload[0]?.payload?.time, dateFormat),
                    capitalizeFirstLetter(payloadName) + ': ' + numberWithCommas(Number(state.activePayload[0]?.payload?.value))
                  ];
                  setTooltipState(stool);
                  setFocusBar(state.activeTooltipIndex ?? null);
                  setMouseLeave(true);
                } else {
                  setFocusBar(null);
                  setMouseLeave(false);
                }
              }}
            >
              <Bar minPointSize={1} dataKey="value" legendType="line" type="monotone">
                {data?.map((_: any, index: number) => (
                  <Cell
                    key={`cell-${index}`}
                    stroke={focusBar === index && mouseLeave ? colors.grey[200] : 'none'}
                    fill={focusBar === index && mouseLeave ? colors?.chart?.hover : colors?.chart?.main}
                  />
                ))}
              </Bar>
            </BarContainer>
            <BorderBottom maxWidth={width} />
          </>
        </ResponsiveContainer>
      </MTooltip>
    </CellContainer>
  );
};
