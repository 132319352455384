

function removeDuplicates(array: string[]) {
  const uniqueArray = [...new Set(array)];
  return uniqueArray;
}

function getIndexName(filter: any, columns: any, functionsInWhere: any[]) {
  const functionArr: any = []
  if (columns === undefined || !(columns?.length > 0) || columns === 'N/A') {
    return undefined;
  }

   functionsInWhere?.map((item: any) => {
    const pattern = new RegExp(`(${item?.functionTokens?.name}\\([^)]*\\))`);
    const result = filter?.match(pattern);

    if (result) {
      const extractedString = result[1];
      functionArr?.push(item.functionDeclaration)
    }
   })
  if(functionArr?.length > 0) {
    return functionArr
  }
  return removeDuplicates(columns)?.filter((column: string) => filter?.includes(column)).map((item: any) => {
    if (item?.includes('.')) {
      return item?.split('.')[1];
    }
    return item
   
})

  }

const findAllValuesByPropertyNameUtility =  (obj: object, propertyName: string) => {
  if(obj === undefined || obj === null || typeof obj !== 'object') {
    return [];
  }
  const arr: any = [];
  const stack = [obj];
  while (stack?.length > 0) {
    const currentObj: any = stack?.pop();
    Object?.keys(currentObj)?.forEach(key => {
      if(key === propertyName) {
        arr.push(currentObj[key])
      }
      if (typeof currentObj?.[key] === 'object' && currentObj?.[key] !== null) {
        stack?.push(currentObj[key]);
      }
    });
  }

  return arr;
}

const buildQueryFunctionList = (ast: any): Array<{functionName: string, arguments: string[], functionDeclaration: string, functionTokens: {
  name: string,
  arguments: string[]
}}>  => {
  const funcCallProperty = 'FuncCall';
  const whereClause = 'whereClause';
  const whereClauseAst = findAllValuesByPropertyNameUtility(ast, whereClause);
  const functionsListRaw = findAllValuesByPropertyNameUtility(whereClauseAst, funcCallProperty);
  // not support agg_star  - we are not related to count * as functions.
  return functionsListRaw.reduce((acc: any, cur: any) => {
      const functionName: string = cur?.funcname?.[0].String?.str;

      const functionArgs: string[] = cur?.args ? cur?.args?.reduce((functionsArgsAcc: any, functionsArgsCur: any) => {
        if(functionsArgsCur?.ColumnRef) {
          functionsArgsAcc?.push(functionsArgsCur?.ColumnRef?.fields?.[0].String?.str);
        }
        if(functionsArgsCur?.A_Const) {
          functionsArgsAcc?.push(functionsArgsCur?.A_Const?.val?.String?.str);
        }
        return functionsArgsAcc;
      }, []) : [];

      acc.push({
        functionName: functionName,
        arguments: functionArgs, 
        functionDeclaration: `${functionName}(${functionArgs.join(',')})`.replace('(,', '('),
        functionTokens: {
          name: functionName,
          arguments: functionArgs
        }
      })
      return acc;
  }, []);
}

const findUsedColumns = (ast: any) => {
  // Properties to search in the ast
  const whereClause = 'whereClause';
  const ColumnRef = 'ColumnRef';

  // Get the values of the columns used in the where clasue
  const whereClauseAst = findAllValuesByPropertyNameUtility(ast, whereClause);
  const columnRefAst = findAllValuesByPropertyNameUtility(whereClauseAst, ColumnRef);

  // Convert the used columns to string as they present in the original sql query
  const res = columnRefAst.map((columnRefArray: any) => {
    const columnsAsArray = columnRefArray?.fields?.reduce((acc: any, cur: any) => {
          acc.push(cur?.String?.str);
          return acc;
    },[])
    return columnsAsArray?.join('.');
  })

  return res;
}

export const createIndexRemedationPlan = (tableInfo: any[], ast: any) => {
  let IsNeedNewIndex = false;
  const columns = findUsedColumns(ast);
  const functionsInWhere = buildQueryFunctionList(ast);
 
  const remTableInfos = tableInfo?.map((table: any) => {
 
    if (
      table?.['Access Method'] &&
      table?.['Access Method'] === 'Table Scan' &&
      table?.['Filter'] &&
      table?.['Filter'] !== 'N/A' &&
      table?.['Filter'] !== 'unknown'
    ) {
      const indexName = getIndexName(table?.['Filter'], columns, functionsInWhere);
      if (indexName && indexName?.length > 0) {
        IsNeedNewIndex = true;
      }
      return {
        ...table,
        remedation: {
          text: 'Add an Index to the table',
          index: indexName
        }
      };
    }
    return table;
  });
  return { table: remTableInfos, isNeedNewIndex: IsNeedNewIndex };
};
