
export const PLAN_PROPS: any = {
    CTE_SCAN : "CTE Scan",
    CTE_NAME : "CTE Name",
    NODE_TYPE : "Node Type",
    ACTUAL_ROWS : "Actual Rows",
    OPERATION : "Operation",
    PLAN_ROWS : "Plan Rows",
    ACTUAL_TOTAL_TIME : "Actual Total Time",
    ROWS_REMOVED_BY_FILTER : "Rows Removed by Filter",
    ROWS_REMOVED_BY_INDEX_RECHECK : "Rows Removed by Index Recheck",
    ACTUAL_LOOPS : "Actual Loops",
    TOTAL_COST : "Total Cost",
    ACTUAL_ROW_SIZE : "Plan Width",
    PLANS : "Plans",
    RELATION_NAME : "Relation Name",
    SCHEMA : "Schema",
    ALIAS : "Alias",
    GROUP_KEY : "Group Key",
    SORT_KEY : "Sort Key",
    JOIN_TYPE : "Join Type",
    INDEX_NAME : "Index Name",
    INDEX_COND : "Index Cond",
    HASH_CONDITION : "Hash Cond",
    PLANNING_TIME_PROP : 'Planning Time',
    FILTER : 'Filter',
    OUTPUT: 'Output'
}

export const PgNodeType =  {
   TABLE_SCAN : "Seq Scan",
   INDEX_SCAN : "Index Scan",
   INDEX_ONLY_SEEK : "Index Only Seek",
   INDEX_ONLY_SCAN : "Index Only Scan",
   BITMAP_INDEX_SCAN : "Bitmap Index Scan",
   TID_SCAN : "Tid Scan",
   SORT : "Sort",
   AGGREGATE : "Aggregate",
   GROUP_BY_AGGREGATE : "Group By Aggregate",
   LIMIT : "Limit",
   UNIQUE : "Unique",
   LOCK_ROWS : "LockRows",
   SET_OP : "SetOp",
   MODIFY_TABLE : "ModifyTable",
   NAME_CAST_TABLE_SCAN : "Table Scan",

}

export const SCAN_LIST = [
  "Seq Scan",
  "Index Access",
  "Index Only Scan",
  "Bitmap Heap Scan",
  "Index Scan",
]

export const SCAN_LIST_008 =  ["Seq Scan", "Index Access", "Index Only Scan", "Index Scan"]
export const SCAN_LIST_009 =  ["Index Scan", "Bitmap Index Scan", "Index Only Scan"]

export const  SCAN_TYPES_LIST = [PgNodeType.TABLE_SCAN, PgNodeType.INDEX_SCAN,
  PgNodeType.INDEX_ONLY_SEEK, PgNodeType.INDEX_ONLY_SCAN,
  PgNodeType.BITMAP_INDEX_SCAN, PgNodeType.TID_SCAN];

export const PLAN_TYPE_ENUM = {
    ACTUAL: 'Actual',
    ESTIMATED: 'Estimated',
};

export const InsightsSchema = [
  {
    id: "QRY-A-000001",
    title: "Number of rows read by the query",
    category: "actual-plan",
    operationCheck: "greater-than-inclusive",
    message: "The query scanned $(facts.actual-rows-read) rows, in all tables, to generate the results set.",
    factName: ["actual-rows-read"],
    conditions: {
      [PLAN_PROPS.ACTUAL_ROWS]: 'positive number',
      [PLAN_PROPS.NODE_TYPE]: `one of ${SCAN_TYPES_LIST.toString()}`,
    },
    calculation: {
      operation: "aggergation over nodes",
      formula: `(${PLAN_PROPS.ROWS_REMOVED_BY_FILTER} || ${PLAN_PROPS.ROWS_REMOVED_BY_INDEX_RECHECK} || 0) +
                ${PLAN_PROPS.ACTUAL_ROWS}) *
                ${PLAN_PROPS.ACTUAL_LOOPS} || 1)`

    },
    severity: [
      {
        priority: 1,
        minVal: 100_001,
        maxVal: 1_000_000
      },
      {
        priority: 2,
        minVal: 50_001,
        maxVal: 100_000
      },
      {
        priority: 3,
        minVal: 25_001,
        maxVal: 50_000
      },
      {
        priority: 4,
        minVal: 0,
        maxVal: 25_000
      },
    ],
  },
  {
    id: "QRY000002",
    title: "Number of columns returned by the query",
    category: "general",
    operationCheck: "greater-than-inclusive",
    message: "The query returns $(facts.num-of-columns) columns",
    factName: ["num-of-columns"],
    conditions: {
      [PLAN_PROPS.OUTPUT]: 'array with some values',
      [PLAN_PROPS.NODE_TYPE]: `one of ${SCAN_TYPES_LIST.toString()}`,
      "queryType": 'SELECT or INSERT'
    },
    calculation: {
      operation: "assertion",
      formula: `${PLAN_PROPS.OUTPUT}`
    },
    severity: [
      {
        priority: 1,
        minVal: 52,
        maxVal: 100
      },
      {
        priority: 2,
        minVal: 37,
        maxVal: 51
      },
      {
        priority: 3,
        minVal: 27,
        maxVal: 36
      },
      {
        priority: 4,
        minVal: 0,
        maxVal: 26
      },
    ],
  },
  {
    id: "QRY-A-000003",
    title: "Query cost",
    category: "actual-plan",
    operationCheck: "greater-than-inclusive",
    message: "The actual cost of the query is $(facts.actual-cost)",
    factName: ["actual-cost"],
    conditions: {
      [PLAN_PROPS.ACTUAL_ROWS]: 'positive number'
    },
    calculation: {
      operation: "aggergation",
      formula: `${PLAN_PROPS.ACTUAL_TOTAL_COST_METRIC}`
    },
    severity: [
      {
        priority: 1,
        minVal: 10_001,
        maxVal: 25_000
      },
      {
        priority: 2,
        minVal: 5_001,
        maxVal: 10_000
      },
      {
        priority: 3,
        minVal: 2_001,
        maxVal: 5_000
      },
      {
        priority: 4,
        minVal: 0,
        maxVal: 2_000
      },
    ],
  },
  {
    id: "QRY-A-000004",
    title: "Number of rows filtered by the query",
    category: "actual-plan",
    operationCheck: "greater-than-inclusive",
    message: "The query filtered $(facts.actual-rows-removed-by-filter) rows",
    factName: ["actual-rows-removed-by-filter"],
    conditions: {
      [PLAN_PROPS.ACTUAL_ROWS]: 'positive number',
      [PLAN_PROPS.ROWS_REMOVED_BY_FILTER]: 'defined',
      [PLAN_PROPS.ROWS_REMOVED_BY_INDEX_RECHECK]: 'defined'
    },
    calculation: {
      operation: "aggergation",
      formula: `(${[PLAN_PROPS.ROWS_REMOVED_BY_FILTER]} ||
        ${[PLAN_PROPS.ROWS_REMOVED_BY_INDEX_RECHECK]}) *
      (${[PLAN_PROPS.ACTUAL_LOOPS]} || 1)`
    },
    severity: [
      {
        priority: 1,
        minVal: 100_001,
        maxVal: 200_000
      },
      {
        priority: 2,
        minVal: 50_001,
        maxVal: 100_000
      },
      {
        priority: 3,
        minVal: 5_001,
        maxVal: 50_000
      },
      {
        priority: 4,
        minVal: 0,
        maxVal: 5_000
      },
    ],
  },
  {
    id: "QRY000005",
    title: "Estimated query cost",
    category: "estimated-plan",
    operationCheck: "greater-than-inclusive",
    message: "The estimated cost of the query is $(facts.estimated-cost)",
    factName: ["estimated-cost"],
    conditions: {
      [PLAN_PROPS.ACTUAL_ROWS]: 'undefined',
      [PLAN_PROPS.ROWS_REMOVED_BY_FILTER]: 'defined',
      [PLAN_PROPS.ROWS_REMOVED_BY_INDEX_RECHECK]: 'defined'
    },
    calculation: {
      operation: "aggergation",
      formula: `(${[PLAN_PROPS.ROWS_REMOVED_BY_FILTER]} ||
        ${[PLAN_PROPS.ROWS_REMOVED_BY_INDEX_RECHECK]}) *
      (${[PLAN_PROPS.ACTUAL_LOOPS]} || 1)`
    },
    severity: [
      {
        priority: 1,
        minVal: 10_001,
        maxVal: 25_000
      },
      {
        priority: 2,
        minVal: 5001,
        maxVal: 10_000 
      },
      {
        priority: 3,
        minVal: 2_001,
        maxVal: 5_000
      },
      {
        priority: 4,
        minVal: 0,
        maxVal: 2_000
      },
    ],
  },
  {
    id: "QRY000006",
    title: "Total estimated cost of reading data from all tables",
    category: "estimated-plan",
    operationCheck: "greater-than-inclusive",
    message: "The total estimated cost from reading rows from $(facts.num-of-tables) tables is $(facts.total-estimated-cost-of-read-operations)",
    factName: ["total-estimated-cost-of-read-operations","num-of-tables"],
    conditions: {
      [PLAN_PROPS.ACTUAL_ROWS]: 'undefined',
      [PLAN_PROPS.NODE_TYPE]: `one of ${SCAN_LIST.toString()}`
    },
    calculation: {
      operation: "aggergation",
      formula: `${[PLAN_PROPS.TOTAL_COST]}`
    },
    severity: [
      {
        priority: 1,
        minVal: 15_001,
        maxVal: 50_000
      },
      {
        priority: 2,
        minVal: 10_001,
        maxVal: 15_000 
      },
      {
        priority: 3,
        minVal: 7_001,
        maxVal: 10_000
      },
      {
        priority: 4,
        minVal: 0,
        maxVal: 7_000
      },
    ],
  },
  {
    id: "QRY000007",
    title: "The Query sorted a large number of rows",
    category: "estimated-plan",
    operationCheck: "greater-than-inclusive",
    message: "The query sorted $(facts.total-num-of-rows-sorted) rows. $(facts.sort-operations-cost-percent) percent of the total CPU and IO operations.",
    factName: ["total-estimated-cost-of-read-operations","num-of-tables"],
    conditions: {
      [PLAN_PROPS.ACTUAL_ROWS]: 'undefined',
      [PLAN_PROPS.NODE_TYPE]: 'Equal to Sort'
    },
    calculation: {
      operation: "aggergation",
      formula: `${[PLAN_PROPS.PLAN_ROWS]}`
    },
    severity: [
      {
        priority: 1,
        minVal: 500_001,
        maxVal: 1_000_000
      },
      {
        priority: 2,
        minVal: 100_001,
        maxVal: 500_000 
      },
      {
        priority: 3,
        minVal: 50_001,
        maxVal: 100_000
      },
      {
        priority: 4,
        minVal: 0,
        maxVal: 50_000
      },
    ],
  },
  {
    id: "QRY000008",
    title: "The Query returned a large number of rows",
    category: "estimated-plan",
    operationCheck: "greater-than-inclusive",
    message: "The query scanned $(facts.estimated-rows-returned) rows, in all tables, to generate the results set.",
    factName: ["estimated-rows-returned"],
    conditions: {
      [PLAN_PROPS.ACTUAL_ROWS]: 'undefined',
      [PLAN_PROPS.NODE_TYPE]: `one of ${SCAN_LIST_008.toString()}`,
      //seperate condition
      [PLAN_PROPS.NODE_TYPE]: 'Equal to Seq Scan',
      [PLAN_PROPS.FILTER]: 'undefined'
    },
    calculation: {
      operation: "aggergation",
      formula: `${[PLAN_PROPS.PLAN_ROWS]}`
    },
    severity: [
      {
        priority: 1,
        minVal: 500_001,
        maxVal: 1_000_000
      },
      {
        priority: 2,
        minVal: 100_001,
        maxVal: 500_000 
      },
      {
        priority: 3,
        minVal: 50_001,
        maxVal: 100_000
      },
      {
        priority: 4,
        minVal: 0,
        maxVal: 50_000
      },
    ],
  },
  {
    id: "QRY000009",
    title: "The Query read a large number of rows",
    category: "estimated-plan",
    operationCheck: "greater-than-inclusive",
    message: "The query read $(facts.estimated-rows-read) rows, in all tables, to generate the results set.",
    factName: ["estimated-rows-read"],
    conditions: {
      [PLAN_PROPS.ACTUAL_ROWS]: 'undefined',
      [PLAN_PROPS.NODE_TYPE]: `one of ${SCAN_LIST_009.toString()}`,
    },
    calculation: {
      operation: "aggergation",
      formula: `${[PLAN_PROPS.PLAN_ROWS]}`
    },
    severity: [
      {
        priority: 1,
        minVal: 100_001,
        maxVal: 1_000_000
      },
      {
        priority: 2,
        minVal: 50_001,
        maxVal: 100_000 
      },
      {
        priority: 3,
        minVal: 25_001,
        maxVal: 50_000
      },
      {
        priority: 4,
        minVal: 0,
        maxVal: 25_000
      },
    ],
  }
];
