import { createTheme } from '@mui/material';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
   body {
    background: #fff;
    color: #6B7186;
    margin:0;
    padding:0;
    font-family: 'Hind', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: overlay;
  }
  /* width */
::-webkit-scrollbar {
  width: 5px;
  height:5px;
}
ol {
  padding: 0px 0px 0px 10px;
}

li::marker {
   font-size: 1.1em;
   font-weight: 600;
   color: #4B4ECD;
}

.disabled-true {
  color: #CED0D6;
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
.recharts-brush-texts {
  display:none;
}
/* Track */
 ::-webkit-scrollbar-track {
  background: transparent;
} 

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D3D3DF;
  border-radius: 5px;
}

/* AgGridReact */
.ag-theme-material {
  --ag-header-height: 40px;
  --ag-header-foreground-color: #383975; //grey[50]
  --ag-header-background-color: #F1F1F6; //text.main
  --ag-header-cell-hover-background-color: #F9F9FB; //grey[20]
  // --ag-header-cell-moving-background-color: rgb(80, 40, 140);
}
`;
export const theme = {
  colors: {
    primary: { light: '#0097FB', main: '#0E639C', dark: '#050b20', border: '#7285F0' },
    secondary: { dark: '#091235', hover: '#222A49' },
    info: { main: '#66DFC8', hover: '#38AFA2', dark: '#2B897E' },
    error: {
      critical: '#F20530',
      criticalBg: '#FEE7EB',
      high: '#FF790B',
      medium: '#FFCC00',
      low: '#03D0FF',
      healthy: '#63D471',
      skipped: '#FFCC00'
    },
    assertions: { success: '#03D0FF', fail: '#F20530', skipped: '#FFCC00', errors: '#F47C7C' },
    tablesInfo: { alert: '#ff7b7b' },
    grey: {
      20: '#F9F9FB',
      50: '#F1F1F6',
      100: '#E4E5F8',
      150: '#D7D7E3',
      200: '#C9CAF0',
      300: '#CED0D6',
      350: '#EBEBF1',
      400: '#6B7383',
      450: '#A7A8D7',
      500: '#6B7186'
    },
    text: {
      title: '#4B4ECD',
      main: '#383975'
    },
    white: '#fff',
    chart: {
      main: '#b7cdef',
      hover: '#608EDC',
      secondary: '#ACC8F9'
    }
  }
};

export const themeMui = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#4B4ECD',
      contrastText: '#383975'
    },
    secondary: {
      main: '#66DFC8',
      contrastText: '#fff'
    },
    grey: {
      50: '#F1F1F6',
      500: '#6B7186'
    },
    action: {
      hover: '#F2F3FC'
    }
  },
  typography: {
    fontSize: 14,
    fontFamily:
      'Hind, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'
  },
  components: {
    // MuiBackdrop: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: 'rgba(0, 0, 0, 0)'
    //     }
    //   }
    // },
    //   MuiDrawer: {
    //     styleOverrides: {
    //       root: {
    //         position: 'unset'
    //       }
    //     }
    //   },

    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&.Mui-disabled': {
            color: '#fff'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#091235',
          color: '#fff',
          boxShadow: 'none'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: '#383975',
          borderColor: '#D7D7E3'
        },
        notchedOutline: {
          borderColor: '#D7D7E3'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '0 15px',
          minHeight: '30px'
        }
      }
    }
  }
});

// components: {
//   MuiButtonBase: {
//     styleOverrides: {
//       root: {
//         backgroundColor: '#091235',
//         borderRadius: '5px'
//       }
//     }
//   },
//   MuiAccordion: {
//     styleOverrides: {
//       root: {
//         borderRadius: '5px',
//         color: '#fff'
//       }
//     }
//   },
//   MuiAccordionDetails: {
//     styleOverrides: {
//       root: {
//         backgroundColor: '#FFF'
//       }
//     }
//   },
//   MuiAccordionSummary: {
//     styleOverrides: {
//       root: {
//         '&.Mui-expanded': {
//           minHeight: '30px',
//           maxHeight: '40px'
//         }
//       }
//     }
//   },
//   MuiCollapse: {
//     styleOverrides: {
//       root: {
//         borderTop: '20px solid #CED0D7'
//       }
//     }
//   },
