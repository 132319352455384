import { useAuth, useLoginActions } from '@frontegg/react';
import React, { useContext, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as Sentry from '@sentry/react';
import { useSetRecoilState } from 'recoil';

import {
  History,
  Error,
  RecentActivity,
  Home,
  General,
  ErrorPage,
  PerformanceDetails,
  Reports,
  Login,
  Boarding,
  QueryAnalyzer,
  Projects,
  ProjectItem,
  SchemaAnalysis,
  TableAnalysis,
  SchemaMigration,
  MigrationAnalysis,
  ObservabilityDashboard,
  HostDashboard,
  TestPage,
  Enrichment
} from './routes';
import { TableRowSize } from './routes/Reports/reports/TableRowSize';
import { RestClientContext } from './auth/RestClientAuthProvider';
import { UserSettings, userSettingsAtom } from './atoms/user.settings.atom';
import AdminPanel from './routes/AdminPanel/AdminPanel';
import { IndexUsage } from './routes/Reports/reports/IndexUsage';
import { snackbarState } from './atoms/snackbar.atom';
import MAlert from './core/Alert';
import usePrevious from './hooks/usePrevious';
import { debugModeState } from './atoms/debug-mode.atom';
import { analyticsIdentity } from './utils/segment-analytics';
import SplashScreen from './components/SplashScreen/SplashScreen';
import { getCookie } from './utils/utils';
import { QueryStatistics } from './routes/Reports/reports/QueryStatistics';
import { DEMO_USER_EMAIL, DYNAMIC_IMPORT_MODLE_ERROR_MESSAGE, isDemo } from './common/consts';
import { DbConfigReport } from './routes/Reports/reports/DbConfigReport';
import { DbExtensionsReport } from './routes/Reports/reports/DbExtensionsReport';
import SettingsRoute from './routes/Settings/SettingsRoute';

const ProtectedRoute = ({ path, component }: any) => {
  const Component = component;
  const setSnackbarS = useSetRecoilState(snackbarState);
  const prevPath: any = usePrevious(path);

  return (
    <Sentry.ErrorBoundary
      onError={(err) => {
        if (err && err.message && typeof err.message === 'string' && err.message.includes(DYNAMIC_IMPORT_MODLE_ERROR_MESSAGE)) {
          window.location.reload();
        } else {
          setSnackbarS((old) => {
            return {
              ...old,
              cb: () => window.location.replace(prevPath || '/'),
              open: true,
              severity: 'error',
              text: 'We encountered an issue, we will be redirected to previous page.'
            };
          });
        }
        console.log(err);
      }}
    >
      <React.Suspense>
        <Component />
      </React.Suspense>
    </Sentry.ErrorBoundary>
  );
};

const App = () => {
  const restClient = useContext(RestClientContext);
  const setUserSettings = useSetRecoilState(userSettingsAtom);
  const { isAuthenticated, isLoading: authIsLoading, user } = useAuth();

  const setDebugMode = useSetRecoilState(debugModeState);

  useEffect(() => {
    setDebugMode(localStorage.getItem('debug_mode') === 'true');
  }, []);
  const ga = new String(getCookie('_ga')).replace(/(G[a-zA-Z])\w.\w./g, '');

  const { isLoading } = useQuery(
    'init',
    () => restClient?.get('init', { searchParams: { gaId: ga } }).json<{ settings: UserSettings }>(),
    {
      refetchOnWindowFocus: false,
      enabled: isAuthenticated,
      onSuccess: (data: any) => {
        setUserSettings(data.settings); /* Identify a visitor */
        analyticsIdentity(data?.providerId, {
          email: data?.email,
          providedId: data?.providedId
        });
      }
    }
  );

  useEffect(() => {
    if (isDemo && isAuthenticated && user?.email !== DEMO_USER_EMAIL) {
      silentLogoutInDemo();
    }
  }, [])

  const loginActions = useLoginActions();
  const navigate = useNavigate();
  const silentLogoutInDemo = () => {
    loginActions.logout(() => {
      navigate('/login');
    });

  }

  if (isLoading || authIsLoading) {
    return <SplashScreen />;
  }
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="settings" element={<SettingsRoute />}>
            <Route index element={<ProtectedRoute component={General} />} />
            <Route path="admin-panel" element={<ProtectedRoute component={AdminPanel} />} />
            <Route path="activity" element={<ProtectedRoute component={() => <h2>Activity Log</h2>} />} />
            <Route path="enrichment" element={<ProtectedRoute component={Enrichment} />} />
            <Route path="exporter" element={<ProtectedRoute component={() => <h2>Exporter</h2>} />} />
          </Route>
          <Route
            path="404"
            element={
              <React.Suspense fallback={<>...</>}>
                <ErrorPage />
              </React.Suspense>
            }
          />
          <Route path="history/:historyId" element={<ProtectedRoute path={'/history/:historyId'} component={History} />} />
          <Route path="query-analysis" element={<ProtectedRoute path={'/query-analysis'} component={QueryAnalyzer} />} />
          <Route path="projects">
            <Route index element={<ProtectedRoute component={Projects} />} />
            <Route path=":projectApiKey">
              <Route index element={<ProtectedRoute component={ProjectItem} />} />
              <Route
                path="observability-dashboard/:pmcDeviceId"
                element={<ProtectedRoute component={ObservabilityDashboard} />}
              />
              <Route path="dashboard/:pmcDeviceId/:hostName/host" element={<ProtectedRoute component={HostDashboard} />} />
              <Route path="reports" element={<ProtectedRoute component={Reports} />}>
                <Route path=":pmcDeviceId/table-row-size-trend" element={<ProtectedRoute component={TableRowSize} />} />
                <Route path=":pmcDeviceId/index-usage" element={<ProtectedRoute component={IndexUsage} />} />
                <Route path=":pmcDeviceId/query-statistics" element={<ProtectedRoute component={QueryStatistics} />} />
                <Route path=":pmcDeviceId/db-config" element={<ProtectedRoute component={DbConfigReport} />} />
                <Route path=":pmcDeviceId/db-extensions" element={<ProtectedRoute component={DbExtensionsReport} />} />
              </Route>
              <Route path="test/:testName">
                <Route index element={<ProtectedRoute component={TestPage} />} />
                <Route path="migration/:migrationID">
                  <Route index element={<ProtectedRoute component={SchemaMigration} />} />
                  <Route path="analysis" element={<ProtectedRoute component={MigrationAnalysis} />} />
                </Route>
                <Route path=":performanceId" element={<ProtectedRoute component={PerformanceDetails} />} />
              </Route>
              <Route path="activities">
                <Route index element={<ProtectedRoute component={RecentActivity} />} />
                <Route path="source/:source" element={<ProtectedRoute component={TestPage} />} />
                <Route path="source/:source/:performanceId" element={<ProtectedRoute component={PerformanceDetails} />} />
                <Route path=":performanceId" element={<ProtectedRoute component={PerformanceDetails} />} />
              </Route>
              <Route path="schemaAnalysis/:pmcDeviceId">
                <Route index element={<ProtectedRoute component={SchemaAnalysis} />} />
                <Route path=":tableID" element={<ProtectedRoute component={TableAnalysis} />} />
              </Route>
            </Route>
          </Route>
          <Route path="admin-panel" element={<ProtectedRoute component={AdminPanel} />} />
        </Route>
        <Route path="boarding">
          <Route index element={<ProtectedRoute component={Boarding} />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/login/:loginType" element={<Login />} />
        <Route path="/signup" element={<Login signUp />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <MAlert />
    </>
  );
}

export default App;