import { atom, selector } from 'recoil';
import { SnackbarSeverityE } from '../enums/snackbar-severity.enum';

export interface SnackbarStateI {
  open: boolean;
  severity: any;
  text: string;
  cb?: any;
}

export const snackbarState = atom<SnackbarStateI>({
  key: 'snackbarState',
  default: {
    open: false,
    severity: SnackbarSeverityE.info,
    text: 'default'
  }
});

export const snackbarStateSelector = selector({
  key: 'snackbarStateSelector',
  get: ({ get }: any) => {
    return get(snackbarState);
  }
});
