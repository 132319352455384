import { Grid } from '@mui/material';
import { Link as Li } from 'react-router-dom';
import styled from 'styled-components';
import Flex from '../../core/Flex';

export const LogListContainer = styled.div`
  height: calc(100vh - 255px);
  overflow-y: auto;
  width: 100%;
`;
export const LogContainer = styled.div`
  height: 400px;
  overflow-y: auto;
  width: 100%;
`;

export const RecentActivityNoDataContainer = styled(Flex)`
  height: calc(100vh - 300px);
  width: 100%;
`;
export const Link = styled(Li)`
  padding: 0 5px;
`;

export const Container = styled.div<{ open: boolean }>`
  height: calc(100vh - 220px);
  overflow: auto;
  width:${({ open }) => (open ? `calc(100% - 250px)` : '100%')}};
  transition:width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;
export const GridContainer = styled(Grid)<{ open?: boolean }>`
  position: relative;
  width:${({ open }) => (open ? `calc(100% - 250px)!important` : '100%')}};
  transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${({ theme }) => `${theme.colors.text.main}`}};
  background:${({ theme }) => `${theme.colors.grey[50]}`}};
  padding:5px 15px;
  border-radius:2px 2px 0px 0px;
  border: 1px solid ${({ theme }) => `${theme.colors.grey[100]}`}};
`;
export const GridListContainer = styled(GridContainer)`
  background:${({ theme }) => `${theme.colors.grey[100]}`}};
  border: 1px solid ${({ theme }) => `${theme.colors.chart.secondary}`}};
  &:hover {
    background:${({ theme }) => `${theme.colors.grey[20]}`}};
  }
`;
