import Editor, { EditorProps } from '@monaco-editor/react';
import styled from 'styled-components';
import CopyToClipBoard from '../../components/Buttons/CopyToClipBoard';

const defaultParams = {
  defaultLanguage: 'json',
  height: '75vh',
  theme: 'github',
  minimap: { enabled: false },
  contextmenu: false,
  showLineNumbers: false
};

type CodeEditorType = {
  readonly?: boolean;
  height?: string;
  width?: string;
  language?: string;
  isCopyValue?: boolean;
  showLineNumbers?: boolean;
} & EditorProps;

const CodeEditor = ({
  readonly = false,
  width,
  height = defaultParams.height,
  language = defaultParams.defaultLanguage,
  value = '',
  isCopyValue,
  showLineNumbers,
  ...rest
}: CodeEditorType) => {
  const showLine: any = !showLineNumbers
    ? {
        renderLineHighlight: 'none',
        lineNumbers: 'off'
      }
    : {};
  return (
    <CodeWrapper width={width}>
      {isCopyValue && (
        <CopyContainer>
          <CopyToClipBoard data={JSON.stringify(value)} manipulateFunction={() => value} />
        </CopyContainer>
      )}
      <Editor
        height={height}
        defaultLanguage={language}
        theme={defaultParams.theme}
        defaultValue=""
        options={{
          readOnly: readonly,
          minimap: defaultParams.minimap,
          scrollBeyondLastLine: false,
          lineDecorationsWidth: 4,
          lineNumbersMinChars: 2,
          ...showLine
        }}
        value={value}
        {...rest}
      />
    </CodeWrapper>
  );
};

export default CodeEditor;

export const CodeWrapper = styled.div<any>`
  position: relative;
  width: ${({ width }) => width || 'inherit'};
  height: ${({ height }) => height || 'inherit'};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.grey[150]};
  .monaco-editor .margin {
    background: ${({ theme }) => theme.colors.grey[20]};
  }
  .monaco-editor-overlaymessage {
    display: none !important;
  }
  .monaco-editor-background {
    background: ${({ theme }) => theme.colors.grey[20]};
  }
  .decorationsOverviewRuler {
    display: none !important;
  }
`;

export const CopyContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 9;
`;
