import { OnChange } from '@monaco-editor/react';
import { Button } from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ApiKeyType, apiKeysState } from '../../../atoms/api-keys.atom';
import Select from '../../../components/Select/Select';
import Flex from '../../../core/Flex';
import { Title } from '../../PerformanceDetails/PerformanceDetails.styled';
import { generateQuerySpan, generateRouteSpan } from '../utils/generateSpansObject';
import { v4 as uuidv4 } from 'uuid';
import CodeEditor from '../../../core/MonacoEditor/CodeEditor';
import { RestClientContext } from '../../../auth/RestClientAuthProvider';

const template = {
  ['Tag Key']: 'tag key',
  ['Tag Value']: 'tag value',
  ['Service Name']: 'service name',
  ['url']: '/exampe-url-replace-me',
  ['duration']: 150,
  ['queries and plans']: [
    {
      query: 'select * from table',
      plan: []
    },
    {
      query: 'select * from table',
      plan: []
    }
  ]
};

const TraceGenerator = () => {
  const restClient = useContext(RestClientContext);

  const [apiKey, setApikey] = useState('');
  const { apiKeys } = useRecoilValue(apiKeysState);
  const [editedData, setEditedData] = useState('');
  const onEditedDataChange = useCallback<OnChange>((data?: string) => setEditedData(data ?? ''), []);

  const generateTrace = () => {
    const dataAsObject = JSON.parse(editedData);
    const traceId = uuidv4();

    const tag =
      (dataAsObject['Tag Key'] &&
        dataAsObject['Tag Value'] && {
          [`app.tag.${dataAsObject['Tag Key'].toLocaleLowerCase()}`]: dataAsObject['Tag Value']
        }) ||
      {};
    const routeSpan = generateRouteSpan(dataAsObject.duration, dataAsObject['Service Name'], tag, dataAsObject.url, traceId);
    const querySpans = !dataAsObject['queries and plans']
      ? []
      : dataAsObject['queries and plans'].map((item: any) => {
          return generateQuerySpan(dataAsObject.duration, dataAsObject['Service Name'], tag, item.query, item.plan, traceId);
        });

    return [routeSpan, ...querySpans];
  };

  return (
    <Flex direction={'column'} style={{ paddingBottom: 15 }}>
      <Title h2>Trace Generator</Title>

      <Select
        minWidth={'400px'}
        changeValueOnPress={true}
        name={'Api Key'}
        list={apiKeys.map((key: ApiKeyType, idx: number) => {
          return { id: idx, name: key.label ?? key.api_key, val: key.api_key };
        })}
        value={apiKey}
        onSelect={setApikey}
      />
      <CodeEditor height="500px" width="95%" value={JSON.stringify(template, null, 2)} onChange={onEditedDataChange} />
      <div style={{ marginTop: 35 }}>
        <Button
          variant="outlined"
          onClick={() =>
            restClient.post('mock/spans', { searchParams: { 'api-key': apiKey }, json: { spans: generateTrace() } }).json()
          }
        >
          Save Changes
        </Button>
      </div>
    </Flex>
  );
};

export default TraceGenerator;
