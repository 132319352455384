export enum Envs {
  development = 'development',
  production = 'production',
  staging = 'staging'
}
import 'virtual:vite-plugin-sentry/sentry-config';

export const SENTRY_CONFIG = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG;
export const FRONTEND_VERSION = SENTRY_CONFIG.release || import.meta.env.VITE_METIS_VERSION;
export const CURRENT_ENV = import.meta.env.VITE_APP_ENV || import.meta.env.NODE_ENV;
export const isProduction = () => import.meta.env.VITE_APP_ENV === Envs.production;
export const isDevelopment = () => import.meta.env.VITE_APP_ENV === Envs.development;
export const isStaging = () => import.meta.env.VITE_APP_ENV === Envs.staging;
