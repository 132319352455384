import { format } from 'sql-formatter';
import * as Sentry from '@sentry/react';

import { LogRecord } from '../types/LogRecord.type';
import { INSIGHT_STATUS, INSIGHT_STATUS_ENUM } from './constants';
import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';
dayjs.extend(Duration);

/**
 * A string literal type that represents the level of precision for a short date.
 * - `'xs'`: Includes MMM-DD-YY HH:MM:ss.
 * - `'s'`: Includes MMM-DD-YY HH:MM.
 * - `'m'`: Includes MMM-DD-YY HH.
 * - `'l'`: Includes MMM-DD-YY.
 */
export type ShortDateFormat = 'xs' | 's' | 'm' | 'l';

export const getFormattedDate = (date?: Date | string, shortDate: ShortDateFormat = 's'): string => {
  const formatHandlers: Record<ShortDateFormat, (date?: Date | string) => string> = {
    xs: (date?: Date | string) => dayjs(date).format('MMM-DD-YY HH:mm:ss'),
    s: (date?: Date | string) => dayjs(date).format('MMM-DD-YY HH:mm'),
    m: (date?: Date | string) => dayjs(date).format('MMM-DD-YY HH'),
    l: (date?: Date | string) => dayjs(date).format('MMM-DD-YY')
  };
  if (!date) return '';
  return formatHandlers[shortDate](date);
};
export const getTags = (tags: any[]) => {
  return tags.reduce((totalTags: any, curTag: any) => {
    if (totalTags.length == 0 || totalTags.filter((t: any) => Object.values(t)[0] != Object.values(curTag)[0]).length) {
      totalTags.push(curTag);
    }
    return totalTags;
  }, []);
};

export const formatSQL = (data: string, catchCb?: any): string => {
  try {
    return format(data, { language: 'postgresql' });
  } catch (error) {
    catchCb && catchCb(error);
    Sentry.captureException(error, { extra: { ErrorMessage: 'Failed formatSQL', SQL: data } });
    return `${data}`;
  }
};

export const getCookie = (name: string) => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const isTypeOf = (val: any, type: string) => {
  return typeof val === type;
};

export const numberWithCommas = (x: number, toFixedNumber = 0): string => {
  if (typeof x !== 'number') {
    return '0';
  }
  const numberFixed = x.toFixed(toFixedNumber);
  return numberFixed.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getFacts = (facts: any) => {
  if (facts?.length > 0) {
    return facts.map((fact: any) => {
      return {
        'Metric Name': fact.metric_name,
        Value: isTypeOf(fact.value, 'number') ? numberWithCommas(fact.value) : fact.value,
        Category: fact.category,
        Type: fact.type,
        Description: fact.description
      };
    });
  }
  return [];
};

export const getFactsAsKeyVal = (facts: any) => {
  if (facts?.length > 0) {
    return facts.reduce((acc: any, cur: any) => {
      return {
        ...acc,
        [cur.metric_name]: cur.value
      };
    }, {});
  }
  return {};
};

export function getTotalSeverity(assertion: any[]) {
  if (assertion.length) {
    return assertion.reduce((acc, part) => {
      const priority = part?.priority ? part?.priority : part?.condition?.event?.priority;
      const counter: number = acc[INSIGHT_STATUS[priority]] ? acc[INSIGHT_STATUS[priority]] : 0;

      return {
        ...acc,
        [INSIGHT_STATUS[priority]]: counter + 1
      };
    }, {});
  }
  return {};
}

export const getSeverity = (severityTotal: any, severityItem: any) => ({
  [INSIGHT_STATUS_ENUM.CRITICAL]: INSIGHT_STATUS[severityItem[1]]
    ? severityTotal[INSIGHT_STATUS[1]] + severityItem[1]
    : severityTotal[INSIGHT_STATUS_ENUM.CRITICAL],
  [INSIGHT_STATUS_ENUM.HIGH]: INSIGHT_STATUS[severityItem[2]]
    ? severityTotal[INSIGHT_STATUS[2]] + severityItem[2]
    : severityTotal[INSIGHT_STATUS_ENUM.HIGH],
  [INSIGHT_STATUS_ENUM.MEDIUM]: INSIGHT_STATUS[severityItem[3]]
    ? severityTotal[INSIGHT_STATUS[3]] + severityItem[3]
    : severityTotal[INSIGHT_STATUS_ENUM.MEDIUM],
  [INSIGHT_STATUS_ENUM.LOW]: INSIGHT_STATUS[severityItem[4]]
    ? severityTotal[INSIGHT_STATUS[4]] + severityItem[4]
    : severityTotal[INSIGHT_STATUS_ENUM.LOW],
  [INSIGHT_STATUS_ENUM.INFO]: INSIGHT_STATUS[severityItem[5]]
    ? severityTotal[INSIGHT_STATUS[5]] + severityItem[5]
    : severityTotal[INSIGHT_STATUS_ENUM.INFO]
});

export const groupBy = (list: any[], key: string) => {
  return list.reduce((prev, curr) => {
    return {
      ...prev,
      [curr[key]]: [...(prev[curr[key]] || []), curr]
    };
  }, {});
};

export const toNumbersSuffix = (num: number | string, fixed = 2): { num: number | string; suffix: string } => {
  const numSuffix = {
    num: num,
    suffix: ''
  };
  if (num === undefined || num === null) {
    return numSuffix;
  }
  if ((typeof num === 'number' && num > 1_000_000_000) || (typeof num === 'string' && parseFloat(num) > 1_000_000_000)) {
    numSuffix.num = parseFloat(((typeof num === 'number' ? num : parseFloat(num)) / 1_000_000_000).toFixed(fixed));
    numSuffix.suffix = 'B';
    return numSuffix;
  }
  if ((typeof num === 'number' && num > 1_000_000) || (typeof num === 'string' && parseFloat(num) > 1_000_000)) {
    numSuffix.num = parseFloat(((typeof num === 'number' ? num : parseFloat(num)) / 1_000_000).toFixed(fixed));
    numSuffix.suffix = 'M';
    return numSuffix;
  }
  if ((typeof num === 'number' && num > 1_000) || (typeof num === 'string' && parseFloat(num) > 1_000)) {
    numSuffix.num = parseFloat(((typeof num === 'number' ? num : parseFloat(num)) / 1_000).toFixed(fixed));
    numSuffix.suffix = 'K';
    return numSuffix;
  }
  return numSuffix;
};

export const getDuration = (dateStart: string, dateEnd: string, isToObject = false, duration = 0) => {
  const range = (dateStart && dateEnd && new Date(dateEnd).getTime() - new Date(dateStart).getTime()) || duration;
  const toObject = (duration: number, type: string) => (isToObject ? { duration, type } : `${duration} ${type}`);
  if (range < 0) return toObject(0, ' now');
  if (range < 1000) return toObject(Math.floor(range), 'ms');
  if (range / 60000 < 1) return toObject(Math.floor(range / 1000), 'sec');
  if (range / 3600000 < 1) return toObject(Math.floor(range / 60000), 'min');
  return toObject(Math.floor(range / 3600000), 'h');
};

export const validateLogRecords = (logRecords: LogRecord[]): void => {
  if (logRecords?.length) {
    const errors = logRecords.filter((log: LogRecord) => log.route == null || log.statusCode == null || log.queries == null);
    if (errors.length) {
      Sentry.captureException({ errors: JSON.stringify(errors) }, { extra: { ErrorMessage: 'Invalid LogRecords params' } });
    }
  }
};

export const capitalizeFirstLetter = (str: string) => {
  if (str && typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return '';
};

export const convertMinutes = (minutes: number) => {
  const duration = dayjs.duration(minutes, 'minutes');

  const days = duration.days();
  const hours = duration.hours();
  const remainingMinutes = duration.minutes();

  return `${days} days ${hours.toString().padStart(2, '0')} hours ${remainingMinutes.toString().padStart(2, '0')} minutes`;
};
