import { ChangeEventHandler, FormEventHandler, useCallback, useContext, useState } from 'react';
import dayjs from 'dayjs';

import Select from '../../../components/Select/Select';
import { useRecoilValue } from 'recoil';
import { ApiKeyType, apiKeysState } from '../../../atoms/api-keys.atom';
import { v4 as uuidv4 } from 'uuid';
import { OnChange } from '@monaco-editor/react';
import CodeEditor from '../../../core/MonacoEditor/CodeEditor';
import { RestClientContext } from '../../../auth/RestClientAuthProvider';

function generateId() {
  return uuidv4();
}

const MAX_URL_DURATION = 350;
const MIN_URL_DURATION = 50;

export const MockSpans = () => {
  const [url, setUrl] = useState('');
  const [urlDuration, setUrlDuration] = useState(
    Math.floor(Math.random() * (MAX_URL_DURATION - MIN_URL_DURATION) + MIN_URL_DURATION)
  );
  const [serviceName, setServiceName] = useState('api-service');
  const [query1, setQuery1] = useState('');
  const [plan1, setPlan1] = useState('');
  const [query2, setQuery2] = useState('');
  const [plan2, setPlan2] = useState('');
  const [apiKey, setApikey] = useState('');
  const [numberOfCopies, setNumberOfCopies] = useState(1);
  const [tagValue, setTagValue] = useState('');
  const [tagKey, setTagKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { apiKeys } = useRecoilValue(apiKeysState);
  const restClient = useContext(RestClientContext);

  const onUrlChanged = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => setUrl(e.target.value), []);
  const onUrlDurationChanged = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => setUrlDuration(Number(e.target.value)),
    []
  );
  const onQueryChanged1 = useCallback<ChangeEventHandler<HTMLTextAreaElement>>((e) => setQuery1(e.target.value), []);
  const onPlanChanged1 = useCallback<OnChange>((plan?: string) => setPlan1(plan ?? ''), []);
  const onQueryChanged2 = useCallback<ChangeEventHandler<HTMLTextAreaElement>>((e) => setQuery2(e.target.value), []);
  const onPlanChanged2 = useCallback<OnChange>((plan?: string) => setPlan2(plan ?? ''), []);

  const onApikeyChanged = useCallback((value: any) => setApikey(value), []);
  const onNumberOfCopiesChanged = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => setNumberOfCopies(Number(e.target.value)),
    []
  );
  const onTagValueUpdate = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => setTagValue(e.target.value), []);
  const onTagKeyUpdate = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => setTagKey(e.target.value), []);

  const upload = useCallback(async () => {
    try {
      const traceId = generateId();

      const routeStartTime = new Date();
      const routeEndTime = dayjs(routeStartTime).add(urlDuration, 'millisecond');

      const query1StartTime = dayjs(routeStartTime).add(5, 'millisecond');
      const query1EndTime = dayjs(routeEndTime).subtract(5, 'millisecond');

      const query2StartTime = dayjs(routeStartTime).add(Math.floor(Math.random() * 10) + 5, 'millisecond');
      const query2EndTime = dayjs(routeEndTime).subtract(20, 'millisecond');
      const tag =
        (tagKey &&
          tagValue && {
            [`app.tag.${tagKey.toLocaleLowerCase()}`]: tagValue
          }) ||
        {};
      const routeSpan = {
        kind: 'SpanKind.SERVER',
        name: `/debug/`,
        links: [],
        events: [],
        status: {
          status_code: 'UNSET'
        },
        context: {
          span_id: `${generateId()}`,
          trace_id: `${traceId}`,
          trace_state: '[]'
        },
        end_time: `${routeEndTime.toISOString()}`,
        resource: {
          'service.name': serviceName,
          'telemetry.sdk.name': 'opentelemetry',
          'telemetry.sdk.version': '1.11.1',
          'telemetry.sdk.language': 'python',
          ...tag
        },
        parent_id: null,
        attributes: {
          'http.url': 'http://test:None/v1/policies/environment/',
          'http.host': 'test:None',
          'http.route': url,
          'http.flavor': '1.1',
          'http.method': 'POST',
          'http.scheme': 'http',
          'http.target': url,
          'net.peer.ip': '127.0.0.1',
          'net.peer.port': 123,
          'track.by.metis': true,
          'http.user_agent': 'python-httpx/0.21.3',
          'http.server_name': 'test',
          'http.status_code': 200,
          'http.request.header.content_type': ['application/json'],
          'http.response.header.content_type': ['application/json'],
          'http.response.header.content_length': ['643']
        },
        start_time: `${routeStartTime.toISOString()}`
      };
      let planParsed1 = JSON.parse(plan1);
      planParsed1 = Array.isArray(planParsed1) ? planParsed1[0] : planParsed1;
      const querySpan1 = {
        kind: 'SpanKind.CLIENT',
        name: 'SELECT postgres',
        links: [],
        events: [],
        status: {
          status_code: 'UNSET'
        },
        context: {
          span_id: `${generateId()}`,
          trace_id: `${traceId}`,
          trace_state: '[]'
        },
        end_time: query1EndTime.toISOString(),
        start_time: query1StartTime.toISOString(),
        resource: {
          'service.name': serviceName,
          'telemetry.sdk.name': 'opentelemetry',
          'telemetry.sdk.version': '1.11.1',
          'telemetry.sdk.language': 'python',
          ...tag
        },
        parent_id: null,
        attributes: {
          'db.name': 'postgres',
          'db.user': 'admin',
          'db.system': 'postgresql',
          'db.statement.metis': `${query1}`,
          'net.peer.name': '127.0.0.1',
          'net.peer.port': 5435,
          'db.statement.metis.plan': JSON.stringify(planParsed1)
        }
      };

      const spans = [querySpan1, routeSpan];

      if (plan2) {
        let planParsed2 = JSON.parse(plan2);
        planParsed2 = Array.isArray(planParsed2) ? planParsed2[0] : planParsed2;
        const querySpan2 = {
          kind: 'SpanKind.CLIENT',
          name: 'SELECT postgres',
          links: [],
          events: [],
          status: {
            status_code: 'UNSET'
          },
          context: {
            span_id: `${generateId()}`,
            trace_id: `${traceId}`,
            trace_state: '[]'
          },
          end_time: query2EndTime.toISOString(),
          start_time: query2StartTime.toISOString(),
          resource: {
            'service.name': 'api-service',
            'telemetry.sdk.name': 'opentelemetry',
            'telemetry.sdk.version': '1.11.1',
            'telemetry.sdk.language': 'python',
            ...tag
          },
          parent_id: null,
          attributes: {
            'db.name': 'postgres',
            'db.user': 'admin',
            'db.system': 'postgresql',
            'db.statement.metis': `${query2}`,
            'net.peer.name': '127.0.0.1',
            'net.peer.port': 5435,
            'db.statement.metis.plan': JSON.stringify(planParsed2)
          }
        };
        spans.push(querySpan2);
      }

      setIsLoading(true);
      setError(null);
      await restClient.post('mock/spans', { searchParams: { 'api-key': apiKey }, json: { spans: spans } }).json();
    } catch (err: any) {
      setError(err?.message);
    } finally {
      setIsLoading(false);
    }
  }, [url, query1, plan1, query2, plan2, apiKey]);

  const multiUpload = useCallback<FormEventHandler>(
    async (e) => {
      e.preventDefault();

      for (let i = 0; i < numberOfCopies; i++) {
        await upload();
      }
      setUrl('');
      setQuery1('');
      setPlan1('');
      setQuery2('');
      setPlan2('');
    },
    [url, query1, plan1, query2, plan2, apiKey]
  );

  return (
    <>
      <form style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }} onSubmit={multiUpload}>
        <div>
          <Select
            minWidth={'400px'}
            changeValueOnPress={true}
            name={'Api Key'}
            list={apiKeys.map((key: ApiKeyType, idx: number) => {
              return { id: idx, name: key.label ?? key.api_key, val: key.api_key };
            })}
            value={apiKey}
            onSelect={onApikeyChanged}
          />
          <button type="submit" disabled={!query1 || !plan1 || !apiKey || !url} style={{ width: '200px', height: '29px' }}>
            {isLoading ? 'saving' : 'upload'}
          </button>
          {error && <span style={{ color: 'red' }}>{error}</span>}
        </div>
        <label style={{ width: '50%' }}>
          number of copies
          <input type="number" style={{ marginLeft: '5px' }} value={numberOfCopies} onChange={onNumberOfCopiesChanged} />
        </label>
        <div>
          <label style={{ width: '50%' }}>
            Tag Key:
            <input type="string" style={{ marginLeft: '5px' }} value={tagKey} onChange={onTagKeyUpdate} />
          </label>
          <label style={{ width: '50%' }}>
            Tag Value:
            <input type="string" style={{ marginLeft: '5px' }} value={tagValue} onChange={onTagValueUpdate} />
          </label>
          <label style={{ width: '50%' }}>
            Service name:
            <input
              type="string"
              style={{ marginLeft: '5px' }}
              value={serviceName}
              onChange={(e) => setServiceName(e.target.value)}
            />
          </label>
        </div>
        <div style={{ display: 'flex', width: '100%' }}>
          <label style={{ width: '60%' }}>
            url
            <input style={{ width: '100%' }} value={url} onChange={onUrlChanged} />
          </label>
          <label style={{ width: '40%' }}>
            duration
            <input style={{ width: '100%' }} type="number" value={urlDuration} onChange={onUrlDurationChanged} />
          </label>
        </div>
        <div>
          <div style={{ display: 'flex', height: '250px' }}>
            <label>query1</label>
            <textarea style={{ width: '50%' }} value={query1} onChange={onQueryChanged1} />
            <label>plan1</label>
            <CodeEditor height="100%" width="50%" value={plan1} onChange={onPlanChanged1} />
          </div>
          <div style={{ display: 'flex', height: '250px' }}>
            <label>query2</label>
            <textarea style={{ width: '50%' }} value={query2} onChange={onQueryChanged2} />
            <label>plan2</label>
            <CodeEditor height="100%" width="50%" value={plan2} onChange={onPlanChanged2} />
          </div>
        </div>
      </form>
    </>
  );
};
