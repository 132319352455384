import Lottie from 'lottie-react';

interface Props {
  lottieJsonFile: any;
  loop: boolean | number;
  height?: number;
  width?: number;
  style?: any;
}

const LottieAnimation = ({ lottieJsonFile, loop, width = 50, height = 50, style }: Props) => {
  return (
    <div style={{ ...style, width: width, height: height }}>
      <Lottie loop={loop} animationData={lottieJsonFile} />
    </div>
  );
};

export default LottieAnimation;
