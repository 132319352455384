//Note: not create function for investigtion cause dont know what is the actaul text yet.

import TableItem from '../../../components/Table/TableItem';
import SafeInnerHtml from '../../SafeInnerHtml';
import { wrapNumbersInBold } from '../utils';

export const AutomatedInvestigationOfSSAFK00002 = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      Usually, the query joins the tables using the PK and FK. If a query involves joining two or more tables on columns with
      mismatched data types, the database may need to perform additional type conversions in order to match the data types of the
      columns being joined. This can slow down the query significantly, especially if the tables being joined are large.
    </>
  ),
  remediation: <>Use the same data type(s) for the PK and FK</>
});

export const AutomatedInvestigationOfSSAFK00001 = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      Performance - A foreign key is usually used to join data with the related primary key table. Without an index, the Query
      database must perform a full table scan of the related table in order to locate the rows that match the foreign key values
      in the original table. This can be very slow, especially for large tables.{' '}
    </>
  ),
  remediation: <>Use the same data type(s) for the PK and FK</>
});

export const RULE_SCHEMA_IDX_02_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      Slow Data Retrieval: Without an index, PostgreSQL has to scan the entire table to retrieve the requested data. This can
      result in slower query performance, especially when dealing with larger datasets. High CPU and Memory Usage: When performing
      table scans, PostgreSQL uses more CPU and memory resources, which can impact the overall performance of the system. Reduced
      Concurrency: Without an index, table locks can be held for a longer period, resulting in reduced concurrency, which can
      impact the scalability of the system.{' '}
    </>
  ),
  remediation: (
    <>
      Create the appropriate indexes on the frequently queried columns and consider partitioning large tables to reduce data
      retrieval times.
    </>
  )
});

export const AutomatedInvestigationOfTBL0002 = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      Increased Storage Requirements: Duplicate indexes result in redundant data, which increases storage requirements and can
      lead to wastage of disk space. Reduced Query Performance: Duplicate indexes can negatively impact query performance, as the
      PostgreSQL query optimizer may choose an inefficient index to execute a query. Slow Data Modification: Insert, update, and
      delete operations on a table with duplicate indexes can be slower due to the overhead of maintaining multiple indexes.{' '}
    </>
  ),
  remediation: (
    <>
      Create the appropriate indexes on the frequently queried columns and consider partitioning large tables to reduce data
      retrieval times.
    </>
  )
});

export const RULE_SCHEMA_IDX_01_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <>
      <SafeInnerHtml
        unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
      />
      {tableItem?.table_details?.indexes && tableItem?.table_details?.indexes.length > 0 && (
        <TableItem
          headHeight={'320px'}
          data={
            tableItem?.table_details?.indexes.map((index: { name: string; relatedColumns: any; relatedExpressions: any }) => ({
              Name: index.name,
              ['Related Columns']:
                index?.relatedColumns?.length > 0
                  ? index.relatedColumns.map((relatedColumn: any) => relatedColumn?.name).join(', ')
                  : 'No Related Columns',
              ['Related Expressions']:
                index?.relatedExpressions?.length > 0
                  ? index.relatedExpressions.map((relatedExpression: any) => relatedExpression?.name).join(', ')
                  : 'No Related Expressions'
            })) || []
          }
        />
      )}
    </>
  ),
  impact: (
    <>
      High number of indexes can slow down write operations and use more disk space. Unused indexes still consume disk space and
      may not improve performance. Queries may not always use indexes, leading to slower performance.{' '}
    </>
  ),
  remediation: (
    <>
      Remove unnecessary or duplicate indexes. Analyze and optimize queries to reduce reliance on indexes. Use partial indexes or
      index-only scans where appropriate to reduce disk space usage.
    </>
  )
});

export const AutomatedInvestigationOfTBLPK0009 = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      Indexing: PostgreSQL does not natively support indexing on JSON data, which means that querying and sorting large datasets
      can be slower and less efficient. In contrast, traditional data types like integers and strings can be indexed for faster
      access. Data Integrity: JSON data is not a primitive data type, and as such, it can be harder to ensure data integrity when
      using JSON as the primary key. The JSON data may contain nested arrays or objects that can make it harder to validate the
      data. Data Size: Storing JSON data as the primary key can lead to an increase in data size, which can impact performance,
      especially in systems that have a high volume of transactions. Complexity: Storing JSON data as a primary key can make
      queries and updates more complex than working with traditional data types.
    </>
  ),
  remediation: (
    <>
      Use a separate column to store the primary key and use the JSON data as a separate column in the table. Alternatively, you
      can use a hash of the JSON data as the primary key, which can help with indexing and querying performance. Additionally, you
      may consider using a NoSQL database like MongoDB, which is designed to work with JSON data natively
    </>
  )
});

export const RULE_SCHEMA_PK_01_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      Without a primary key, it would be difficult to identify and update specific rows in the table. It would also be challenging
      to enforce data integrity constraints, such as ensuring that each record is unique or maintaining referential integrity with
      other tables. A table without a primary key can lead to data duplication, inconsistency, and even data loss in some cases.{' '}
    </>
  ),
  remediation: (
    <>
      The easiest way to add a primary key to a table is to use an auto-incrementing integer column. This will ensure that each
      record in the table has a unique identifier. If a natural key is used as a primary key, it is essential to ensure that it is
      truly unique and that it does not change over time. For example, a social security number or an email address can be
      changed, so it may not be the best choice for a primary key. It is also possible to use a composite primary key, which
      consists of multiple columns. In this case, it is important to ensure that the combination of columns is unique for each
      record in the table.
    </>
  )
});

export const RULE_SCHEMA_PK_02_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      A high number of columns in a primary key can slow down queries, increase storage requirements, and make table maintenance
      more difficult.
    </>
  ),
  remediation: (
    <>
      Keep the number of columns in a primary key as low as possible, limit the number of columns to two or three whenever
      possible, and reevaluate the table schema if a composite primary key with more than three columns is needed.
    </>
  )
});

export const RULE_SCHEMA_FK_01_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: <>If a foreign key doesn't have an index, it can significantly slow down queries that involve joins between tables.</>,
  remediation: (
    <>To avoid this issue, it's recommended to create an index on the foreign key column(s) to improve query performance.</>
  )
});

export const RULE_SCHEMA_IDX_03_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
    // <>
    //   {' '}
    //   In PostgreSQL, an index is used to improve query performance by allowing fast lookup of data based on the indexed columns.
    // </>
  ),
  impact: (
    <>
      A duplicated index can waste disk space and slow down write operations, as well as create confusion and possible errors when
      managing the indexes.
    </>
  ),
  remediation: (
    <>It's recommended to regularly review and remove any duplicated indexes to optimize disk space and improve performance.</>
  )
});

export const RULE_01_SCHEMA_TBL_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    // <>
    //   In PostgreSQL, a wide table refers to a table with many columns, which can lead to performance issues and difficulties in
    //   managing the table.
    // </>
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      Wide tables can negatively impact query performance, especially if the table has a lot of null values or if the table is
      frequently updated.
    </>
  ),
  remediation: (
    <>
      To avoid these issues, it's recommended to normalize the table by breaking it down into smaller tables with fewer columns
      and establishing relationships between them. This can improve performance, simplify management, and reduce storage
      requirements.
    </>
  )
});

export const RULE_01_SCHEMA_TGR_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
    // <> In PostgreSQL, a trigger is a database object that automatically executes a function in response to certain events.</>
  ),
  impact: (
    <>
      A high number of triggers can negatively impact database performance, especially if the triggers are complex or execute
      frequently.
    </>
  ),
  remediation: (
    <>
      To avoid these issues, it's recommended to periodically review and optimize the number of triggers in the database by
      removing any redundant or unnecessary triggers. Additionally, simplifying the logic of triggers and reducing their frequency
      can help improve overall database performance.
    </>
  )
});

export const RULE_SCHEMA_PK_03_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <>
      <SafeInnerHtml
        unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
      />

      <div>{tableItem?.table_details?.pkData?.name}</div>
      {tableItem?.table_details?.pkData?.columns && tableItem?.table_details?.pkData?.columns.length > 0 && (
        <TableItem
          headHeight={'320px'}
          data={
            tableItem?.table_details?.pkData?.columns.map((column: any) => ({
              Name: column.name,
              ['Data Type']: column.dataType
            })) || []
          }
        />
      )}
    </>
  ),
  impact: (
    <>
      A high primary key size in bytes can impact query performance, especially if the primary key is used as a foreign key in
      other tables.
    </>
  ),
  remediation: (
    <>
      To avoid these issues, it's recommended to use smaller data types for primary keys whenever possible. For example, using a
      serial data type instead of a bigint can significantly reduce the size of the primary key. Additionally, it's important to
      ensure that the primary key is optimized for the query workload of the database.
    </>
  )
});

export const RULE_SCHEMA_PK_04_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
    Primary Key values are typically generated automatically. However, if a newly generated value exceeds the maximum supported value for the data type, the table becomes unable to accommodate new records.
    </>
  ),
  remediation: (
    <>
     Change the datatype to one that supports larger numbers. For ex. if the primary key uses <code>int</code> but the table has more than 2B records, change to <code>bigint</code>.
    </>
  )
});

export const RULE_SCHEMA_FK_02_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <>
      <SafeInnerHtml
        unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
      />
      {tableItem?.table_details?.fk && tableItem?.table_details?.fk.length > 0 && (
        <TableItem
          headHeight={'320px'}
          data={
            tableItem?.table_details?.fk.map((foreignKeyData: any) => ({
              ['PK Columns and types']: foreignKeyData?.referencedColumns
                ?.map((column: any) => `${column?.name}(${column?.dataType})`)
                .join(', '),
              ['FK Name']: foreignKeyData?.name,
              ['FK Columns']: foreignKeyData?.columns?.map((column: any) => `${column?.name}-${column?.dataType}`).join(', ')
            })) || []
          }
        />
      )}
    </>
  ),
  impact: (
    <>
      Performance: Matching data types between primary and foreign keys prevents the need for data type conversions during query
      execution. This avoids potential inefficiencies caused by converting data types and ensures that indexes can be effectively
      utilized.
    </>
  ),
  remediation: <>Change the data types of the Foreign Key to be exactly as the Primary Key's data types.</>
});

export const RULE_04_SCHEMA_PK_AI = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      A high number of columns in the primary key can negatively impact query performance, especially if the key is used as a
      foreign key in other tables.
    </>
  ),
  remediation: (
    <>
      To avoid these issues, it's recommended to use the minimum number of columns required to uniquely identify each row in the
      table. Additionally, it's important to ensure that the primary key is optimized for the query workload of the database.
    </>
  )
});

export const RULE_SCHEMA_STAT_03 = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      <ul>
        <li>
          {' '}
          <b>Performance - Dead rows can make queries slower </b>. When a query is executed, the database must scan all of the
          rows in the table, including the dead rows. This can significantly slow down the query, especially if the table is
          large.
        </li>

        <li>
          {' '}
          <b>Storage - Dead rows take up space</b>. Dead rows are rows that have been deleted from a table, but the space they
          occupied has not been reused. This can lead to wasted space on disk, which can make the database slower and less
          efficient.
        </li>

        <li>
          <b>Storage - Dead rows can make backups larger</b>. When a backup of a PostgreSQL database is created, all of the rows
          in the table, including the dead rows, are copied to the backup file. This can make the backup file larger and take
          longer to create.
        </li>
      </ul>
    </>
  ),
  remediation: (
    <>
      <ul>
        <li>
          <b>Use the VACUUM command to clean up dead rows.</b> The VACUUM command will remove dead rows from the table and reclaim
          the space they occupied.
        </li>

        <li>
          <b> Set the AUTOVACUUM configuration parameter to a value that will regularly clean up dead rows.</b> The AUTOVACUUM
          parameter tells the database how often to run the VACUUM command automatically.
        </li>
      </ul>
    </>
  )
});

export const RULE_SCHEMA_STAT_01 = (assertion: any, tableItem: any) => ({
  investigation: (
    <>
      {tableItem?.table_details?.last_analyze_date !== null ||
        (tableItem?.table_details?.last_autoanalyze_date !== null && (
          <>
            The table has statistics. The last time the statistics run was{' '}
            {tableItem?.table_details?.last_analyze_date || tableItem?.table_details?.last_autoanalyze_date}. The statistics were
            created {tableItem?.table_details?.last_autoanalyze_date ? 'automatically' : 'manually'}
          </>
        ))}
      {tableItem?.table_details?.last_analyze_date === null && tableItem?.table_details?.last_autoanalyze_date === null && (
        <>The table doesn’t have statistics.</>
      )}
    </>
  ),
  impact: (
    <>
      Performance - Without table statistics, the query planner will not be able to accurately estimate the cost of executing
      queries. This can lead to the query planner choosing an inefficient execution plan, which can result in poor performance.
    </>
  ),
  remediation: (
    <>
      Create statistics manually using the <code>ANALYZE</code> command. <code>ANALYZE VERBOSE table-name</code>.
    </>
  )
});

export const RULE_SCHEMA_STRUCTURE_01 = (assertion: any, tableItem: any) => ({
  investigation: (
    <SafeInnerHtml
      unsafeInnerHtmlText={wrapNumbersInBold(assertion?.rule?.successEvent?.message || assertion?.condition?.event?.message)}
    />
  ),
  impact: (
    <>
      The use of JSON/JSONB columns alongside regular columns can increase complexity, limit indexing capabilities, and compromise
      data integrity due to the lack of strict schema enforcement.
    </>
  ),
  remediation: (
    <>
      Consider normalizing the schema by extracting relevant attributes into regular columns, validate JSON data to ensure
      integrity, optimize indexing strategies, and carefully evaluate the necessity of using JSON/JSONB columns in the first
      place.
    </>
  )
});
