import styled from 'styled-components';
import Flex from '../../core/Flex';
import { SvgImg } from '../../core/SvgImg';
import Typography from '../../core/Typography';

export const HeaderContainer = styled(Flex)`
  width: 100%;
`;
export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.title};
`;

export const Arrow = styled.div`
  padding-left: 8px;
`;

export const DateTypography = styled(Typography)`
  font-size: 14px;
  line-height: ${(props: any) => props?.lineHeight || '22px'};
  font-weight: 700;
  color: ${({ theme }: any) => theme.colors.text.main};
  padding-left: ${(props: any) => props?.paddingLeft || '15px'};
  border-width: 0px;
  font-family: inherit;
`;
export const Total = styled(Typography)`
  color: ${({ theme, isFailed }) => (isFailed ? theme.colors.error.critical : theme.colors.text.main)};
  padding-right: 5px;
`;
export const FilterTitle = styled(Typography)`
  padding-right: 15px;
`;
export const TypographySecondary = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
  padding-left: ${(props) => props?.paddingLeft || '15px'};
`;
export const TypographyValue = styled(Typography)`
  padding-left: 8px;
`;
export const SearchInput = styled.div`
  padding: 16px 0;
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[350]};
  height: 32px;
  width: 2px;
  margin: 0 24px;
`;

export const GridBorder = styled(Flex)`
  padding-right: 16px;
  border-right: 2px solid ${({ theme }) => theme.colors.grey[300]};
`;

export const SeverityTitle = styled.div`
  padding-left: 20px;
`;
export const FailedStatus = styled(Total)`
  color: ${({ theme }) => theme.colors.error.critical};
`;

export const Error = styled(SvgImg)`
  height: 18px;
  width: 18px;
  margin-right: 5px;
  stroke: ${({ theme }) => theme.colors.white};
  fill: ${({ theme }) => theme.colors.error.critical};
`;
