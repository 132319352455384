import styled from 'styled-components';
import { ContextHolder } from '@frontegg/react';

import Flex from '../../core/Flex';
import LottieAnimation from '../../core/Lottie';
import SplashScreenIcon from '../../../assets/lotties/ splash-screen.json';

const SplashScreen = () => {
  const logOut = () => {
    const baseUrl = `${ContextHolder.getContext().baseUrl}`;
    window.location.href = new URL(`oauth/logout?post_logout_redirect_uri=${window.location}`, baseUrl).href;
  };
  return (
    <Splash onClick={logOut}>
      <LottieAnimation width={250} height={250} lottieJsonFile={SplashScreenIcon} loop={true} />
    </Splash>
  );
};

export const Splash = styled(Flex)`
  width: 100%;
  height: 100vh;
  justify-content: center;
  cursor: pointer;
`;

export default SplashScreen;
