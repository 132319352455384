import styled, { css } from 'styled-components';
import { ReactComponent as CloseIcon } from '@icons/close-bold.svg';
import { ReactComponent as ArrowIcon } from '@icons/arrow-down.svg';
import { ReactComponent as ClockIcon } from '@icons/clock.svg';
import { AgGridReact } from 'ag-grid-react';

import Flex from '../../core/Flex';
import Typography from '../../core/Typography';
import { breatheAnimation } from '../../utils/animation';

export const Container = styled(Flex)`
  height: inherit;
  width: 100%;
  align-items: start;
`;
export const TitleWrapper = styled(Typography)`
  margin: 2px 0;
`;
export const CellTitle = styled(Typography)`
  display: flex;
  height: inherit;
  align-items: center;
`;
export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.title};
`;
export const TitleDrawer = styled(Typography)<{ pd: string }>`
  color: ${({ theme }) => theme.colors.text.main};
  padding: ${({ pd }) => pd || 0};
`;
export const TextDrawer = styled(Typography)`
  padding-left: 3px;
`;
export const HeaderDrawer = styled(Flex)`
  width: 100%;
  background: ${({ theme }) => theme.colors.grey[50]};
`;
export const BodyDrawer = styled(Flex)`
  margin-right: 8px;
  width: 65vw;
  flex-direction: column;
  align-items: start;
`;
export const Close = styled(CloseIcon)`
  cursor: pointer;
  height: 20px;
  width: 20px;
  stroke: ${({ theme }) => theme.colors.text.main};
`;
export const ArrowWrapper = styled.div`
  position: absolute;
  right: 15px;
  padding-top: 5px;
`;
export const Arrow = styled(ArrowIcon)`
  height: 20px;
  width: 20px;
  transform: rotate(-90deg);
  stroke: ${({ theme }) => theme.colors.info.main};
`;

export const Clock = styled(ClockIcon)`
  height: 20px;
  width: 20px;
  padding-right: 5px;
  stroke: ${({ theme }) => theme.colors.text.main};
`;

export const LastUpdateDateComponent = styled(Flex)<{ isWarning: boolean }>`
  background: ${({ theme, isWarning }) => (isWarning ? theme.colors.error.criticalBg : theme.colors.grey[50])};
  border-radius: 2px;
  width: 160px;
  padding: 5px;
  svg {
    stroke: ${({ theme, isWarning }) => (isWarning ? theme.colors.error.critical : theme.colors.text.main)};
  }
  div {
    color: ${({ theme, isWarning }) => (isWarning ? theme.colors.error.critical : theme.colors.text.main)};
  }
`;

export const TabWrapper = styled.div`
  width: 914px;
`;
export const WrapperList = styled.div`
  border-radius: 3px;
  overflow-y: auto;
  height: calc(100vh - 230px);
  overflow-x: hidden;
  width: inherit;
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
`;
export const ContainerItem = styled.div<{ isActive: boolean }>`
  width: inherit;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  border: 0.5px solid ${({ theme }) => theme.colors.grey[100]};
  position: relative;
  .arrow-icon-item {
    display: none;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.grey[20]};
    .arrow-icon-item {
      display: block;
    }
  }
  ${({ isActive }) =>
    isActive &&
    css`
      animation-name: ${breatheAnimation};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}
`;

export const ExtendLinkWrapper = styled.a`
  position: absolute;
  right: 26px;
`;

export const TooltipContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: none;
  padding: 3px 5px;
  border-radius: 5px;
  box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.38);
`;

export const CopyDrawerContainer = styled(Flex)`
  position: relative;
  padding: 16px;
`;

export const GridItem = styled(Flex)`
  height: 100%;
  word-break: break-all;
`;
export const TableSizeContainer = styled(Flex)`
  width: 65px;
`;

export const DrawerItemWrapper = styled(Flex)`
  max-width: 990px;
  align-item: start;
  overflow-x: hidden;
`;

export const DataGridUIContainer = styled.div`
  height: calc(100vh - 190px);
  width: calc(100% - 2px);
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  border-radius: 5px;
  overflow-y: auto;
`;

export const DataGridUI = styled(AgGridReact)<{ onRowClicked: any }>`
  .ag-row,
  .ag-header-cell-label .ag-header-cell-text {
    font-family: 'Hind', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 22px;
  }
  .ag-row {
    ${({ onRowClicked }) =>
      !!onRowClicked &&
      css`
        cursor: pointer;
      `}
  }
  .ag-cell {
    color: ${({ theme }) => theme.colors.grey[500]};
  }
  .disabled-row {
    background-color: ${({ theme }) => theme.colors.grey[50]};
    opacity: 0.5;
  }
  .disabled-row:hover > * {
    cursor: auto;
    background-color: ${({ theme }) => theme.colors.grey[50]};
  }
`;

export const ToggleWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 3px;
  z-index: 6;
`;

export const Wrapper = styled.div`
  height: calc(100vh - 190px);
  position: relative;
`;

export const Header = styled(Flex)`
  width: 100%;
  align-items: start;
  position: relative;
`;

export const SubTitle = styled(Typography)`
  padding-right: 3px;
  color: ${({ theme }) => theme.colors.text.main};
`;
