import styled from 'styled-components';
import { styled as MuiStyle } from '@mui/material/styles';

import Flex from '../../core/Flex';
import Typography from '../../core/Typography';
import { ReactComponent as PG } from '@icons/pg.svg';
import { Button, Tab } from '@mui/material';

export const Title = styled(Typography)`
  padding-right: 5px;
`;
export const PGText = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.title};
  padding: 0 5px;
`;
export const HeaderText = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
`;
export const SubTitle = styled(HeaderText)`
  padding-left: 20px;
  padding-right: 5px;
`;
export const TitleContainer = styled(Flex)`
  width: 100%;
`;
export const PGIcon = styled(PG)`
  margin: 0 10px;
`;
export const ExecutionPlanWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  z-index: 5;
`;

export const RulerContainer = styled(TitleContainer)<{ hideRuler: boolean }>`
  align-items: start;
  padding-bottom: 15px;
  display: ${({ hideRuler }) => (hideRuler ? 'none' : 'flex')};
`;
export const SeverityWrapper = styled.div`
  padding-left: 2px;
  height: 23px;
`;
export const TabContainer = styled.div`
  width: 100%;
`;
export const CodeEditorContainer = styled.div``;
export const EmptyState = styled(Flex)`
  padding-top: 5vh;
  text-align: center;
`;
export const Link = styled(Typography)`
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.colors.text.title};
  }
`;
export const PlanViewerContainer = styled(CodeEditorContainer)<{ isQueryLog: boolean }>`
  height: ${({ isQueryLog }) => (isQueryLog ? 'calc(100vh - 235px)' : 'calc(100vh - 415px)')};
`;
export const SummaryContainer = styled(Flex)`
  min-width: 300px;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.grey[50]};
  justify-content: start;
  align-items: start;
`;

export const PretifyButton = MuiStyle(Button)({
  padding: '1px',
  lineHeight: 1.1,
  textTransform: 'lowercase'
});

export const TabWrapper = styled(Tab)`
  &.MuiButtonBase-root {
    font-weight: 600;
    font-size: 15px;
  }
`;

export const LinkHref = styled.a<any>`
  width: 163px;
  height: 18px;
  text-decoration: none;
  font-family: 'Hind';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #66dfc8;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const ButtonText = styled(Typography)`
  padding-top: 2px;
`;

export const PlanWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.grey[50]};
  border-radius: 3px;
  height: 28px;
  width: 160px;
  margin-top: -14px;
  margin-left: 16px;
  div {
    color: ${({ theme }) => theme.colors.text.main};
  }
`;
