import styled from 'styled-components';
import Flex from '../../core/Flex';
import Typography from '../../core/Typography';

export const FullFlex = styled.div`
  width: 100%;
  display: block;
`;
export const InvestigationDetailContainer = styled(Flex)`
  &:first-child {
    margin-top: 0px;
    display: block;
  }
  &:last-child {
    margin-bottom: 5px;
  }
  margin-top: 4px;
  width: calc(100% - 28px);
  padding: 4px 4px 4px 6px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  //border-bottom: ${({ theme, hideLine }) => !hideLine && `1px solid ${theme.colors.grey[150]}`};
`;

export const Details = styled(Typography)`
  padding: 0px 0px 0px 15px;
  color: ${({ theme }) => theme.colors.text.main};
`;

export const IconWrapper = styled.div`
  padding-right: 5px;
  display: flex;
  align-items: flex-end;
`;
