import CodeEditor from '../../../core/MonacoEditor/CodeEditor';
import { InsightsSchema } from '../../../utils/insights-schemas';

const AdminPanelUtils = () => {
  return (
    <div>
      Json that show how insights
      <div>
        <CodeEditor value={JSON.stringify(InsightsSchema, null, 2)} readonly />
      </div>
    </div>
  );
};

export default AdminPanelUtils;
