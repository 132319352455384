// import { useParams } from 'react-router-dom';
import { Title } from '../../components/Header/Header.styled';
import Flex from '../../core/Flex';

function History() {
  // const { historyId } = useParams();
  return (
    <Flex justify="start" margin="0 0 10px">
      <Title h1 weight={600}>
        History
      </Title>
    </Flex>
  );
}

export default History;
