import { ReactComponent as HighSeverity } from '@icons/severity-high.svg';
import { ReactComponent as LowSeverity } from '@icons/severity-low.svg';
import { ReactComponent as MediumSeverity } from '@icons/severity-medium.svg';
import { ReactComponent as CriticalSeverity } from '@icons/severity-critical.svg';

import Flex from '../../core/Flex';
import styled from 'styled-components';
import Typography from '../../core/Typography';
import { toNumbersSuffix } from '../../utils/utils';
import { SeveritiesI } from '../../types/Severity.type';

interface SeveritiesSummaryPropsI {
  showAll?: boolean;
}

const SeveritiesSummary = ({
  severity_critical = 0,
  severity_high = 0,
  severity_medium = 0,
  severity_low = 0,
  showAll = true
}: SeveritiesSummaryPropsI & SeveritiesI) => {
  return (
    <SeveritiesContainer direction="row">
      {(severity_critical > 0 || showAll) && (
        <Flex justify="end">
          <TypographySecondary weight="600">
            {toNumbersSuffix(severity_critical, 0).num}
            {toNumbersSuffix(severity_critical, 0).suffix}
          </TypographySecondary>
          <CriticalSeverity height={16} width={16} title="Critical" />
        </Flex>
      )}
      {(severity_high > 0 || showAll) && (
        <Flex justify="end">
          <TypographySecondary weight="600">
            {toNumbersSuffix(severity_high, 0).num}
            {toNumbersSuffix(severity_high, 0).suffix}
          </TypographySecondary>
          <HighSeverity title="High" height={16} width={16} />
        </Flex>
      )}
      {(severity_medium > 0 || showAll) && (
        <Flex justify="end">
          <TypographySecondary weight="600">
            {toNumbersSuffix(severity_medium, 0).num}
            {toNumbersSuffix(severity_medium, 0).suffix}
          </TypographySecondary>
          <MediumSeverity title="Medium" height={16} width={16} />
        </Flex>
      )}
      {(severity_low > 0 || showAll) && (
        <Flex justify="end">
          <TypographySecondary weight="600">
            {toNumbersSuffix(severity_low, 0).num}
            {toNumbersSuffix(severity_low, 0).suffix}
          </TypographySecondary>
          <LowSeverity title="Low" height={16} width={16} />
        </Flex>
      )}
    </SeveritiesContainer>
  );
};

export default SeveritiesSummary;

const SeveritiesContainer = styled(Flex)`
  height: 22px;
`;

const TypographySecondary = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
  padding: 3px;
  width: 30px;
  text-align: end;
`;
