import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import MockTablesEnrichment from './components/MockTablesEnrichment';
import { MockSpans } from './components/MockSpans';
import AdminPanelUtils from './components/AdminPanelUtils';
import Typography from '../../core/Typography';
import { DemoData } from './components/DemoData';
import TraceGenerator from './components/TraceGenerator';
import { useSegmentPage } from '../../utils/segment-analytics';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  useSegmentPage('AdminPanel');
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function AdminPanel() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Trace Generator" {...a11yProps(0)} />
          <Tab label="Tables Enrichments Generator" {...a11yProps(1)} />
          <Tab label="Insights Details" {...a11yProps(2)} />
          <Tab label="Demo Data" {...a11yProps(3)} />
          <Tab label="Trace JSON Generator" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MockSpans />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MockTablesEnrichment />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AdminPanelUtils />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <DemoData />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TraceGenerator />
      </TabPanel>
    </Box>
  );
}
