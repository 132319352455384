import { OnChange } from '@monaco-editor/react';
import { Button } from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { RestClientContext } from '../../../auth/RestClientAuthProvider';
import Flex from '../../../core/Flex';
import CodeEditor from '../../../core/MonacoEditor/CodeEditor';
import { Title } from '../../PerformanceDetails/PerformanceDetails.styled';

const MockTablesEnrichment = () => {
  const restClient = useContext(RestClientContext);

  const [editedData, setEditedData] = useState('');
  const onEditedDataChange = useCallback<OnChange>((data?: string) => setEditedData(data ?? ''), []);
  const { isLoading, data }: any = useQuery(
    ['mock-tables-enrichment'],
    (): Promise<any> => restClient.get(`tables-enrichment/tablesEnrichmentMock`).json()
  );

  if (isLoading) return <div>Loading...</div>;

  return (
    <Flex direction={'column'} style={{ paddingBottom: 15 }}>
      <Title h2>TablesEnrichment Mock</Title>
      <CodeEditor height="500px" width="95%" value={JSON.stringify(data, null, 2)} onChange={onEditedDataChange} />
      <div style={{ marginTop: 35 }}>
        <Button
          variant="outlined"
          onClick={() =>
            restClient.post('tables-enrichment/updatetablesEnrichmentMock', { json: { mockTablesEnrichment: editedData } }).json()
          }
        >
          Save Changes
        </Button>
      </div>
    </Flex>
  );
};

export default MockTablesEnrichment;
