export const reportsRoute = [
  {
    route: 'table-row-size-trend',
    label: 'Tables sizes',
    index: 0
  },
  {
    route: 'index-usage',
    label: 'Indexes',
    index: 1
  },
  {
    route: 'query-statistics',
    label: 'Queries',
    index: 2
  },
  {
    route: 'db-extensions',
    label: 'Extensions',
    index: 3
  },
  {
    route: 'db-config',
    label: 'Configurations',
    index: 4
  }
];

export const QUERY_PER_PAGE = 50;
