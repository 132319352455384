import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CelWrapper, Cell, ErrorItem, EmptyState, Container, FilterCell } from './TableItem.styled';
import Typography from '../../core/Typography';
import { getFormattedDate } from '../../utils/utils';
import { theme } from '../../theme';
import Flex from '../../core/Flex';
import MTooltip from '../../core/Tooltip/Tooltip';
interface TableItemProps {
  data: Array<any>;
  emptyErrorMessage?: string;
  headHeight?: string;
}

const TableItem = ({ data, emptyErrorMessage = 'No Data', headHeight }: TableItemProps) => {
  if (!(data && data.length)) {
    return (
      <EmptyState>
        <Typography h4>{emptyErrorMessage}</Typography>
      </EmptyState>
    );
  }

  const customizeCell = (item: any, idx: number, tableLength: number, key: string): any => {
    if (item === 'Table Scan' || item === 'Seq Scan') {
      return { color: theme.colors.tablesInfo.alert };
    }
    if (item === 'Index Only Scan') {
      return { color: '#03C04A' };
    }
    if (idx === 0) {
      return { marginRight: 0, paddingRight: 0 };
    }
    if (idx === 0) {
      return { marginRight: 0, paddingRight: 0 };
    }
    if (idx === tableLength - 1) {
      return { marginRight: 0, paddingRight: 0 };
    }

    return {};
  };

  const rows = data;

  const cellItem = (item: any, key: string) => {
    switch (key) {
      case 'Error':
        return <ErrorItem>{String(item)}</ErrorItem>;
      case 'Created Date':
        return <Typography>{getFormattedDate(item)}</Typography>;
      case 'Access Method':
        return <Flex justify="flex-start">{String(item)}</Flex>;
      case 'Filter':
        return (
          <MTooltip title={item} disable={item.length < 20}>
            <FilterCell>{item}</FilterCell>
          </MTooltip>
        );
      default:
        return item === undefined ? '' : String(item);
    }
  };
  return (
    <Container head_height={headHeight}>
      <Table stickyHeader aria-label="sticky table" size="small">
        <TableHead>
          <TableRow style={{ minHeight: '35px' }}>
            {data.length !== 0 &&
              Object.keys(data[0]).map((item, idx) => {
                return (
                  <CelWrapper key={idx} align="left">
                    <Typography weight={600}>{item}</Typography>
                  </CelWrapper>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((fact: any, idx: number) => (
            <TableRow key={idx}>
              {Object.values(fact).map((item: any, i: number, arr: any) => (
                <Cell sx={customizeCell(item, i, arr.length, Object.keys(fact)[i])} key={i} align="left">
                  {cellItem(item, Object.keys(fact)[i])}
                </Cell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default TableItem;
