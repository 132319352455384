import Drawer from '@mui/material/Drawer';

export type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface DrawerI {
  anchor: Anchor;
  open: boolean;
  onClose?: any;
  modalProps?: any;
  children?: React.ReactNode;
}

const MDrawer = ({ anchor, open, children, onClose, modalProps }: DrawerI) => {
  return (
    <>
      <Drawer ModalProps={modalProps} anchor={anchor} open={open} onClose={onClose ? onClose() : undefined}>
        {children}
      </Drawer>
    </>
  );
};

export default MDrawer;

export const toggleDrawer = (cb: () => void) => (event: React.KeyboardEvent | React.MouseEvent) => {
  if (
    event &&
    event.type === 'keydown' &&
    ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
  ) {
    return;
  }
  cb();
};
