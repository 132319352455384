import { atom, selector } from 'recoil';
import { Config, DataIndex, DataTable, Extension, QueryStats, ReportAtomState } from '../routes/Reports/reports/types';

export interface reportsStateI {
  selectedType: ReportAtomState;
  pmcDeviceId: string;
  dataTable: {
    lastUpdateIsoDate: string;
    rows: DataTable[];
  };
  dataIndex: {
    lastUpdateIsoDate: string;
    rows: DataIndex[];
  };
  dataQueryStats: {
    lastUpdateIsoDate: string;
    rows: QueryStats[];
    offset: number;
  };
  dataExtension: {
    lastUpdateIsoDate: string;
    rows: Extension[];
  };
  dataDBConfig: {
    lastUpdateIsoDate: string;
    rows: Config[];
  };
}

export const reportsStateAtom = atom<reportsStateI>({
  key: 'reportsState',
  default: {
    selectedType: 'dataTable',
    pmcDeviceId: '',
    dataTable: {
      lastUpdateIsoDate: '',
      rows: []
    },
    dataIndex: {
      lastUpdateIsoDate: '',
      rows: []
    },
    dataQueryStats: {
      lastUpdateIsoDate: '',
      rows: [],
      offset: 0
    },
    dataExtension: {
      lastUpdateIsoDate: '',
      rows: []
    },
    dataDBConfig: {
      lastUpdateIsoDate: '',
      rows: []
    }
  }
});

export const reportsStateSelector = selector<any>({
  key: 'reportsStateSelector',
  get: ({ get }: any): reportsStateI[ReportAtomState] => {
    const state = get(reportsStateAtom);
    return state[state.selectedType];
  }
});
