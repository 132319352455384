import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { snackbarState } from '../atoms/snackbar.atom';
import Flex from './Flex';
import Button from './Button/Button';
import Typography from './Typography';

export default function MAlert() {
  const setSnackbarS = useSetRecoilState(snackbarState);
  const snackbarValue = useRecoilValue(snackbarState);
  useEffect(() => {
    if (!snackbarValue.cb) {
      const timer = setTimeout(
        () =>
          setSnackbarS((old) => {
            return { ...old, open: false };
          }),
        2200
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [snackbarValue.open, snackbarValue.cb]);

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={snackbarValue.open} autoHideDuration={6000}>
        <Alert severity={snackbarValue.severity}>
          <Flex>
            {snackbarValue.text}
          </Flex>
        </Alert>
      </Snackbar>
    </Stack>
  );
}
