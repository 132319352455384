import { Skeleton } from '@mui/material';
import Flex from '../../core/Flex';
import CopyToClipBoard from '../Buttons/CopyToClipBoard';
import { Label } from '../Ruler/Ruler.styled';
import { Details, FullFlex, IconWrapper, InvestigationDetailContainer } from './InvestigationDetail.styled';
import Typography from '../../core/Typography';

export interface InvestigationDetailProps {
  title: string;
  details: any;
  icon: any;
  hideLine?: boolean;
  copyDetails?: string[];
  isLoading?: boolean;
}

export const InvestigationDetail = ({
  title,
  details,
  icon,
  hideLine = false,
  copyDetails = [],
  isLoading
}: InvestigationDetailProps) => {
  return (
    <InvestigationDetailContainer justify={'flex-start'} hideLine={hideLine}>
      <Flex  align={'start'} direction={'column'}>
        <Flex align={'end'}>
          <IconWrapper>{icon}</IconWrapper>
          <Label weight={700} h4>
            {title}
          </Label>
        </Flex>
        <FullFlex>
          {details ? (
            <Details>{details}</Details>
          ) : isLoading ? (
            <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'12vh'} />
          ) : (
            <Flex justify={'start'} margin="0 0 0 24px">
              <Typography weight={600}>No data to show</Typography>
            </Flex>
          )}
          {!!copyDetails.length &&
            copyDetails.map((d: string, i: number) => (
              <Flex key={d}>
                <Details> {i + 1 + ' : '}</Details>
                <code>
                  <Details>{d}</Details>
                </code>
                <CopyToClipBoard data={d} />
              </Flex>
            ))}
        </FullFlex>
      </Flex>
    </InvestigationDetailContainer>
  );
};
