import LottieAnimation from "../../core/Lottie"
import SimpleLoader from '../../../assets/lotties/simple-loader.json';

interface BasicSpinnerLoaderProps {
  size?: number;
}

const BasicSpinnerLoader = ({ size }: BasicSpinnerLoaderProps) => {
  return  <LottieAnimation width={size || 80} height={size || 80} lottieJsonFile={SimpleLoader} loop={true}></LottieAnimation>
}

export default BasicSpinnerLoader;