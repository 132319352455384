import { ReactComponent as ArrowIcon } from '@icons/arrow-down.svg';
import styled from 'styled-components';
import Typography from '../Typography';
import Flex from '../Flex';

export const TitleMain = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.main};
  padding: 10px 0 8px 0;
`;

export const Container = styled(Flex)`
  width: calc(100% - 48px);
  position: absolute;
  top: 56px;
  height: 34px;
  left: 0px;
  z-index: 1;
  padding: 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[150]};
  background-color: ${({ theme }) => theme.colors.grey[20]};
`;
export const BreadcrumbsImg = styled(ArrowIcon)`
  height: 16px;
  width: 16px;
  margin: 0px 8px 1px 8px;
  cursor: auto;
  transform: rotate(-90deg);
  fill: ${({ theme }) => theme.colors.text.main};
  stroke: ${({ theme }) => theme.colors.text.main};
`;

export const BreadcrumbsText = styled(Typography)<{ isActive: boolean }>`
  cursor: pointer;
  max-width: 420px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  color: ${({ theme, isActive }) => (isActive ? theme.colors.text.title : theme.colors.text.main)};
  &:hover {
    color: ${({ theme, isActive }) => (isActive ? theme.colors.text.main : theme.colors.primary.border)};
  }
`;
