import Select from '../../../components/Select/Select';
import { apiKeysState, ApiKeyType } from '../../../atoms/api-keys.atom';
import { useRecoilValue } from 'recoil';
import { FormEventHandler, useCallback, useContext, useState } from 'react';
import { RestClientContext } from '../../../auth/RestClientAuthProvider';
import { useMutation } from 'react-query';

const useMutateDemoData = () => {
  const restClient = useContext(RestClientContext);
  return useMutation(({ apiKey }: { apiKey: string }) =>
    restClient.post('mock/sample-data', { searchParams: { 'api-key': apiKey } }).json()
  );
};

export const DemoData = () => {
  const [apiKey, setApikey] = useState('');
  const { apiKeys } = useRecoilValue(apiKeysState);
  const { mutate, isLoading: isDemoDataLoading } = useMutateDemoData();

  const addDemoData = useCallback<FormEventHandler>(() => {
    mutate({ apiKey });
  }, [mutate, apiKey]);

  const onApikeyChanged = useCallback((value: any) => setApikey(value), []);

  return (
    <div>
      <Select
        minWidth={'400px'}
        changeValueOnPress={true}
        name={'Api Key'}
        list={apiKeys.map((key: ApiKeyType, idx: number) => {
          return { id: idx, name: key.label ?? key.api_key, val: key.api_key };
        })}
        value={apiKey}
        onSelect={onApikeyChanged}
      />
      <button
        disabled={!apiKey || isDemoDataLoading}
        onClick={addDemoData}
        style={{ color: 'red', width: '200px', height: '29px' }}
      >
        {isDemoDataLoading ? 'LOADING' : 'DEMO DATA'}
      </button>
    </div>
  );
};
