import { useEffect, useState } from 'react';
import InsightDetailsCard from '../InsightDetailsCard/InsightDetailsCard';
import Flex from '../../core/Flex';
import {
  AssertionContainer,
  AssertionDetailsContainer,
  AssertionDetailsTitleContainer,
  AssertionsTabContainer,
  EmptyAssertionContainer,
  EmptyAssertionTitle
} from './AssertionTab.styled';
import { Label } from '../Ruler/Ruler.styled';
import OrangeAlert from '../../../assets/lotties/orange-alert.json';
import { InvestigationDetail } from '../InvestigationDetail/InvestigationDetail';
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import { Assertion } from '../../types/Assertion.type';
import InsightLoader from '../Loader/Insight';
import { getInsightInvestigation } from '../../utils/automated-investgiation/automated-investigation';
import { useRecoilValue } from 'recoil';
import { debugModeState } from '../../atoms/debug-mode.atom';
import { analyticsTrack } from '../../utils/segment-analytics';
import SafeInnerHtml from '../../utils/SafeInnerHtml';
import LottieAnimation from '../../core/Lottie';

interface AssertionTabProps {
  assertions: Assertion[];
  facts: any;
  assertionCurrentIndex: any;
  setAssertionIndexCallback: any;
  tableInfos: any[];
  isLoading: boolean;
  selectedQuery: any;
  changeTabActivity?: any;
  headHeight?: string;
  tableItem?: any;
  extensionsReportAssertions?: boolean;
  isSidebar?: boolean;
}

export const AssertionTab = ({
  assertions,
  isLoading,
  assertionCurrentIndex,
  setAssertionIndexCallback,
  facts,
  tableInfos,
  changeTabActivity,
  selectedQuery,
  headHeight,
  tableItem,
  extensionsReportAssertions,
  isSidebar
}: AssertionTabProps) => {
  const [selectedAssertion, setSelectedAssertion] = useState<Assertion & { idx: number }>({
    idx: assertionCurrentIndex,
    ...assertions[assertionCurrentIndex]
  });
  const debugMode = useRecoilValue(debugModeState);
  const [automatedInvestigation, setAutomatedInvestigation] = useState<any>(undefined);

  useEffect(() => {
    if (assertions.length) {
      setSelectedAssertion({ idx: assertionCurrentIndex, ...assertions[assertionCurrentIndex] });
      setAutomatedInvestigation(
        extensionsReportAssertions
          ? {
              investigation: <SafeInnerHtml unsafeInnerHtmlText={assertions[assertionCurrentIndex]?.details} />,
              impact: <SafeInnerHtml unsafeInnerHtmlText={assertions[assertionCurrentIndex]?.impact} />,
              remediation: <SafeInnerHtml unsafeInnerHtmlText={assertions[assertionCurrentIndex]?.remediation} />
            }
          : getInsightInvestigation(
              facts,
              assertions[assertionCurrentIndex].ruleId,
              tableInfos,
              changeTabActivity,
              selectedQuery,
              assertions[assertionCurrentIndex],
              tableItem
            )
      );
    }
  }, [assertions]);

  return (
    <AssertionsTabContainer headHeight={headHeight}>
      {isLoading ? (
        <InsightLoader />
      ) : (
        <AssertionContainer>
          {assertions
            .sort((assertionA: Assertion, assertionB: Assertion) => {
              const priorityA = assertionA?.condition?.event?.priority || assertionA?.priority;
              const priorityB = assertionB?.condition?.event?.priority || assertionB?.priority;
              return priorityA - priorityB;
            })
            .map((assertion: Assertion, idx: number) => (
              <InsightDetailsCard
                choosen={selectedAssertion.idx === idx}
                index={idx}
                isSidebar={isSidebar}
                callback={(assertionData: any) => {
                  analyticsTrack('PerformanceDetails Insight', assertionData);
                  setSelectedAssertion(assertionData);
                  setAssertionIndexCallback(assertionData.idx);
                  setAutomatedInvestigation(
                    extensionsReportAssertions
                      ? {
                          investigation: <>{(assertion as any).details}</>,
                          impact: <>{(assertion as any).impact}</>,
                          remediation: <>{(assertion as any).remediation}</>
                        }
                      : getInsightInvestigation(
                          facts,
                          assertions[assertionCurrentIndex].ruleId,
                          tableInfos,
                          changeTabActivity,
                          selectedQuery,
                          assertions[assertionCurrentIndex],
                          tableItem
                        )
                  );
                }}
                assertionDetails={assertion}
                key={idx}
              />
            ))}
        </AssertionContainer>
      )}
      {automatedInvestigation ? (
        <AssertionDetailsContainer>
          <AssertionDetailsTitleContainer justify={'space-between'}>
            <Flex>
              <Label weight={600}>
                {debugMode && [selectedAssertion?.category, selectedAssertion?.ruleId].filter(Boolean).join(' - ')}
              </Label>
            </Flex>
          </AssertionDetailsTitleContainer>
          <Flex style={{ width: '100%' }} align={'start'} justify={'flex-start'} direction={'column'}>
            {[
              {
                title: 'Insights Details',
                details: automatedInvestigation?.investigation,
                icon: <InsightsDetailsIcon width={24} />
              },
              {
                title: 'Impact',
                details: automatedInvestigation?.impact,
                icon: <Pendulum width={24} />
              },
              {
                title: 'Remediation Plan',
                details: automatedInvestigation?.remediation,
                icon: <Wrench width={24} />
              }
            ]
              .filter((item: any, idx: number) => {
                const priority = selectedAssertion?.condition?.event?.priority || selectedAssertion?.priority;
                return (
                  (priority !== 6 || idx === 0) &&
                  !(priority === 5 && (item.title === 'Impact' || item.title === 'Remediation Plan'))
                );
              })
              .map((e: any, idx: number) => {
                return <InvestigationDetail isLoading={isLoading} key={idx} title={e.title} details={e.details} icon={e.icon} />;
              })}
          </Flex>
        </AssertionDetailsContainer>
      ) : (
        !isLoading && (
          <EmptyAssertionContainer justify="center">
            <LottieAnimation width={29} height={29} lottieJsonFile={OrangeAlert} loop={false} />
            <EmptyAssertionTitle weight={600} h4>
              Unable to generate insights due to missing or faulty query or execution plan
            </EmptyAssertionTitle>
          </EmptyAssertionContainer>
        )
      )}
    </AssertionsTabContainer>
  );
};
