import { useRef, useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import styled from 'styled-components';

import LottieCopy from '../../../assets/lotties/copy-button.json';

interface CopyToClipBoardProps {
  data: any;
  manipulateFunction?: any;
}

const CopyToClipBoard = ({ data, manipulateFunction }: CopyToClipBoardProps) => {
  const defaultStyle = {
    height: '20px',
    width: '20px'
  };
  const hoverStyle = {
    height: '20px',
    width: '20px',
    filter: 'brightness(70%)'
  };
  const [style, setStyle] = useState<any>(defaultStyle);
  const playerRef = useRef<Player>(null);

  const handleClick = async (event: any) => {
    event.stopPropagation();
    playerRef.current?.play();

    await navigator.clipboard.writeText(manipulateFunction ? manipulateFunction(data) : data);
  };

  return (
    <CopyWrapper
      onClick={(event) => handleClick(event)}
      onMouseOver={() => setStyle(hoverStyle)}
      onMouseOut={() => setStyle(defaultStyle)}
    >
      <Player style={style} speed={2} src={LottieCopy} ref={playerRef} />
    </CopyWrapper>
  );
};

const CopyWrapper = styled.div`
  cursor: pointer;
  margin: 3px;
`;
export default CopyToClipBoard;
