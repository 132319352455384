export const bestPracticeApproach = [
  'itzikb@metisdata.io',
  'gideons@metisdata.io',
  'roee@metisdata.io',
  'issackr@metisdata.io',
  'itay@metisdata.io',
  'ilyar@metisdata.io',
  'liorz@metisdata.io',
  'e2e-test@metisdata.io',
  'demo4all@metisdata.io',
  'amitc@metisdata.io',
  'orl@metisdata.io',
  'demo@metisdata.io'
];

export const filterList = [
  { id: 0, name: 'None' },
  { id: 1, name: 'Severity' },
  { id: 2, name: 'Duration' },
  { id: 3, name: 'Date' },
  { id: 4, name: 'Command Type' }
];
export const sideMenuUserList = [
  { name: 'Settings', path: '/settings' }
  // { name: 'Management', path: '/management' }
];

import { ReactComponent as SettingsIcon } from '@icons/settings.svg';
import { ReactComponent as InProgress } from '@icons/no-data-found-traces.svg';

export const sideMenuList = [
  { name: 'General Settings', path: '/settings', icon: SettingsIcon },
  { name: 'User management ', icon: InProgress, path: 'user-management' },
  {
    name: 'Enrichment',
    icon: InProgress,
    path: 'enrichment',
    isActive: (user: any) => bestPracticeApproach.includes(user.email ? user.email : '')
  },
  {
    name: 'Exporter',
    icon: InProgress,
    path: 'exporter',
    isActive: (user: any) => bestPracticeApproach.includes(user.email ? user.email : '')
  },
  // { name: 'divider_1' },
  {
    name: 'Activity Log',
    icon: InProgress,
    path: 'activity',
    isActive: (user: any) => bestPracticeApproach.includes(user.email ? user.email : '')
  },

  // { name: 'divider_2' },
  {
    name: 'Admin Panel',
    icon: SettingsIcon,
    path: 'admin-panel',
    isActive: (user: any) => bestPracticeApproach.includes(user.email ? user.email : '')
  }
];

export const INSIGHT_STATUS = ['N/A', 'Critical', 'High', 'Medium', 'Low', 'Info', 'Skipped'];

export const DEMO_API_KEY = '4KauFohR2zaOxXLN2EimDmZEHRBTwKX4DG8E1wR5';

export enum INSIGHT_STATUS_ENUM {
  N_A = 'N/A',
  CRITICAL = 'Critical',
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
  INFO = 'Info',
  SKIPPED = 'Skipped'
}

export const severity = {
  [INSIGHT_STATUS_ENUM.CRITICAL]: 0,
  [INSIGHT_STATUS_ENUM.HIGH]: 0,
  [INSIGHT_STATUS_ENUM.MEDIUM]: 0,
  [INSIGHT_STATUS_ENUM.LOW]: 0,
  [INSIGHT_STATUS_ENUM.INFO]: 0
};

export const summaryInit = {
  title: 'Recent Activity',
  description: '',
  totalRestCommand: 0,
  totalQueries: 0,
  totalFailedRules: 0,
  dateStart: '',
  dateEnd: '',
  severity,
  hideIcon: false
};

export const ResponseMethods = ['ALL', 'GET', 'POST', 'PUT', 'DELETE', 'HEAD', 'PUT'];
export const ResponseStatusCodes = ['ALL', '1XX', '2XX', '3XX', '4XX', '5XX'];
export const ResponseInsights = ['ALL', 'Insights only'];
export const ResponseRelativeTime = ['All', 'last-hour', 'last-day', 'last-week', 'last-month'];
