import { useRecoilState } from 'recoil';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';

import { RestClientContext } from '../../../auth/RestClientAuthProvider';
import { reportsStateAtom, reportsStateI } from '../../../atoms/reports.atom';
import { getFactsAsKeyVal, numberWithCommas } from '../../../utils/utils';
import Flex from '../../../core/Flex';
import {
  BodyDrawer,
  Close,
  Container,
  DataGridUI,
  DataGridUIContainer,
  HeaderDrawer,
  TableSizeContainer,
  TitleDrawer
} from '../Reports.styled';
import TableLoader from '../../../components/Loader/TableLoader';
import { byteMe, BYTE_SIZE } from '../../../utils/byteMe';
import { DataTable, ReportAtomState } from './types';
import MDrawer, { toggleDrawer } from '../../../core/Drawer/Drawer';
import { ReportTabState, getSSAssertions } from '../utils';
import { AssertionTab } from '../../../components/AssertionsTab/AssertionTab';
import { getTableByPmcDeviceIdAndParamsAPI } from '../../SchemaAnalysis/SchemaAnalysisAPI';
import { analyticsTrack } from '../../../utils/segment-analytics';
import { getTableRowSizeAPI } from '../utils/ReportAPI';
import { ReactComponent as InfoI } from '@icons/info-primary.svg';
import MTooltip from '../../../core/Tooltip/Tooltip';


const ExtraInfoDetails =  () => {
  return <div>
   -1 indicates that the table has never yet been vacuumed or analyzed, thus row (<code>pg_catalog.reltuples</code>) count is unknown.
Run the <code>ANALYZE</code> command to generate statistics.
For ex. <code>ANALYZE</code> <b>my_table</b>
  </div>
}

const CustomizeRowReadHeader = () => {
 
    return  (
      <MTooltip title={<ExtraInfoDetails />}>
      <div style={{display:'flex', flexDirection: 'row'}}>
        <div style={{paddingRight: 3}}>
        Number of rows
        </div>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5}}>
          <InfoI height={14}  width={14}/>
        </div> 
        </div>
        </MTooltip>
    )
};

export const TableRowSize = () => {
  const type: ReportAtomState = 'dataTable';
  const restClient = useContext(RestClientContext);

  const [isLoading, setLoading] = useState(false);
  const [isSSALoading, setSSALoading] = useState(false);
  const [insight, setSelectedInsight] = useState<any>(null);
  const [{ [type]: reportsState, selectedType }, setReportsState] = useRecoilState(reportsStateAtom);

  const { projectApiKey = '', pmcDeviceId = '' } = useParams();
  const { state }: any = useLocation();
  const [assertionIndex, setAssertionIndex] = useState(0);
  const [tableAnalysisItem, setTableAnalysisItem] = useState<any>();
  // const insightsFormatter = (params: ValueFormatterParams) => {
  //   return (
  //     params?.data?.insights && (
  //       <SeveritiesSummary
  //         severity_critical={params?.data?.insights?.severity_critical}
  //         severity_high={params?.data?.insights?.severity_high}
  //         severity_medium={params?.data?.insights?.severity_medium}
  //         severity_low={params?.data?.insights?.severity_low}
  //         showAll={false}
  //       />
  //     )
  //   );
  // };
  const getTableSizeFormatter = (formate: any[]) => {
    const params = byteMe(formate?.[formate.length - 1]?.value, BYTE_SIZE.KB) || '0 KB';
    const data = formate ? params.split(' ') : ['0', 'KB'];
    return (
      <TableSizeContainer justify="space-between">
        <div>{data[0]}</div>
        <div>{data[1]}</div>
      </TableSizeContainer>
    );
  };
  const [columnDefs] = useState([
    {
      field: 'schemaName',
      headerName: 'Schema Name',
      valueGetter: (params: any) => params?.data?.context?.schema
      // filter: 'agSetColumnFilter'
    },
    {
      field: 'tableName',
      headerName: 'Table Name',
      valueGetter: (params: any) => params?.data?.context?.table
    },
    {
      headerName: 'Number of rows',
      headerComponent: CustomizeRowReadHeader,
      valueGetter: (params: any) => params?.data?.rows?.[params?.data?.rows?.length - 1]?.value,
      valueFormatter: (params: any) => numberWithCommas(params?.data?.rows?.[params?.data?.rows?.length - 1]?.value)
    },
    {
      headerName: 'Table Size',
      sort: 'desc',
      valueGetter: (params: any) => params?.data?.table_size?.[params?.data?.table_size?.length - 1]?.value,
      cellRenderer: (params: any) => getTableSizeFormatter(params?.data?.table_size)
    },
    {
      headerName: 'Dead Rows',
      valueGetter: (params: any) => params?.data?.dead_rows_tuples?.[params?.data?.dead_rows_tuples?.length - 1]?.value,
      valueFormatter: (params: any) =>
        numberWithCommas(params?.data?.dead_rows_tuples?.[params?.data?.dead_rows_tuples?.length - 1]?.value)
    }
    // {
    //   headerName: 'Insights',
    //   sortable: true,
    //   cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
    //   cellRenderer: (params: ValueFormatterParams) => insightsFormatter(params),
    //   comparator: (valueA: number, valueB: number, nodeA: any, nodeB: any) =>
    //     sortSeverityTable(nodeA?.data?.insights, nodeB?.data?.insights)
    // }
  ]);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1
    }),
    []
  );

  const getReports = async () => {
    setLoading(true);
    const tableRowSizeRes: { rows: DataTable[]; lastUpdateIsoDate: string } | undefined = await getTableRowSizeAPI(
      restClient,
      projectApiKey,
      pmcDeviceId
    );
    setReportsState((old: reportsStateI) => ({
      ...old,
      selectedType: type,
      [type]: { rows: tableRowSizeRes?.rows || [], lastUpdateIsoDate: tableRowSizeRes?.lastUpdateIsoDate || '' }
    }));
    setLoading(false);
  };

  const getTableAnalysisItem = async () => {
    setSSALoading(true);
    if (insight?.table_name && insight?.schema_name && state?.pmcDeviceId && projectApiKey) {
      const resTableList = await getTableByPmcDeviceIdAndParamsAPI(
        restClient,
        insight.table_name,
        insight.schema_name,
        state?.pmcDeviceId || '',
        projectApiKey || ''
      );
      setTableAnalysisItem(resTableList);
      analyticsTrack('getTableAnalysisList Success', resTableList);
    }
    setSSALoading(false);
  };
  useEffect(() => {
    if (selectedType !== type) {
      setReportsState((old) => ({
        ...old,
        selectedType: type,
        pmcDeviceId
      }));
    }
    if (projectApiKey && pmcDeviceId) {
      getReports();
    }
  }, [projectApiKey, pmcDeviceId]);
  useEffect(() => {
    if (insight) {
      getTableAnalysisItem();
    }
  }, [insight]);

  return (
    <>
      <Container>
        <DataGridUIContainer className="ag-theme-material">
          {isLoading ? (
            <TableLoader size={10} />
          ) : (
            <DataGridUI
              rowData={reportsState.rows}
              animateRows={true}
              columnDefs={columnDefs}
              suppressCellFocus={true}
              multiSortKey={'ctrl'}
              defaultColDef={defaultColDef}
              onRowClicked={(row: any) => {
                row?.data?.insights && setSelectedInsight(row.data.insights);
              }}
            />
          )}
        </DataGridUIContainer>
      </Container>
      <MDrawer anchor="right" onClose={() => toggleDrawer(() => setSelectedInsight(null))} open={!!insight}>
        <HeaderDrawer justify="space-between">
          <Flex margin="16px">
            <TitleDrawer h3 weight="600">
              Table Statistics Details
            </TitleDrawer>
          </Flex>
          <Flex margin="16px">
            <Close title="close-icon" onClick={() => setSelectedInsight(null)} />
          </Flex>
        </HeaderDrawer>
        <BodyDrawer>
          <Tabs sx={{ minHeight: '35px', ml: 1 }} value={0}>
            {ReportTabState.map((tabItem: string) => (
              <Tab key={tabItem} sx={{ textTransform: 'none', fontWeight: 600 }} id={`report-tab-${tabItem}`} label={tabItem} />
            ))}
          </Tabs>
          <AssertionTab
            assertions={getSSAssertions(true, tableAnalysisItem?.table_insights)}
            headHeight="120px"
            facts={getFactsAsKeyVal(tableAnalysisItem?.table_insights?.assertions?.metrics)}
            assertionCurrentIndex={assertionIndex}
            setAssertionIndexCallback={setAssertionIndex}
            tableInfos={[]}
            changeTabActivity={0}
            isLoading={isSSALoading}
            selectedQuery={0}
            tableItem={tableAnalysisItem}
            isSidebar={true}
          />
        </BodyDrawer>
      </MDrawer>
    </>
  );
};
